import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

import Error from "./Error";
import Loading from "./Loading";
import {
  createSupplier,
  fetchAllSupplier,
  updateSupplier,
  deleteSupplier,
  uploadSupplier,
} from "../Redux/supplier/supplierSlice";
import CsvReader from "./fileReaders/CsvReader";
import PdfReader from "./fileReaders/PdfReader";
import ExcelReader from "./fileReaders/ExelReader";
import { useParams } from "react-router-dom";

export default function Supplier() {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState([]);

  const { id } = useParams();
  let emptySupplier = {
    created_by: JSON.parse(localStorage.getItem("user"))?.id || null, // Assuming "created_by" should be the user's ID from localStorage
    service: "",
    type: "", // Required field, must be one of the choices defined in LANDLORD_TYPES
    first_name: "", // Required field for the supplier's first name
    last_name: "", // Required field for the supplier's last name
    company: "", // Optional field for the supplier's company name
    phone: "", // Required field for the supplier's phone number
    secondary_phone: "", // Optional field for a secondary phone number
    email: "", // Required field for the supplier's email address
    secondary_email: "", // Optional field for a secondary email address
    document_type: "", // Optional field for the supplier's document type
    document_number: "", // Optional field for the supplier's document number
    occupation: "", // Optional field for the supplier's occupation
    pin_number: "", // Optional field for the supplier's PIN number
    address: "", // Required field for the supplier's address
    postal_code: "", // Optional field for the supplier's postal code
    property: id,
  };

  const dispatch = useDispatch();
  const [suppliers, setsuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [supplierInfoDialog, setSupplierInfoDialog] = useState(false);
  const [supplierDialog, setsupplierDialog] = useState(false);
  const [supplierUploadDialog, setsupplierUploadDialog] = useState(false);
  const [deletesupplierDialog, setDeletesupplierDialog] = useState(false);
  const [deletesuppliersDialog, setDeletesuppliersDialog] = useState(false);
  const [supplier, setsupplier] = useState(emptySupplier);
  const [selectedsuppliers, setSelectedsuppliers] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const types = [
    { name: "company", code: "cp" },
    { name: "individual", code: "ind" },
  ];

  const docTypes = [
    { name: "national_id", code: "id" },
    { name: "passport", code: "pas" },
    { name: "driver_license", code: "dl" },
  ];

  const service = [
    { name: "electricity" },
    { name: "water" },
    { name: "gas" },
    { name: "internet" },
    { name: "maintenance" },
  ];
  const Suppliers = useSelector((state) => state.supplier.Suppliers);
  const loading = useSelector((state) => state.supplier.loading);
  const error = useSelector((state) => state.supplier.error);
  useEffect(() => {
    dispatch(fetchAllSupplier(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (Suppliers && Suppliers.length > 0) {
      setsuppliers(Suppliers);
    }
  }, [Suppliers]);

  const openNew = () => {
    setsupplier(emptySupplier);
    setSubmitted(false);
    setsupplierDialog(true);
  };

  const openNewUpload = () => {
    setSubmitted(false);
    setsupplierUploadDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setsupplierDialog(false);
  };

  const hideUploadDialog = () => {
    setFile([]);
    setSubmitted(false);
    setsupplierUploadDialog(false);
  };

  const hideDeletesupplierDialog = () => {
    setDeletesupplierDialog(false);
  };

  const hideDeletesuppliersDialog = () => {
    setDeletesuppliersDialog(false);
  };

  const savesupplier = () => {
    // Dispatch createSupplier or updateSupplier based on whether the supplier has an ID
    if (supplier.id) {
      dispatch(updateSupplier({ id: supplier.id, newData: supplier }))
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Supplier Updated",
            life: 3000,
          });
          // Optionally refresh the suppliers list
          dispatch(fetchAllSupplier(id));
        })
        .catch((error) => {
          // Handle error (e.g. display a message or log it)
          console.error("Error updating supplier:", error);
        });
    } else {
      // For creating a new supplier

      dispatch(createSupplier(supplier))
        .then((er) => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Supplier Created",
            life: 3000,
          });
          // Optionally refresh the suppliers list
          dispatch(fetchAllSupplier(id));
        })
        .catch((error) => {
          console.error("Error creating supplier:", error);
        });
    }

    // Close the supplier dialog and reset the supplier form
    setsupplierDialog(false);
    setsupplier(emptySupplier);
  };

  const upload = async () => {
    if (!file || file.length === 0) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "No file selected",
        life: 3000,
      });
      return;
    }

    const formData = new FormData();
    file.forEach((file) => {
      formData.append("file", file);
    });
    dispatch(uploadSupplier(formData))
      .then((er) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Supplier file Uploaded",
          life: 3000,
        });
        setsupplierUploadDialog(false);
        setFile([]);
      })
      .catch((error) => {
        toast.current.show({
          severity: "success",
          summary: "failed",
          detail: error,
          life: 3000,
        });
      });
  };

  const DownloadSupplier = () => {};

  const hideSupplierInfoDialogue = () => {
    setSupplierInfoDialog(false);
  };

  const SupplierInfo = (supplierId) => {
    const expe = Suppliers.find((b) => b.id === supplierId);
    setSelectedSupplier(expe);
    setSupplierInfoDialog(true);
  };

  const editsupplier = (supplier) => {
    setsupplier({ ...supplier });
    setsupplierDialog(true);
  };

  const confirmDeletesupplier = (supplier) => {
    setsupplier(supplier);
    setDeletesupplierDialog(true);
  };

  const deletesupplier = () => {
    dispatch(deleteSupplier(supplier.id));
    let _suppliers = suppliers.filter((val) => val.id !== supplier.id);
    setsuppliers(_suppliers);
    setDeletesupplierDialog(false);
    setsupplier(emptySupplier);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "supplier Deleted",
      life: 3000,
    });
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeletesuppliersDialog(true);
  };

  const deleteSelectedsuppliers = async () => {
    for (const supplier of selectedsuppliers) {
      try {
        dispatch(deleteSupplier(supplier.id));
      } catch (error) {
        console.error(
          `Failed to delete supplier with ID ${supplier.id}:`,
          error
        );
      }
    }

    const remainingSuppliers = suppliers.filter(
      (supplier) =>
        !selectedsuppliers.some(
          (selectedSupplier) => selectedSupplier.id === supplier.id
        )
    );

    setsuppliers(remainingSuppliers);

    setSelectedsuppliers(null);
    setDeletesuppliersDialog(false);

    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Suppliers Deleted",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _supplier = { ...supplier };

    _supplier[`${name}`] = val;

    setsupplier(_supplier);
  };

  const onTypeChange = (e) => {
    const selectedValue = e.value.name;
    const updatedSupplier = {
      ...supplier,
      type: selectedValue,
    };

    setsupplier(updatedSupplier);
  };

  const onDocTypeChange = (e) => {
    const selectedValue = e.value.name;
    const updatedSupplier = {
      ...supplier,
      document_type: selectedValue,
    };

    setsupplier(updatedSupplier);
  };

  const onServiceChange = (e) => {
    const selectedValue = e.value.name;
    const updatedSupplier = {
      ...supplier,
      service: selectedValue,
    };

    setsupplier(updatedSupplier);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New Supplier"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Supplier Doccumnent"
          icon="pi pi-cloud-upload"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete Suppliers"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedsuppliers || !selectedsuppliers.length}
        />
        <Button
          label="Add from a file"
          icon="pi pi-file-excel"
          severity="success"
          onClick={openNewUpload}
        />
      </div>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default();

        // Extract the current data from the DataTable
        const currentData = suppliers; // This is the current data from the DataTable

        // Define the table headers (columns)
        const tableHeaders = [
          { title: "Supplier No", dataKey: "supplier_number" },
          { title: "Name", dataKey: "name" },
          { title: "Type", dataKey: "type" },
          { title: "Payable", dataKey: "payable" },
          { title: "Earn Commission", dataKey: "earn_commission" },
          { title: "Measuring Units", dataKey: "measuring_unit" },
          { title: "Calculate Unit Rate", dataKey: "calculate_unit_rate" },
          { title: "Deposit", dataKey: "deposit" },
          // Add more columns as needed
        ];

        // Create the PDF table
        doc.autoTable({
          startY: 20, // Adjust the starting Y position as needed
          head: [tableHeaders], // Pass the table headers
          body: currentData, // Pass the current data
        });

        // Save the PDF file
        doc.save("suppliers.pdf");
      });
    });
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Export csv"
          icon="pi pi-upload"
          className="p-button-help mr-2"
          onClick={exportCSV}
        />
        <Button
          label="Export pdf"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportPdf}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editsupplier(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          className="mr-2"
          onClick={() => confirmDeletesupplier(rowData)}
        />
        <Button
          icon="pi pi-info"
          rounded
          outlined
          style={{ color: "black" }}
          onClick={() => SupplierInfo(rowData.id)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Suppliers</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );
  const supplierDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={savesupplier} />
    </React.Fragment>
  );

  const supplierDialogUploadFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideUploadDialog}
      />
    </React.Fragment>
  );
  const deletesupplierDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletesupplierDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deletesupplier}
      />
    </React.Fragment>
  );

  const supplierInfoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        outlined
        onClick={hideSupplierInfoDialogue}
      />{" "}
      <Button label="Download" icon="pi pi-download" onClick={DownloadSupplier} />
    </React.Fragment>
  );
  const deletesuppliersDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletesuppliersDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedsuppliers}
      />
    </React.Fragment>
  );

  if (error) {
    return <Error err={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    const selectedFiles = e.files;

    let newFiles = [];
    Object.keys(selectedFiles).forEach((key) => {
      _totalSize += selectedFiles[key].size || 0;
      newFiles.push(selectedFiles[key]);
    });

    setTotalSize(_totalSize);
    setFile(newFiles);
  };

  const onTemplateRemove = (removedFile, callback) => {
    const updatedFiles = file.filter((f) => f !== removedFile);
    setFile(updatedFiles);
    setTotalSize(totalSize - removedFile.size);
    callback();
  };

  const onTemplateClear = () => {
    setFile([]);
    setTotalSize(0);
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Render different components based on the file extension
    let readerComponent;
    switch (fileExtension) {
      case "csv":
        readerComponent = <CsvReader uploadedFile={file} />;
        break;
      case "xls":
      case "xlsx":
        readerComponent = <ExcelReader uploadedFile={file} />;
        break;
      case "pdf":
        readerComponent = <PdfReader uploadedFile={file} />;
        break;
      default:
        readerComponent = <div>Unsupported file format: {fileExtension}</div>;
        break;
    }

    return (
      <div className="flex align-items-center justify-content-between flex-wrap w-full">
        {readerComponent}
        <Button
          icon="pi pi-times"
          severity="danger"
          label="Remove"
          onClick={() => props.onRemove(file)}
          className="mt-5"
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop ether a pdf or csv here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-folder-plus",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };
  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={suppliers}
          selection={selectedsuppliers}
          onSelectionChange={(e) => setSelectedsuppliers(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} suppliers"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="first_name"
            header="First name"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>
          <Column
            field="last_name"
            header="Last Name"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column field="type" sortable header="Type"></Column>
          <Column
            field="phone"
            header="Phone Number"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="email"
            header="Email"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="service"
            header="Service"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="document_type"
            header="Doc Type"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="document_number"
            header="Doc Number"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={supplierInfoDialog}
        style={{ width: "39rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Supplier Detail"
        modal
        className="p-fluid"
        footer={supplierInfoDialogFooter}
        onHide={hideSupplierInfoDialogue}
      >
        <div class="grid">
          <div class="col-6">
            <div>
              <p className="font-bold">
                <span>Supplier Name:</span> <small>{selectedSupplier.name}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Supplier Type:</span> <small>{selectedSupplier.type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Measuring Units:</span>{" "}
              <small>{selectedSupplier.measuring_unit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <p>
                <span> Payable:</span> <small>{selectedSupplier.payable}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Earn Commission:</span>{" "}
              <small>{selectedSupplier.earn_commission}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Unit rate:</span> <small>{selectedSupplier.unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>calculate unit rate:</span>{" "}
              <small>{selectedSupplier.calculate_unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit type:</span>{" "}
              <small>{selectedSupplier.deposit_type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit:</span> <small>{selectedSupplier.deposit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum units:</span>{" "}
              <small>{selectedSupplier.minimum_units}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum charge:</span>{" "}
              <small>{selectedSupplier.minimum_charge}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Alocate surplus:</span>{" "}
              <small>{selectedSupplier.allocate_surplus}</small>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={supplierDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Supplier"
        modal
        className="p-fluid"
        footer={supplierDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="first_name" className="font-bold">
            First Name
          </label>
          <InputText
            id="first_name"
            value={supplier.first_name}
            onChange={(e) => onInputChange(e, "first_name")}
            required={true}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !supplier.first_name,
            })}
          />
          {submitted && !supplier.first_name && (
            <small className="p-error">first_name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="last_name" className="font-bold">
            Last Name
          </label>
          <InputText
            id="first_name"
            value={supplier.last_name}
            onChange={(e) => onInputChange(e, "last_name")}
            required={true}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !supplier.last_name,
            })}
          />
          {submitted && !supplier.last_name && (
            <small className="p-error">last_name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="company" className="font-bold">
            Company Name
          </label>
          <InputText
            id="first_name"
            value={supplier.company}
            onChange={(e) => onInputChange(e, "company")}
            required={true}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !supplier.company,
            })}
          />
          {submitted && !supplier.company && (
            <small className="p-error">last_name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="service" className="font-bold">
            service
          </label>
          <Dropdown
            value={supplier.service} // Use the supplier.type as the value of the dropdown
            onChange={(e) => onServiceChange(e)} // Pass the entire event to the handler
            options={service}
            optionLabel="name"
            placeholder={
              supplier.service ? `${supplier.service}` : "Select doccument type"
            }
            className="w-full md:w-14rem"
          />
          {submitted && !supplier.service && (
            <small className="p-error">service is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="type" className="font-bold">
            Type
          </label>
          <Dropdown
            value={supplier.type} // Use the supplier.type as the value of the dropdown
            onChange={(e) => onTypeChange(e)} // Pass the entire event to the handler
            options={types}
            optionLabel="name"
            placeholder={
              supplier.type ? `${supplier.type}` : "Select supplier type"
            }
            className="w-full md:w-14rem"
          />
          {submitted && !supplier.type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="document_type" className="font-bold">
            Document_type
          </label>
          <Dropdown
            value={supplier.document_type} // Use the supplier.type as the value of the dropdown
            onChange={(e) => onDocTypeChange(e)} // Pass the entire event to the handler
            options={docTypes}
            optionLabel="name"
            placeholder={
              supplier.type
                ? `${supplier.document_type}`
                : "Select doccument type"
            }
            className="w-full md:w-14rem"
          />
          {submitted && !supplier.document_type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="document_number" className="font-bold">
            Document_number
          </label>
          <InputText
            id="measuring_unit"
            value={supplier.document_number}
            onChange={(e) => onInputChange(e, "document_number")}
            required
          />
          {submitted && !supplier.document_number && (
            <small className="p-error">phone is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="phone" className="font-bold">
            phone Number
          </label>
          <InputText
            id="measuring_unit"
            value={supplier.phone}
            onChange={(e) => onInputChange(e, "phone")}
            required
          />
          {submitted && !supplier.phone && (
            <small className="p-error">phone is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="secondary_phone" className="secondary_phone">
            secondary phone
          </label>
          <InputText
            id="secondary_phone"
            value={supplier.secondary_phone}
            onChange={(e) => onInputChange(e, "secondary_phone")}
            required
          />
          {submitted && !supplier.secondary_phone && (
            <small className="p-error">secondary_phone is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="email" className="email">
            Email
          </label>
          <InputText
            id="email"
            value={supplier.email}
            onChange={(e) => onInputChange(e, "email")}
            required
          />
          {submitted && !supplier.email && (
            <small className="p-error">email is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="address" className="email">
            address
          </label>
          <InputText
            id="address"
            value={supplier.address}
            onChange={(e) => onInputChange(e, "address")}
            required
          />
          {submitted && !supplier.address && (
            <small className="p-error">address is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="postal_code" className="email">
            postal_code
          </label>
          <InputText
            id="address"
            value={supplier.postal_code}
            onChange={(e) => onInputChange(e, "postal_code")}
            required
          />
          {submitted && !supplier.address && (
            <small className="p-error">address is required.</small>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={supplierUploadDialog}
        style={{ width: "60rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Supplier"
        modal
        className="p-fluid"
        footer={supplierDialogUploadFooter}
        onHide={hideUploadDialog}
      >
        <div>
          <Toast ref={toast}></Toast>

          <Tooltip
            target=".custom-choose-btn"
            content="Choose"
            position="bottom"
          />
          <Tooltip
            target=".custom-cancel-btn"
            content="Clear"
            position="bottom"
          />

          <FileUpload
            ref={fileUploadRef}
            name="demo[]"
            multiple
            accept=".pdf,.csv"
            maxFileSize={1000000}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            cancelOptions={cancelOptions}
            onUpload={(e) => upload(e)}
            encType="multipart/form-data"
          />
        </div>
      </Dialog>

      <Dialog
        visible={deletesupplierDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deletesupplierDialogFooter}
        onHide={hideDeletesupplierDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {supplier && (
            <span>
              Are you sure you want to delete <b>{supplier.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deletesuppliersDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deletesuppliersDialogFooter}
        onHide={hideDeletesuppliersDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {supplier && (
            <span>Are you sure you want to delete the selected suppliers?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
