import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchTenantDocumentAPI,
  createTenantDocumentAPI,
  updateTenantDocumentAPI,
  deleteTenantDocumentAPI,
} from "./tenantDocumentAPI";

export const fetchTenantDocument = createAsyncThunk(
  "tenantDocument/fetchTenantDocument",
  async (id, { rejectWithValue }) => {
    try {
      const tenantDocument = await fetchTenantDocumentAPI(id);
      return tenantDocument;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createTenantDocument = createAsyncThunk(
  "tenantDocument/createTenantDocument",
  async (tenantDocumentData, { rejectWithValue }) => {
    try {
      const tenantDocument = await createTenantDocumentAPI(tenantDocumentData);
      return tenantDocument;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateTenantDocument = createAsyncThunk(
  "tenantDocument/updateTenantDocument",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedTenantDocument = await updateTenantDocumentAPI(id, newData);
      return updatedTenantDocument;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTenantDocument = createAsyncThunk(
  "tenantDocument/deleteTenantDocument",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteTenantDocumentAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  tenantDocument: null,
  loading: false,
  error: null,
};

const tenantDocumentSlice = createSlice({
  name: "tenantDocument",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenantDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTenantDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.tenantDocument = action.payload;
      })
      .addCase(fetchTenantDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createTenantDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTenantDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.tenantDocument = action.payload;
      })
      .addCase(createTenantDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTenantDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTenantDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.tenantDocument = action.payload;
      })
      .addCase(updateTenantDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteTenantDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTenantDocument.fulfilled, (state) => {
        state.loading = false;
        state.tenantDocument = null;
      })
      .addCase(deleteTenantDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default tenantDocumentSlice.reducer;
