import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchTenantAPI,
  createTenantAPI,
  updateTenantAPI,
  deleteTenantAPI,
  fetchAllTenantsAPI,
  uploadTenantAPI
} from "./tenantAPI";


export const fetchAllTenant = createAsyncThunk(
  "premise/fetchAllTenant",
  async (id, { rejectWithValue }) => {

    try {
      const premises = await fetchAllTenantsAPI(id); 
      return premises;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchTenant = createAsyncThunk(
  "tenant/fetchTenant",
  async (id, { rejectWithValue }) => {
    try {
      const tenant = await fetchTenantAPI(id);
      return tenant;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createTenant = createAsyncThunk(
  "tenant/createTenant",
  async (tenantData, { rejectWithValue }) => {
    try {
      const tenant = await createTenantAPI(tenantData);
      return tenant;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadTenant = createAsyncThunk(
  "tenant/uploadTenant",
  async (tenantData, { rejectWithValue }) => {
    try {
      const tenant = await uploadTenantAPI(tenantData);
      return tenant;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateTenant = createAsyncThunk(
  "tenant/updateTenant",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedTenant = await updateTenantAPI(id, newData);
      return updatedTenant;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTenant = createAsyncThunk(
  "tenant/deleteTenant",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteTenantAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  Tenants:[],
  tenant: null,
  loading: false,
  error: null,
};

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.Tenants = action.payload;
      })
      .addCase(fetchAllTenant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.tenant = action.payload;
      })
      .addCase(fetchTenant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.tenant = action.payload;
      })
      .addCase(createTenant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.tenant = action.payload;
      })
      .addCase(updateTenant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTenant.fulfilled, (state) => {
        state.loading = false;
        state.tenant = null;
      })
      .addCase(deleteTenant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default tenantSlice.reducer;
