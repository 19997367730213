import React from 'react';
import { Menubar } from 'primereact/menubar';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

export default function AdminDashNav() {
    const itemRenderer = (item) => (
        <Link to={item.path || '#'} className="flex align-items-center p-menuitem-link">
            <span className={item.icon} />
            <span className="mx-2">{item.label}</span>
            {item.badge && <span className="ml-auto">{item.badge}</span>}
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </Link>
    );

    const items = [
        {
            label: 'Home',
            icon: 'pi pi-home',
            path: '/', // Add a path for navigation
            template: itemRenderer,
        },
        {
            label: 'Admin Settings',
            icon: 'pi pi-cog',
            path: 'admin-dashboard/admin-settings',
            template: itemRenderer,
        },
        {
            label: 'Admin Reports',
            icon: 'pi pi-chart-line',
            path: 'admin-dashboard/admin-reports',
            template: itemRenderer,
        },
        {
            label: 'Advertise Properties', // New item for advertising properties
            icon: 'pi pi-verified', // Add icon for Advertise Properties
            path: 'admin-dashboard/advertise-properties',
            template: itemRenderer,
        },
    ];

    return (
        <div className="card">
            <Menubar model={items} />
        </div>
    );
}
