import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { fetchAllProperties } from '../../Redux/Properties/PropertySlice';
import { fetchAllPremises } from '../../Redux/Premises/premiseSlice';
import { useParams } from 'react-router-dom';

export default function OccupancyChart() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {loading,properties} = useSelector((state) => state.properties);
    const premises = useSelector((state) => state.premise.premises);

    const [selectedProperty, setSelectedProperty] = useState(null); // Initialize with null or an initial value

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    // Fetch properties and premises when component mounts or when ID changes
    useEffect(() => {
        dispatch(fetchAllProperties(id));
    }, [dispatch, id]);

    // When properties change, select the first property by default
    useEffect(() => {
        if (properties.length > 0) {
            setSelectedProperty(properties[0]);
        }
    }, [properties]);

    // Fetch premises when a property is selected
    useEffect(() => {
        if (selectedProperty) {
            dispatch(fetchAllPremises(selectedProperty.id));
        }
    }, [dispatch, selectedProperty]);

    // Calculate occupancy data for the chart
    useEffect(() => {
        if (selectedProperty && premises.length > 0) {
            const occupiedCount = premises.filter(premise => premise.occupied).length;
            const vacantCount = premises.length - occupiedCount;

            const data = {
                labels: ['Occupied', 'Vacant'],
                datasets: [
                    {
                        data: [occupiedCount, vacantCount],
                        backgroundColor: [
                            occupiedCount > 0 ? '--blue-500' : '--gray-500', // Color for occupied (blue if occupied, gray if not)
                            vacantCount > 0 ? '--green-500' : '--gray-500', // Color for vacant (green if vacant, gray if not)
                        ],
                        hoverBackgroundColor: [
                            occupiedCount > 0 ? '--blue-400' : '--gray-400', // Hover color for occupied
                            vacantCount > 0 ? '--green-400' : '--gray-400', // Hover color for vacant
                        ],
                    }
                ]
            };

            const options = {
                maintainAspectRatio: false,
                aspectRatio: 2,
                plugins: {
                    legend: {
                        labels: {
                            color: '--text-color' // Adjust as needed
                        }
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        } else {
            // Handle case where no premises are found for the selected property
            const data = {
                labels: ['No Premises'],
                datasets: [
                    {
                        data: [1], // Just to have a value, since the chart expects data
                        backgroundColor: ['--red-500'], // Use gray for no premises state
                        hoverBackgroundColor: ['--gray-400'], // Hover color for no premises state
                    }
                ]
            };

            const options = {
                maintainAspectRatio: false,
                aspectRatio: 2,
                plugins: {
                    legend: {
                        labels: {
                            color: '--text-color' // Adjust as needed
                        }
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [selectedProperty, premises]);

    // Handle property change event
    const handlePropertyChange = (e) => {
        setSelectedProperty(e.value);
    };

    // Render loading state if properties are not yet loaded
    if (loading) {
        return <div> Loading properties...</div>; // Handle loading state
    }

    if (!loading && properties.length === 0) {
        return <div> <span className='pi pi-exclamation-triangle'></span>No properties available.</div>; // Show this message if no properties are available
    }

    return (
        <div className="card">
            {/* Property dropdown */}
            <Dropdown
                value={selectedProperty}
                options={properties.map(property => ({
                    label: property.property_name,
                    value: property,
                }))}
                onChange={handlePropertyChange}
                placeholder="Select a Property"
                className="mb-4"
                optionLabel="label"
                optionValue="value"
            />
            {/* Chart */}
            <Chart type="pie" data={chartData} options={chartOptions} />
        </div>
    );
}
