import { useEffect } from "react";
import Cookies from "js-cookie";
import { BaseUrl } from "../Redux/Constants";

const CheckTokenValidity = () => {
  useEffect(() => {
    const checkToken = async () => {
      const token = Cookies.get("token");

      if (!token) {
        return;
      }

      try {
        const response = await fetch(`${BaseUrl}/api/check-token/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        if (response.ok) {
          return;
        } else {
          Cookies.remove("token");
          localStorage.removeItem("user");
          localStorage.removeItem("_cur_item");
        }
      } catch (error) {
        Cookies.remove("token");
        localStorage.removeItem("user");
        localStorage.removeItem("_cur_item");
      }
    };

    checkToken();
  });

  return null;
};

export default CheckTokenValidity;
