import React, { useEffect, useState } from "react";
import { Menubar } from "primereact/menubar";
import { Badge } from "primereact/badge";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const [itemId, setItemId] = useState("");

  useEffect(() => {
    const storedItemId = localStorage.getItem("_cur_item_id");
    if (storedItemId) {
      setItemId(storedItemId);
    }
  }, []);

  const itemRenderer = (item) => (
    <Link to={item.path} className="flex align-items-center p-menuitem-link">
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </Link>
  );
  const items = [
    {
      label: "Bills",
      icon: "pi pi-money-bill",
      path: `/property/${itemId}/bills`,
      template: itemRenderer,
    },
    {
      label: "Expenses",
      icon: "pi pi-wallet",
      path: `/property/${itemId}/expenses`,
      template: itemRenderer,
    },
    {
      label: "Income",
      icon: "pi pi-chart-line",
      path: `/property/${itemId}/income`,
      template: itemRenderer,
    },
    {
      label: "Landlords",
      icon: "pi pi-user",
      path: `/property/${itemId}/landlords`,
      template: itemRenderer,
    },
    {
      label: "Lease",
      icon: "pi pi-file",
      path: `/property/${itemId}/leases`,
      template: itemRenderer,
    },
    {
      label: "Premises",
      icon: "pi pi-home",
      path: `/property/${itemId}/premises`,
      template: itemRenderer,
    },
    {
      label: "Supplier",
      icon: "pi pi-shopping-cart",
      path: `/property/${itemId}/suppliers`,
      template: itemRenderer,
    },
    {
      label: "Tenant",
      icon: "pi pi-users",
      path: `/property/${itemId}/tenant`,
      template: itemRenderer,
    },
    {
      label: "Reports",
      icon: "pi pi-book",
      path: `/property/${itemId}/reports`,
      template: itemRenderer,
    },
    {
      label: "Dashboard",
      icon: "pi pi-home",
      path: "/dashboard", // Specify path for Home
      template: itemRenderer,
    },
    {
      label: "Home",
      icon: "pi pi-home",
      path: "/", // Specify path for Home
      template: itemRenderer,
    },
  ];

  return (
    <div className="card">
      <Menubar model={items} />
    </div>
  );
}
