// store.js

import { configureStore } from "@reduxjs/toolkit";
import authReducer, { loginUser, logoutUser } from "./Auth/authSlice";
import userReducer from "../Redux/user/userSlice";
import usersReducer from "../Redux/users/userSlice";
import billReducer from "../Redux/Bills/billSlice";
import landlordReducer from "../Redux/Landlords/landlordSlice";
import premiseReducer from "../Redux/Premises/premiseSlice";
import incomeReducer from "../Redux/Income/incomeSlice"
import tenantReducer from '../Redux/Tenant/tenantSlice';
import tenantDocumentReducer from '../Redux/TenantDocument/tenantDocumentSlice';
import leaseReducer from '../Redux/lease/leaseSlice';
import supplierReducer from '../Redux/supplier/supplierSlice';
import supplierDocumentReducer from '../Redux/supplierDocument/supplierDocumentSlice'; 
import expenseReducer from '../Redux/expense/expenseSlice';
import expenseDocumentReducer from '../Redux/expenseDocument/expenseDocumentSlice';
import propertiesReducer from "../Redux/Properties/PropertySlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    bills: billReducer,
    landlord: landlordReducer,
    premise: premiseReducer,
    income:incomeReducer,
    tenant: tenantReducer,
    tenantDocument: tenantDocumentReducer,
    lease: leaseReducer,
    supplier: supplierReducer,
    supplierDocument: supplierDocumentReducer,
    expense: expenseReducer,
    expenseDocument: expenseDocumentReducer,
    properties: propertiesReducer,
  },
});

export { store, loginUser, logoutUser };
