import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputNumber } from "primereact/inputnumber";
import { fetchAllUsers } from "../Redux/users/userSlice.js";
import Loading from "../ClientDashboard/Loading.jsx";
export default function AdminDash() {
  const Customers = useSelector((state) => state.users.Users);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Add loading state
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "country.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    balance: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");



  useEffect(() => {
    dispatch(
      fetchAllUsers(JSON.parse(localStorage.getItem("user"))?.id || null)
    );
  }, [dispatch]);

  useEffect(() => {
    if (Customers && Customers.length > 0) {
      setCustomers(Customers);
      setLoading(false); // Set loading to false when data is fetched
    }
  }, [Customers]);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
        <h4 className="m-0">Customers</h4>
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt="flag"
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flag flag-${rowData.last_name}`}
          style={{ width: "24px" }}
        />
        <span>{rowData.last_name}</span>
      </div>
    );
  };

  const balanceBodyTemplate = (rowData) => {
    if (rowData.balance) {
      return formatCurrency(rowData.balance);
    }
  };

  const balanceFilterTemplate = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };

  const header = renderHeader();

  return (
    <div className="card">
      {loading ? ( // Conditional rendering based on loading state
        <Loading />
      ) : (
        <DataTable
          value={customers}
          paginator
          header={header}
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          selectionMode="checkbox"
          selection={selectedCustomers}
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={[
            "first_name",
            "last_name",
            "email",
            "balance",
            "status",
          ]}
          emptyMessage="No customers found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column
            field="first_name"
            header="First Name"
            sortable
            filter
            filterPlaceholder="Search by name"
            style={{ minWidth: "14rem" }}
          />
          <Column
            field="last_name"
            header="Last Name"
            sortable
            filterField="last_name"
            style={{ minWidth: "14rem" }}
            body={countryBodyTemplate}
            filter
            filterPlaceholder="Search by country"
          />
          <Column
            field="email"
            header="Email"
            sortable
            sortField="email"
            filterField="email"
            showFilterMatchModes={false}
            filterMenuStyle={{ width: "14rem" }}
            style={{ minWidth: "14rem" }}
            filter
          />
          <Column
            field="balance"
            header="Balance"
            sortable
            dataType="numeric"
            style={{ minWidth: "12rem" }}
            body={balanceBodyTemplate}
            filter
            filterElement={balanceFilterTemplate}
          />
        </DataTable>
      )}
    </div>
  );
}
