import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx"; // Import XLSX library for Excel file parsing
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { createBill } from "../../Redux/Bills/billSlice";

const allowedExtensions = ["xlsx", "xls"];

const ExcelReader = ({ uploadedFile }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (uploadedFile) {
      handleParse(uploadedFile);
    }
  }, [uploadedFile]);

  const handleParse = (file) => {
    const fileExtension = file?.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      setError("Please input an Excel file (xlsx or xls)");
      return;
    }

    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const arrayBuffer = target.result;
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      // Assuming only one sheet for simplicity
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      console.log("Parsed data:", parsedData); // Debugging

      if (parsedData.length > 0) {
        const headers = parsedData[0];
        const cols = headers.map((header) => ({
          field: header,
          header: header.charAt(0).toUpperCase() + header.slice(1),
          isEditing: false
        }));
        const dataWithoutHeaders = parsedData.slice(1); // Remove headers row
        setData(dataWithoutHeaders);
        setColumns(cols);
        console.log(dataWithoutHeaders);
      } else {
        setError("No data found in the Excel file");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case "quantity":
      case "price":
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }

    const updatedData = [...data];
    const rowIndex = data.findIndex((item) => item === rowData);
    if (rowIndex !== -1) {
      updatedData[rowIndex] = { ...rowData };
      setData(updatedData);
    }
  };

  const cellEditor = (options) => {
    if (options.field === "price") return priceEditor(options);
    else return textEditor(options);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
      />
    );
  };

  const priceEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="currency"
        currency="USD"
        locale="en-US"
        onKeyDown={(e) => e.stopPropagation()}
      />
    );
  };

  const onHeaderEditComplete = (field, newValue) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.field === field ? { ...col, header: newValue, isEditing: false } : col
      )
    );
  };

  const headerEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.header}
        onBlur={(e) => onHeaderEditComplete(options.field, e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onHeaderEditComplete(options.field, e.target.value);
          }
        }}
        autoFocus
      />
    );
  };

  const renderHeader = (header, field) => {
    const col = columns.find((col) => col.field === field);
    if (col.isEditing) {
      return headerEditor({ header, field });
    }
    return (
      <div onClick={() => toggleHeaderEdit(field)}>
        {header} <i className="pi pi-pencil" style={{ marginLeft: "0.5rem" }} />
      </div>
    );
  };

  const toggleHeaderEdit = (field) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.field === field ? { ...col, isEditing: !col.isEditing } : col
      )
    );
  };

  const upload = () => {
    data.forEach((bill) => {
      dispatch(createBill(bill));
    });
  };

  return (
    <div className="p-grid p-nogutter w-full">
      <div className="p-col-12">
        <Card
          title="Please edit the fields if needed"
          subTitle="Just click on the field you want to edit"
          className="p-shadow-5 w-full text-center"
        >
          <div className="p-fluid w-full">
            {error ? (
              <Message severity="error" text={error} />
            ) : (
              <DataTable
                value={data}
                editMode="cell"
                tableStyle={{ minWidth: "20rem" }}
              >
                {columns.map(({ field, header }) => (
                  <Column
                    key={field}
                    field={field}
                    header={renderHeader(header, field)}
                    style={{ width: "25%" }}
                    editor={(options) => cellEditor(options)}
                    onCellEditComplete={onCellEditComplete}
                  />
                ))}
              </DataTable>
            )}
          </div>
        </Card>
      </div>
      <div className="p-col-12">
        <Button
          label="Upload"
          icon="pi pi-cloud-upload"
          onClick={upload}
          className="p-button-success p-button-raised"
        />
      </div>
    </div>
  );
};

export default ExcelReader;
