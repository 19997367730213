import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchLeaseAPI,
  createLeaseAPI,
  updateLeaseAPI,
  deleteLeaseAPI,
  fetchAllLeasesAPI,
  uploadLeaseAPI
} from "./leaseAPI";

export const fetchAllLeases = createAsyncThunk(
  "leases/fetchAllLeases",
  async (id, { rejectWithValue }) => {
    try {
      const leases = await fetchAllLeasesAPI(id);
      return leases;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchLease = createAsyncThunk(
  "lease/fetchLease",
  async (id, { rejectWithValue }) => {
    try {
      const lease = await fetchLeaseAPI(id);
      return lease;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createLease = createAsyncThunk(
  "lease/createLease",
  async (leaseData, { rejectWithValue }) => {
    try {
      const lease = await createLeaseAPI(leaseData);
      return lease;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadLease = createAsyncThunk(
  "lease/uploadLease",
  async (leaseData, { rejectWithValue }) => {
    try {
      const lease = await uploadLeaseAPI(leaseData);
      return lease;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateLease = createAsyncThunk(
  "lease/updateLease",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedLease = await updateLeaseAPI(id, newData);
      return updatedLease;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteLease = createAsyncThunk(
  "lease/deleteLease",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteLeaseAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  Leases: [],
  lease: null,
  loading: false,
  error: null,
};

const leaseSlice = createSlice({
  name: "lease",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchAllLeases.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchAllLeases.fulfilled, (state, action) => {
      state.loading = false;
      state.Leases = action.payload;
    })
    .addCase(fetchAllLeases.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
      .addCase(fetchLease.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLease.fulfilled, (state, action) => {
        state.loading = false;
        state.lease = action.payload;
      })
      .addCase(fetchLease.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createLease.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLease.fulfilled, (state, action) => {
        state.loading = false;
        state.lease = action.payload;
      })
      .addCase(createLease.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateLease.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLease.fulfilled, (state, action) => {
        state.loading = false;
        state.lease = action.payload;
      })
      .addCase(updateLease.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteLease.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteLease.fulfilled, (state) => {
        state.loading = false;
        state.lease = null;
      })
      .addCase(deleteLease.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default leaseSlice.reducer;
