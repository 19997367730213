import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/api/expenses/`;
const UPLOAD_URL = "https://backend.primdigitalsolutions.com/api/upload-expenses/";


const fetchAllExpensesAPI = async (property) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}?property=${property}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message || 'Error fetching all expenses');
  }
};


const fetchExpenseAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const createExpenseAPI = async (expenseData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(BASE_URL, expenseData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const uploadExpenseAPI = async (expenseData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(UPLOAD_URL, expenseData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const updateExpenseAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.put(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteExpenseAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export {
  fetchExpenseAPI,
  createExpenseAPI,
  updateExpenseAPI,
  deleteExpenseAPI,
  fetchAllExpensesAPI,
  uploadExpenseAPI
};
