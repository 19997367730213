import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register } from "../Redux/Auth/authSlice";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';

const Register = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    company_name: '',
    password: '',
    user_type: 'individual'
  });

  const { loading, error, registrationSuccess } = useSelector((state) => state.auth);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUserTypeChange = (e) => {
    setFormData({ ...formData, user_type: e.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(register(formData));
  };

  useEffect(() => {
    if (registrationSuccess && toast.current) {
      toast.current.show({
        severity: 'success',
        summary: 'Registration Successful',
        detail: 'You have been registered successfully.Please confirm your email.',
        life: 4000
      });
      setVisible(false);
    }
  }, [registrationSuccess, setVisible]);

  useEffect(() => {
    if (error && toast.current) {
      toast.current.show({
        severity: 'error',
        summary: 'Registration Failed',
        detail: error || 'An error occurred during registration.',
        life: 3000
      });
    }
  }, [error]);

  const userTypes = [
    { label: 'Individual', value: 'individual' },
    { label: 'Company', value: 'company' }
  ];

  return (
    <div>
      <Toast ref={toast} />

      <Dialog
        header="Register"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: '30vw' }}
        breakpoints={{ '960px': '75vw', '640px': '90vw' }}
      >
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <label>User Type:</label>
              <Dropdown
                value={formData.user_type}
                options={userTypes}
                onChange={handleUserTypeChange}
                placeholder="Select User Type"
              />
            </div>

            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Username:</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>First Name:</label>
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Last Name:</label>
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Phone Number:</label>
              <input
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                required
              />
            </div>

            {formData.user_type === 'company' && (
              <div>
                <label>Company Name:</label>
                <input
                  type="text"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  required
                />
              </div>
            )}

            <div>
              <label>Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>

            <Button
              label="Register"
              type="submit"
              disabled={loading}
              severity="success"
              className="m-3"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default Register;
