import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchExpenseAPI,
  createExpenseAPI,
  updateExpenseAPI,
  deleteExpenseAPI,
  fetchAllExpensesAPI,
  uploadExpenseAPI
} from "./expenseAPI";

export const fetchExpense = createAsyncThunk(
  "expense/fetchExpense",
  async (id, { rejectWithValue }) => {
    console.log(id);
    try {
      const expense = await fetchExpenseAPI(id);
      return expense;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAllExpenses = createAsyncThunk(
  "expense/fetchAllExpenses",
  async (id, { rejectWithValue }) => {
    try {
      const expenses = await fetchAllExpensesAPI(id);
      return expenses;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createExpense = createAsyncThunk(
  "expense/createExpense",
  async (expenseData, { rejectWithValue }) => {
    try {
      const expense = await createExpenseAPI(expenseData);
      return expense;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadExpense = createAsyncThunk(
  "expense/uploadExpense",
  async (expenseData, { rejectWithValue }) => {
    try {
      const expense = await uploadExpenseAPI(expenseData);
      return expense;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExpense = createAsyncThunk(
  "expense/updateExpense",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedExpense = await updateExpenseAPI(id, newData);
      return updatedExpense;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteExpense = createAsyncThunk(
  "expense/deleteExpense",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteExpenseAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  expenses: [], 
  loading: false,
  error: null,
};

const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllExpenses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllExpenses.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses = action.payload; 
      })
      .addCase(fetchAllExpenses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchExpense.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.expense = action.payload; 
      })
      .addCase(fetchExpense.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createExpense.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses.push(action.payload); 
      })
      .addCase(createExpense.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateExpense.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExpense.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.expenses.findIndex(expense => expense.id === action.payload.id);
        if (index >= 0) {
          state.expenses[index] = action.payload;
        }
      })
      .addCase(updateExpense.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteExpense.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses = state.expenses.filter(expense => expense.id !== action.payload.id);
      })
      .addCase(deleteExpense.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default expenseSlice.reducer;
