import React, { useState, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';

export default function PropertyImages() {
    const [images, setImages] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];

    useEffect(() => {
        // Fetch a set of images from Unsplash
        const fetchImages = async () => {
            const imageUrls = [
                "https://source.unsplash.com/random/800x600",
                "https://source.unsplash.com/random/800x600",
                "https://source.unsplash.com/random/800x600",
                "https://source.unsplash.com/random/800x600",
                "https://source.unsplash.com/random/800x600"
            ];
            const imageList = imageUrls.map(url => ({
                itemImageSrc: url,
                thumbnailImageSrc: url,
                alt: "Property Image"
            }));
            setImages(imageList);
        };

        fetchImages();
    }, []);

    const itemTemplate = (item) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    };

    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block', width: '100px', height: '75px' }} />;
    };

    return (
        <div className="card">
            <Galleria value={images} responsiveOptions={responsiveOptions} numVisible={5} style={{ maxWidth: '640px' }} 
                item={itemTemplate} thumbnail={thumbnailTemplate} circular autoPlay transitionInterval={2000} />
        </div>
    );
}
