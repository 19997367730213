import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchIncomeAPI,
  createIncomeAPI,
  updateIncomeAPI,
  deleteIncomeAPI,
  fetchAllIncomeAPI,
  uploadIncomeAPI
} from "./incomeAPI";

export const fetchAllIncome = createAsyncThunk(
  "income/fetchAllIncome",
  async (id, { rejectWithValue }) => {
    try {
      const allIncome = await fetchAllIncomeAPI(id); 
      return allIncome;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchIncome = createAsyncThunk(
  "income/fetchIncome",
  async (id, { rejectWithValue }) => {
    try {
      const income = await fetchIncomeAPI(id);
      return income;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createIncome = createAsyncThunk(
  "income/createIncome",
  async (incomeData, { rejectWithValue }) => {
    try {
      const income = await createIncomeAPI(incomeData);
      return income;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadIncome = createAsyncThunk(
  "income/uploadIncome",
  async (incomeData, { rejectWithValue }) => {
    try {
      const income = await uploadIncomeAPI(incomeData);
      return income;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateIncome = createAsyncThunk(
  "income/updateIncome",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedIncome = await updateIncomeAPI(id, newData);
      return updatedIncome;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteIncome = createAsyncThunk(
  "income/deleteIncome",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteIncomeAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  income: [],
  loading: false,
  error: null,
};

const incomeSlice = createSlice({
  name: "income",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncome.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIncome.fulfilled, (state, action) => {
        state.loading = false;
        state.income = action.payload;
      })
      .addCase(fetchIncome.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllIncome.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllIncome.fulfilled, (state, action) => {
        state.loading = false;
        state.income = action.payload;
      })
      .addCase(fetchAllIncome.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createIncome.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createIncome.fulfilled, (state, action) => {
        state.loading = false;
        state.income = action.payload;
      })
      .addCase(createIncome.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateIncome.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateIncome.fulfilled, (state, action) => {
        state.loading = false;
        state.income = action.payload;
      })
      .addCase(updateIncome.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteIncome.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteIncome.fulfilled, (state) => {
        state.loading = false;
        state.income = null;
      })
      .addCase(deleteIncome.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default incomeSlice.reducer;
