import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/api/leases/`;
const UPLOAD_URL = "https://backend.primdigitalsolutions.com/api/upload-leases/";

const fetchAllLeasesAPI = async (property) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}?property=${property}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message || "Error fetching all expenses");
  }
};

const fetchLeaseAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const createLeaseAPI = async (leaseData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(BASE_URL, leaseData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const uploadLeaseAPI = async (leaseData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(UPLOAD_URL, leaseData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const updateLeaseAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.put(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteLeaseAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export {
  fetchAllLeasesAPI,
  fetchLeaseAPI,
  createLeaseAPI,
  updateLeaseAPI,
  deleteLeaseAPI,
  uploadLeaseAPI,
};
