import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/api/users/`;

const fetchUserAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const editUserAPI = async (id, newData) => {
  try {
    const token = Cookies.get('token');
    const response = await axios.put(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteUserAPI = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}${id}/`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export { fetchUserAPI, editUserAPI, deleteUserAPI };
