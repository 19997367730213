import React from "react";
import Hero from "./Hero";
import { Button } from "primereact/button";
import image from "../Images/dash.png";
import { Link } from "react-router-dom";

const Home = () => {
  const handleClick = () => {
    window.location.href = "https://chat.whatsapp.com/BCZQSh4C5DsI7cY4uWF5iZ";
  };
  return (
    <>
      <Hero />

      <div className="grid grid-nogutter surface-0 text-800 pb-7">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
          <section>
            <span className="block text-5xl font-bold mb-1">
              Prime Digital Solutions
            </span>
            <div className="text-5xl text-primary font-bold mb-3">
              About Our Company
            </div>
            <p className="mt-0 mb-4 text-700 line-height-3">
              Prime Digital Solutions is a leading software development company
              dedicated to creating innovative, custom software solutions that
              drive business success. Our team of experts delivers high-quality,
              scalable, and secure software, transforming ideas into powerful
              digital tools. Partner with us to experience unparalleled service
              and cutting-edge technology that sets your business apart.
            </p>

            <Link to="/contact">
              <Button
                label="Contact Us"
                type="button"
                className="p-button-outlined"
              />
            </Link>
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden">
          <img
            src={image}
            alt="hero-1"
            className="md:ml-auto block md:h-full"
            style={{ clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)" }}
          />
        </div>
      </div>

      <div className="bg-bluegray-900 text-center">
        <div className="mb-3 font-bold text-3xl pt-7">
          <span className="text-white">One Product, </span>
          <span className="text-blue-600">Many Solutions</span>
        </div>
        <div className="text-white mb-6">
          
        </div>
        <div className="grid m-0">
          <div className="col-12 md:col-4 mb-4 px-5">
            <span
              className="p-3 shadow-2 mb-3 inline-block"
              style={{ borderRadius: "10px" }}
            >
              <i className="pi pi-desktop text-4xl text-blue-500"></i>
            </span>
            <div className="text-white text-xl mb-3 font-medium">
              Automated Rent Collection
            </div>
            <span className="text-white line-height-3">
              Streamline rent collection with automated reminders and online
              payment options.
            </span>
          </div>
          <div className="col-12 md:col-4 mb-4 px-5">
            <span
              className="p-3 shadow-2 mb-3 inline-block"
              style={{ borderRadius: "10px" }}
            >
              <i className="pi pi-lock text-4xl text-blue-500"></i>
            </span>
            <div className="text-white text-xl mb-3 font-medium">
              Robust Security Features
            </div>
            <span className="text-white line-height-3">
              Ensure the safety of your data with end-to-end encryption, secure
              user authentication, and regular security updates.
            </span>
          </div>
          <div className="col-12 md:col-4 mb-4 px-5">
            <span
              className="p-3 shadow-2 mb-3 inline-block"
              style={{ borderRadius: "10px" }}
            >
              <i className="pi pi-check-circle text-4xl text-blue-500"></i>
            </span>
            <div className="text-white text-xl mb-3 font-medium">
              Detailed Financial Reporting
            </div>
            <span className="text-white line-height-3">
              Generate income statements, expense reports, and profit & loss
              reports.
            </span>
          </div>
          <div className="col-12 md:col-4 mb-4 px-5">
            <span
              className="p-3 shadow-2 mb-3 inline-block"
              style={{ borderRadius: "10px" }}
            >
              <i className="pi pi-users text-4xl text-blue-500"></i>
            </span>
            <div className="text-white text-xl mb-3 font-medium">
              Tenant Management
            </div>
            <span className="text-white line-height-3">
              Simplified tenant onboarding and offboarding processes.
            </span>
          </div>
          <div className="col-12 md:col-4 mb-4 px-5">
            <span
              className="p-3 shadow-2 mb-3 inline-block"
              style={{ borderRadius: "10px" }}
            >
              <i className="pi pi-file text-4xl text-blue-500"></i>
            </span>
            <div className="text-white text-xl mb-3 font-medium">
              Lease Management
            </div>
            <span className="text-white line-height-3">
              Automated lease tracking, renewal reminders, and expiry
              notifications.{" "}
            </span>
          </div>
          <div className="col-12 md:col-4 md:mb-4 mb-0 px-3">
            <span
              className="p-3 shadow-2 mb-3 inline-block"
              style={{ borderRadius: "10px" }}
            >
              <i className="pi pi-wrench text-4xl text-blue-500"></i>
            </span>
            <div className="text-white text-xl mb-3 font-medium">
              Maintenance Management
            </div>
            <span className="text-white line-height-3">
              Easy submission and tracking of maintenance requests by tenants.
            </span>
          </div>
        </div>
      </div>

      <div className="surface-0">
        <div className="text-900 font-bold text-6xl mb-4 text-center p-4">
          Pricing Plans
        </div>

        <div className="grid m-0">
          <div className="col-12 lg:col-3">
            <div className="p-3 h-full">
              <div
                className="shadow-2 p-3 h-full flex flex-column"
                style={{ borderRadius: "6px" }}
              >
                <div className="text-900 font-medium text-xl mb-2">Bronze</div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <div className="flex align-items-center">
                  <span className="font-bold text-2xl text-900">
                    ksh 4999/=
                  </span>
                  <span className="ml-2 font-medium text-600">per month</span>
                </div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <ul className="list-none p-0 m-0 flex-grow-1">
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>from 1 unit to 50 units</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Automated invoicing</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Automated Reports</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>free SMS</span>
                  </li>
                </ul>
                <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
              </div>
            </div>
          </div>

          <div className="col-12 lg:col-3">
            <div className="p-3 h-full">
              <div
                className="shadow-2 p-3 h-full flex flex-column"
                style={{ borderRadius: "6px" }}
              >
                <div className="text-900 font-medium text-xl mb-2">Silver</div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <div className="flex align-items-center">
                  <span className="font-bold text-2xl text-900">
                    ksh 6999/=
                  </span>
                  <span className="ml-2 font-medium text-600">per month</span>
                </div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <ul className="list-none p-0 m-0 flex-grow-1">
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>from 51 unit to 100 units</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Automated invoicing</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Automated Reports</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>free SMS</span>
                  </li>
                </ul>
                <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
              </div>
            </div>
          </div>

          <div className="col-12 lg:col-3">
            <div className="p-3 h-full">
              <div
                className="shadow-2 p-3 h-full flex flex-column"
                style={{ borderRadius: "6px" }}
              >
                <div className="text-900 font-medium text-xl mb-2">Gold</div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <div className="flex align-items-center">
                  <span className="font-bold text-2xl text-900">
                    ksh 8999/=
                  </span>
                  <span className="ml-2 font-medium text-600">per month</span>
                </div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <ul className="list-none p-0 m-0 flex-grow-1">
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>from 101 unit to 150 units</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Automated invoicing</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Automated Reports</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>free SMS</span>
                  </li>
                </ul>
                <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
              </div>
            </div>
          </div>

          <div className="col-12 lg:col-3">
            <div className="p-3 h-full">
              <div
                className="shadow-2 p-3 flex flex-column"
                style={{ borderRadius: "6px" }}
              >
                <div className="text-900 font-medium text-xl mb-2">
                  Platinum
                </div>
                <div className="text-600">We have a custom pricing for you</div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <div className="flex align-items-center"></div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <ul className="list-none p-0 m-0 flex-grow-1">
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>from 150 unit and above</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Automated invoicing</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Automated Reports</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>free SMS</span>
                  </li>
                </ul>
                <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="surface-0 text-700 text-center pb-5 pt-5 surface-100">
        <div className="text-green-500 font-bold mb-3">
          <i className="pi pi-whatsapp"></i>&nbsp;POWERED BY WHATSAPP
        </div>
        <div className="text-900 font-bold text-5xl mb-3">
          Join Our Community
        </div>
        <div className="text-700 text-2xl mb-5">
          Join our WhatsApp for consultation about any of our softwares for free
        </div>
        <Button
          onClick={handleClick}
          label="Join Now"
          severity="success"
          icon="pi pi-whatsapp"
          className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
        />
      </div>
    </>
  );
};

export default Home;
