import React from "react";

const Error = ({ err }) => {
  return ( 
    <div className="flex h-screen justify-content-center align-items-center bg-red">
      <i severity="danger" className="pi pi-exclamation-triangle ">{err}</i>
    </div>
  );
};

export default Error;
