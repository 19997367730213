import React from "react";
import "../css/NotFound.css";

const NotFound = () => {
  return (
    <div className="app-error-section">
      <h1 className="app-error-title">404</h1>
      <div className="app-error-message">
        Oops! The page you are looking for was not found.
      </div>
    </div>
  );
};

export default NotFound;
