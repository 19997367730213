import React from 'react'
import BillTable from './tables/BillTable'

const Bills = () => {
  return (
    <div>
      <BillTable />
    </div>
  )
}

export default Bills