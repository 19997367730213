import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../Redux/Auth/authSlice";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { useAuth } from "./Hooks/useAuth";

const Login = ({ visible, setVisible }) => {
  const { login } = useAuth();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { isAuthenticated, loading, error, user } = useSelector(
    (state) => state.auth
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const resultAction = await dispatch(loginUser(formData));
    if (loginUser.fulfilled.match(resultAction)) {
      login(resultAction.payload.data.user);
    }
  };

  useEffect(() => {
    if (!error && isAuthenticated && toast.current) {
      setVisible(false);
    }
  }, [isAuthenticated, error, setVisible]);

  useEffect(() => {
    if (error && toast.current) {
      toast.current.show({
        severity: "error",
        summary: "Login Failed",
        detail: error || "An error occurred during login.",
        life: 3000,
      });
    }
  }, [error]);

  const handlePasswordResetClick = () => {
    setVisible(false);
  };

  return (
    <div>
      <Toast ref={toast} />

      <Dialog
        header="Login"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "640px": "90vw" }}
      >
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <Button
              label="Login"
              type="submit"
              disabled={loading}
              severity="success"
              className="m-3"
            />
          </form>
          <small>
            Forgot password?{" "}
            <Link to="/password-reset" onClick={handlePasswordResetClick}>
              Reset password
            </Link>
          </small>
        </div>
      </Dialog>
    </div>
  );
};

export default Login;
