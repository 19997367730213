import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

import Error from "./Error";
import Loading from "./Loading";
import { useParams } from "react-router-dom";
import {
  fetchAllLeases,
  createLease,
  deleteLease,
  updateLease,
  uploadLease,
} from "../Redux/lease/leaseSlice";
import { fetchAllPremises } from "../Redux/Premises/premiseSlice";
import { fetchAllTenant } from "../Redux/Tenant/tenantSlice";
import CsvReader from "./fileReaders/CsvReader";
import PdfReader from "./fileReaders/PdfReader";
import ExcelReader from "./fileReaders/ExelReader";

export default function Leases() {
  const { id } = useParams();
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState([]);

  let emptyLease = {
    property: id,
    premise: "", // Required field for the lease name
    tenant: "",
    lease_period: "", // Required field, must be one of the choices defined in BILL_TYPES
    document_date: "", // Required field for measuring unit
    start_date: "", // Required field, must be one of the choices defined in PAYABLE_FREQUENCY
    expiry_date: "", // Required field, default is False
    tenant_leave_date: null, // Required field for unit rate, default amount or variable lease rate
    review_period: "", // Required field, default is False
    lease_review_date: null, // Required field, default is False
    deposit_held: "", // Required field, default is False
    penalty_type: "", // Required field for lease percentage
    penalty_date: null, // Required field for percentage (without %)
    penalty_rate: 0, // Required field for minimum units
    lease_notice_period: 0, // Required field for appropriation priority
    lease_notice_date: null, // Required field, default is False
    created_by: JSON.parse(localStorage.getItem("user"))?.id || null,
  };

  const dispatch = useDispatch();
  const [leases, setleases] = useState([]);
  const [selectedLease, setSelectedLease] = useState([]);
  const [leaseInfoDialog, setLeaseInfoDialog] = useState(false);
  const [leaseDialog, setleaseDialog] = useState(false);
  const [leaseUploadDialog, setleaseUploadDialog] = useState(false);
  const [deleteleaseDialog, setDeleteleaseDialog] = useState(false);
  const [deleteleasesDialog, setDeleteleasesDialog] = useState(false);
  const [lease, setlease] = useState(emptyLease);
  const [selectedleases, setSelectedleases] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const Leases = useSelector((state) => state.lease.Leases);
  const loading = useSelector((state) => state.lease.loading);
  const error = useSelector((state) => state.lease.error);
  const Tenants = useSelector((state) => state.tenant.Tenants);
  const Premises = useSelector((state) => state.premise.premises);

  useEffect(() => {
    dispatch(fetchAllLeases(id));
    dispatch(fetchAllPremises(id));
    dispatch(fetchAllTenant(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (Leases && Leases.length > 0) {
      setleases(Leases);
    }
  }, [Leases]);

  const openNew = () => {
    setlease(emptyLease);
    setSubmitted(false);
    setleaseDialog(true);
  };

  const openNewUpload = () => {
    setSubmitted(false);
    setleaseUploadDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setleaseDialog(false);
  };

  const hideUploadDialog = () => {
    setFile([]);
    setSubmitted(false);
    setleaseUploadDialog(false);
  };

  const hideDeleteleaseDialog = () => {
    setDeleteleaseDialog(false);
  };

  const hideDeleteleasesDialog = () => {
    setDeleteleasesDialog(false);
  };

  const hideLeaseInfoDialogue = () => {
    setLeaseInfoDialog(false);
  };

  const savelease = () => {
    if (lease.id) {
      dispatch(updateLease({ id: lease.id, newData: lease }))
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Lease Updated",
            life: 3000,
          });
          dispatch(fetchAllLeases(id));
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "error",
            detail: `${error}`,
            life: 3000,
          });
        });
    } else {
      dispatch(createLease(lease))
        .then((er) => {
          console.log(error);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Lease Created",
            life: 3000,
          });
          dispatch(fetchAllLeases(id));
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "error",
            detail: `${error}`,
            life: 3000,
          });
        });
    }

    // Close the lease dialog and reset the lease form
    setleaseDialog(false);
    setlease(emptyLease);
  };

  const upload = async () => {
    if (!file || file.length === 0) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "No file selected",
        life: 3000,
      });
      return;
    }

    const formData = new FormData();
    file.forEach((file) => {
      formData.append("file", file);
    });
    dispatch(uploadLease(formData))
      .then((er) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Lease file Uploaded",
          life: 3000,
        });
        setleaseUploadDialog(false);
        setFile([]);
      })
      .catch((error) => {
        toast.current.show({
          severity: "success",
          summary: "failed",
          detail: error,
          life: 3000,
        });
      });
  };

  const leaseInfo = (leaseId) => {
    const expe = Leases.find((b) => b.id === leaseId);
    setSelectedLease(expe);
    setLeaseInfoDialog(true);
  };

  const editlease = (lease) => {
    setlease({ ...lease });
    setleaseDialog(true);
  };

  const confirmDeletelease = (lease) => {
    setlease(lease);
    setDeleteleaseDialog(true);
  };

  const deletelease = () => {
    dispatch(deleteLease(lease.id));
    let _leases = leases.filter((val) => val.id !== lease.id);
    setleases(_leases);
    setDeleteleaseDialog(false);
    setlease(emptyLease);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "lease Deleted",
      life: 3000,
    });
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const DownloadLease = () => {};

  const confirmDeleteSelected = () => {
    setDeleteleasesDialog(true);
  };

  const deleteSelectedleases = async () => {
    for (const lease of selectedleases) {
      try {
        dispatch(deleteLease(lease.id));
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: `${error.message}`,
          life: 3000,
        });
      }
    }

    const remainingLeases = leases.filter(
      (lease) =>
        !selectedleases.some((selectedLease) => selectedLease.id === lease.id)
    );

    setleases(remainingLeases);
    setSelectedleases(null);
    setDeleteleasesDialog(false);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Leases Deleted",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _lease = { ...lease };

    _lease[`${name}`] = val;

    setlease(_lease);
  };

  const onPremiseChange = (e) => {
    const selectedValue = e.value.id;
    const updatedLease = {
      ...lease,
      premise: selectedValue,
    };

    setlease(updatedLease);
  };

  const onTenantChange = (e) => {
    const selectedValue = e.value.id;
    const updatedLease = {
      ...lease,
      tenant: selectedValue,
    };

    setlease(updatedLease);
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const onDocDateChange = (e) => {
    const selectedValue = e.value;
    const date = new Date(selectedValue);
    const updatedIncome = {
      ...lease,
      document_date: formatDate(date),
    };
    setlease(updatedIncome);
  };

  const onStartDateChange = (e) => {
    const selectedValue = e.value;
    const date = new Date(selectedValue);
    const updatedIncome = {
      ...lease,
      start_date: formatDate(date),
    };
    setlease(updatedIncome);
  };

  const onExpiryDateChange = (e) => {
    const selectedValue = e.value;
    const date = new Date(selectedValue);
    const updatedIncome = {
      ...lease,
      expiry_date: formatDate(date),
    };
    setlease(updatedIncome);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New Lease"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete Leases"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedleases || !selectedleases.length}
        />
        <Button
          label="Add from a file"
          icon="pi pi-file-excel"
          severity="success"
          onClick={openNewUpload}
        />
      </div>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default();

        // Extract the current data from the DataTable
        const currentData = leases; // This is the current data from the DataTable

        // Define the table headers (columns)
        const tableHeaders = [
          { title: "Lease No", dataKey: "lease_number" },
          { title: "Name", dataKey: "name" },
          { title: "Type", dataKey: "type" },
          { title: "Payable", dataKey: "payable" },
          { title: "Earn Commission", dataKey: "earn_commission" },
          { title: "Measuring Units", dataKey: "measuring_unit" },
          { title: "Calculate Unit Rate", dataKey: "calculate_unit_rate" },
          { title: "Deposit", dataKey: "deposit" },
          // Add more columns as needed
        ];

        // Create the PDF table
        doc.autoTable({
          startY: 20, // Adjust the starting Y position as needed
          head: [tableHeaders], // Pass the table headers
          body: currentData, // Pass the current data
        });

        // Save the PDF file
        doc.save("leases.pdf");
      });
    });
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Export csv"
          icon="pi pi-upload"
          className="p-button-help mr-2"
          onClick={exportCSV}
        />
        <Button
          label="Export pdf"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportPdf}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editlease(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          className="mr-2"
          onClick={() => confirmDeletelease(rowData)}
        />
        <Button
          icon="pi pi-info"
          rounded
          outlined
          style={{ color: "black" }}
          onClick={() => leaseInfo(rowData.id)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Leases</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );
  const leaseDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={savelease} />
    </React.Fragment>
  );
  const leaseDialogUploadFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideUploadDialog}
      />
    </React.Fragment>
  );
  const deleteleaseDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteleaseDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deletelease}
      />
    </React.Fragment>
  );
  const deleteleasesDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteleasesDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedleases}
      />
    </React.Fragment>
  );

  const incomeInfoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        outlined
        onClick={hideLeaseInfoDialogue}
      />{" "}
      <Button label="Download" icon="pi pi-download" onClick={DownloadLease} />
    </React.Fragment>
  );

  if (error) {
    return <Error err={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    const selectedFiles = e.files;

    let newFiles = [];
    Object.keys(selectedFiles).forEach((key) => {
      _totalSize += selectedFiles[key].size || 0;
      newFiles.push(selectedFiles[key]);
    });

    setTotalSize(_totalSize);
    setFile(newFiles);
  };

  const onTemplateClear = () => {
    setFile([]);
    setTotalSize(0);
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Render different components based on the file extension
    let readerComponent;
    switch (fileExtension) {
      case "csv":
        readerComponent = <CsvReader uploadedFile={file} />;
        break;
      case "xls":
      case "xlsx":
        readerComponent = <ExcelReader uploadedFile={file} />;
        break;
      case "pdf":
        readerComponent = <PdfReader uploadedFile={file} />;
        break;
      default:
        readerComponent = <div>Unsupported file format: {fileExtension}</div>;
        break;
    }

    return (
      <div className="flex align-items-center justify-content-between flex-wrap w-full">
        {readerComponent}
        <Button
          icon="pi pi-times"
          severity="danger"
          label="Remove"
          onClick={() => props.onRemove(file)}
          className="mt-5"
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop ether a pdf or csv here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-folder-plus",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={leases}
          selection={selectedleases}
          onSelectionChange={(e) => setSelectedleases(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} leases"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="lease_number"
            header="Lease No"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>
          <Column
            field="tenant"
            header="Tenant "
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="premise"
            header="Premise"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="start_date"
            header="From date"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="expiry_date"
            header="Expiry Date"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="tenant_leave_date"
            header="Day Tenant left"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="show_readings_in_invoice"
            header="Bills"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column field="type" sortable header="Penalty"></Column>
          <Column
            field="deposit"
            header="Balances"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={leaseInfoDialog}
        style={{ width: "39rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Lease Detail"
        modal
        className="p-fluid"
        footer={incomeInfoDialogFooter}
        onHide={hideLeaseInfoDialogue}
      >
        <div class="grid">
          <div class="col-6">
            <div>
              <p className="font-bold">
                <span>lease_period:</span> <small>{selectedLease.lease_period}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Income Type:</span> <small>{selectedLease.type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Measuring Units:</span>{" "}
              <small>{selectedLease.measuring_unit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <p>
                <span> Payable:</span> <small>{selectedLease.payable}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Earn Commission:</span>{" "}
              <small>{selectedLease.earn_commission}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Unit rate:</span> <small>{selectedLease.unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>calculate unit rate:</span>{" "}
              <small>{selectedLease.calculate_unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit type:</span>{" "}
              <small>{selectedLease.deposit_type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit:</span> <small>{selectedLease.deposit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum units:</span>{" "}
              <small>{selectedLease.minimum_units}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum charge:</span>{" "}
              <small>{selectedLease.minimum_charge}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Alocate surplus:</span>{" "}
              <small>{selectedLease.allocate_surplus}</small>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={leaseDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Lease"
        modal
        className="p-fluid"
        footer={leaseDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="premise" className="font-bold">
            Premise
          </label>
          <Dropdown
            value={lease.premise} // Use the lease.type as the value of the dropdown
            onChange={(e) => onPremiseChange(e)} // Pass the entire event to the handler
            options={Premises}
            optionLabel="name"
            placeholder={lease.premise ? `${lease.premise}` : "Select premise"}
            className="w-full md:w-14rem"
          />
          {submitted && !lease.type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="tenant" className="font-bold">
            Tenant
          </label>
          <Dropdown
            value={lease.tenant} // Use the lease.type as the value of the dropdown
            onChange={(e) => onTenantChange(e)} // Pass the entire event to the handler
            options={Tenants}
            optionLabel="name"
            placeholder={lease.tenant ? `${lease.tenant}` : "Select a tenant"}
            className="w-full md:w-14rem"
          />
          {submitted && !lease.type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="lease_period" className="font-bold">
            lease period
          </label>
          <InputText
            id="lease_period"
            value={lease.measuring_unit}
            onChange={(e) => onInputChange(e, "lease_period")}
            required
          />
          {submitted && !lease.lease_period && (
            <small className="p-error">lease_period is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="document_date" className="font-bold">
            Doccument date
          </label>
          <Calendar
            id="document_date"
            dateFormat="yy-mm-dd."
            required
            value={lease.document_date}
            onChange={(e) => onDocDateChange(e)}
          />
          {submitted && lease.document_date <= 0 && (
            <small className="p-error">enter date</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="start_date" className="font-bold">
            Start Date
          </label>
          <Calendar
            id="start_date"
            dateFormat="yy-mm-dd."
            required
            value={lease.document_date}
            onChange={(e) => onStartDateChange(e)}
          />
          {submitted && lease.start_date <= 0 && (
            <small className="p-error">enter start_date</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="expiry_date" className="font-bold">
            Expiry Date
          </label>
          <Calendar
            id="expiry_date"
            dateFormat="yy-mm-dd."
            required
            value={lease.expiry_date}
            onChange={(e) => onExpiryDateChange(e)}
          />
          {submitted && lease.start_date <= 0 && (
            <small className="p-error">enter expiry_date</small>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteleaseDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteleaseDialogFooter}
        onHide={hideDeleteleaseDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {lease && (
            <span>
              Are you sure you want to delete <b>{lease.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={leaseUploadDialog}
        style={{ width: "60rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Lease"
        modal
        className="p-fluid"
        footer={leaseDialogUploadFooter}
        onHide={hideUploadDialog}
      >
        <div>
          <Toast ref={toast}></Toast>

          <Tooltip
            target=".custom-choose-btn"
            content="Choose"
            position="bottom"
          />
          <Tooltip
            target=".custom-cancel-btn"
            content="Clear"
            position="bottom"
          />

          <FileUpload
            ref={fileUploadRef}
            name="demo[]"
            multiple
            accept=".pdf,.csv"
            maxFileSize={1000000}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            cancelOptions={cancelOptions}
            onUpload={(e) => upload(e)}
            encType="multipart/form-data"
          />
        </div>
      </Dialog>

      <Dialog
        visible={deleteleasesDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteleasesDialogFooter}
        onHide={hideDeleteleasesDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {lease && (
            <span>Are you sure you want to delete the selected leases?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
