import axios from 'axios';
import Cookies from 'js-cookie';
import { BaseUrl } from '../Constants';

const BASE_URL = `${BaseUrl}/api/incomes/`;
const UPLOAD_URL = "https://backend.primdigitalsolutions.com/api/upload-incomes/";


const fetchIncomeAPI = async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchAllIncomeAPI = async (property) => {
  console.log(property);
  try {
    const token = Cookies.get('token');
    const response = await axios.get(`${BASE_URL}?property=${property}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const createIncomeAPI = async (incomeData) => {
  try {
    const token = Cookies.get('token');
    const response = await axios.post(BASE_URL, incomeData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const uploadIncomeAPI = async (incomeData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(UPLOAD_URL, incomeData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


const updateIncomeAPI = async (id, newData) => {
  try {
    const token = Cookies.get('token');
    const response = await axios.put(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteIncomeAPI = async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export {
  fetchIncomeAPI,
  fetchAllIncomeAPI,
  createIncomeAPI,
  updateIncomeAPI,
  deleteIncomeAPI,
  uploadIncomeAPI
};
