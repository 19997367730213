import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchExpenseDocumentAPI,
  createExpenseDocumentAPI,
  updateExpenseDocumentAPI,
  deleteExpenseDocumentAPI,
} from "./expenseDocumentAPI";

export const fetchExpenseDocument = createAsyncThunk(
  "expenseDocument/fetchExpenseDocument",
  async (id, { rejectWithValue }) => {
    try {
      const expenseDocument = await fetchExpenseDocumentAPI(id);
      return expenseDocument;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createExpenseDocument = createAsyncThunk(
  "expenseDocument/createExpenseDocument",
  async (expenseDocumentData, { rejectWithValue }) => {
    try {
      const expenseDocument = await createExpenseDocumentAPI(
        expenseDocumentData
      );
      return expenseDocument;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExpenseDocument = createAsyncThunk(
  "expenseDocument/updateExpenseDocument",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedExpenseDocument = await updateExpenseDocumentAPI(
        id,
        newData
      );
      return updatedExpenseDocument;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteExpenseDocument = createAsyncThunk(
  "expenseDocument/deleteExpenseDocument",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteExpenseDocumentAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  expenseDocument: null,
  loading: false,
  error: null,
};

const expenseDocumentSlice = createSlice({
  name: "expenseDocument",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenseDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExpenseDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.expenseDocument = action.payload;
      })
      .addCase(fetchExpenseDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createExpenseDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createExpenseDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.expenseDocument = action.payload;
      })
      .addCase(createExpenseDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateExpenseDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExpenseDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.expenseDocument = action.payload;
      })
      .addCase(updateExpenseDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteExpenseDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteExpenseDocument.fulfilled, (state) => {
        state.loading = false;
        state.expenseDocument = null;
      })
      .addCase(deleteExpenseDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default expenseDocumentSlice.reducer;
