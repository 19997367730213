import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';

const AdminReports = () => {
    // Sample data for DataTable
    const [reports, setReports] = useState([
        { id: 1, date: '2024-08-01', amount: 1200, status: 'Paid', category: 'Rent' },
        { id: 2, date: '2024-08-02', amount: 800, status: 'Pending', category: 'Utilities' },
        { id: 3, date: '2024-08-03', amount: 1500, status: 'Paid', category: 'Maintenance' },
        // Add more data as needed
    ]);

    // Sample data for financial charts
    const [chartData, setChartData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Revenue',
                backgroundColor: '#42A5F5',
                data: [4000, 4500, 3000, 5000, 4000, 6000]
            },
            {
                label: 'Expenses',
                backgroundColor: '#FF6384',
                data: [2000, 2200, 1800, 2600, 2400, 3000]
            }
        ]
    });

    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true
            },
            y: {
                beginAtZero: true
            }
        }
    });

    // Filter state
    const [statusFilter, setStatusFilter] = useState(null);
    const [amountFilter, setAmountFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [filteredReports, setFilteredReports] = useState(reports);

    const filterReports = () => {
        let filtered = reports;
        if (statusFilter) {
            filtered = filtered.filter(report => report.status === statusFilter);
        }
        if (amountFilter) {
            filtered = filtered.filter(report => report.amount >= amountFilter);
        }
        if (dateFilter) {
            filtered = filtered.filter(report => new Date(report.date) >= new Date(dateFilter));
        }
        setFilteredReports(filtered);
    };

    // Dialog state
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);

    const showReportDetails = (report) => {
        setSelectedReport(report);
        setDialogVisible(true);
    };

    const hideDialog = () => {
        setDialogVisible(false);
    };

    return (
        <div className="p-grid p-fluid">
            <div className="p-col-12 p-lg-6">
                <Card title="Financial Overview">
                    <Chart type="bar" data={chartData} options={chartOptions} />
                </Card>
            </div>
            <div className="p-col-12 p-lg-6">
                <Card title="Revenue vs Expenses">
                    <Chart type="line" data={chartData} options={chartOptions} />
                </Card>
            </div>
            <div className="p-col-12">
                <Card title="Recent Reports" className="p-mb-4">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4">
                            <Dropdown
                                value={statusFilter}
                                options={[{ label: 'All', value: null }, { label: 'Paid', value: 'Paid' }, { label: 'Pending', value: 'Pending' }]}
                                onChange={(e) => setStatusFilter(e.value)}
                                placeholder="Select Status"
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <InputNumber
                                value={amountFilter}
                                onValueChange={(e) => setAmountFilter(e.value)}
                                mode="currency"
                                currency="USD"
                                locale="en-US"
                                placeholder="Minimum Amount"
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <Calendar
                                value={dateFilter}
                                onChange={(e) => setDateFilter(e.value)}
                                dateFormat="yy-mm-dd"
                                placeholder="Start Date"
                            />
                        </div>
                        <div className="p-col-12">
                            <Button label="Apply Filters" icon="pi pi-filter" onClick={filterReports} />
                        </div>
                    </div>
                    <DataTable value={filteredReports} paginator rows={5} header="Reports" onRowClick={(e) => showReportDetails(e.data)}>
                        <Column field="id" header="ID" sortable />
                        <Column field="date" header="Date" sortable />
                        <Column field="amount" header="Amount" sortable body={(rowData) => `$${rowData.amount}`} />
                        <Column field="status" header="Status" sortable />
                        <Column field="category" header="Category" sortable />
                    </DataTable>
                </Card>
            </div>

            <Dialog header="Report Details" visible={dialogVisible} onHide={hideDialog} style={{ width: '50vw' }}>
                {selectedReport && (
                    <div>
                        <p><strong>ID:</strong> {selectedReport.id}</p>
                        <p><strong>Date:</strong> {selectedReport.date}</p>
                        <p><strong>Amount:</strong> ${selectedReport.amount}</p>
                        <p><strong>Status:</strong> {selectedReport.status}</p>
                        <p><strong>Category:</strong> {selectedReport.category}</p>
                    </div>
                )}
            </Dialog>
        </div>
    );
};

export default AdminReports;
