import React from "react";
import Heading from "./Heading";
import "../css/hero.css";

const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="cont container">
          <Heading
            title="Prime Digital Solutions"
            subtitle="Your Smart Real Estate Management Solution!"
          />
        </div>
      </section>
    </>
  );
};

export default Hero;
