import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/api/landlords/`;
const UPLOAD_URL = "https://backend.primdigitalsolutions.com/api/upload-landlords/";


const fetchAllLandlordsAPI = async (property) => {
  try {
    const token = Cookies.get("token");
    // Send a GET request to the base URL to fetch all landlords
    const response = await axios.get(`${BASE_URL}?property=${property}`, {
      headers: {
        Authorization: `Token ${token}`, // Include authorization token in the headers
      },
    });
    // Return the data from the response
    return response.data;
  } catch (error) {
    // If an error occurs, throw an error with the response message
    throw new Error(
      error.response ? error.response.data.message : "An error occurred"
    );
  }
};

const fetchLandlordAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const createLandlordAPI = async (landlordData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(BASE_URL, landlordData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const uploadLandlordAPI = async (landlordData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(UPLOAD_URL, landlordData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const updateLandlordAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.put(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteLandlordAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export {
  fetchLandlordAPI,
  fetchAllLandlordsAPI,
  createLandlordAPI,
  updateLandlordAPI,
  deleteLandlordAPI,
  uploadLandlordAPI
};
