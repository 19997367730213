import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { Tooltip } from "primereact/tooltip";
import { useParams } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

import Error from "./Error";
import Loading from "./Loading";
import {
  fetchAllIncome,
  deleteIncome,
  createIncome,
  updateIncome,
} from "../Redux/Income/incomeSlice";
import { fetchAllBills } from "../Redux/Bills/billSlice";
import { fetchAllPremises } from "../Redux/Premises/premiseSlice";
import CsvReader from "./fileReaders/CsvReader";
import PdfReader from "./fileReaders/PdfReader";
import ExcelReader from "./fileReaders/ExelReader";

export default function IncomeTable() {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState([]);
  const { id } = useParams();

  let emptyIncome = {
    type: "", // Field should match expected choices ("Fixed", etc.)
    date: "", // Initialize with null (or default date), required
    amount_or_current_reading: 0, // Initial value for amount or current reading, required
    payment_method: "", // Payment method, required
    transaction_ref: "", // Reference for transaction, required
    income_receipt: null, // Initialize with null for file submission, required
    bill: "", //this bill is not related to the other bill but related to tenant bills
    premise: "", // Premise reference, required
    select_multiple_premises: [], // Initialize as an empty array for multiple premises selection
    created_by: JSON.parse(localStorage.getItem("user"))?.id || null,
    property: id,
  };

  const dispatch = useDispatch();
  const [incomes, setincomes] = useState([]);
  const [selectedIncome, setSelectedIncome] = useState([]);
  const [incomeInfoDialogue, setIncomeInfoDialog] = useState(false);
  const [incomeDialog, setincomeDialog] = useState(false);
  const [incomeUploadDialog, setincomeUploadDialog] = useState(false);
  const [deleteincomeDialog, setDeleteincomeDialog] = useState(false);
  const [deleteincomesDialog, setDeleteincomesDialog] = useState(false);
  const [income, setincome] = useState(emptyIncome);
  const [selectedincomes, setSelectedincomes] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const Incomes = useSelector((state) => state.income.income);
  const loading = useSelector((state) => state.income.loading);
  const error = useSelector((state) => state.income.error);
  const Bills = useSelector((state) => state.bills.bills);
  const premises = useSelector((state) => state.premise.premises);

  useEffect(() => {
    dispatch(fetchAllIncome(id));
    dispatch(fetchAllBills(id));
    dispatch(fetchAllPremises(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (Incomes && Incomes.length > 0) {
      setincomes(Incomes);
    }
  }, [Incomes]);

  const openNew = () => {
    setincome(emptyIncome);
    setSubmitted(false);
    setincomeDialog(true);
  };

  function formatDate(date) {
    const year = date.getFullYear(); // Get the full year
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get the month (0-based, so add 1) and pad with 0 if necessary
    const day = String(date.getDate()).padStart(2, "0"); // Get the day and pad with 0 if necessary

    // Combine year, month, and day with hyphens
    return `${year}-${month}-${day}`;
  }

  const hideDialog = () => {
    setSubmitted(false);
    setincomeDialog(false);
  };

  const hideDeleteincomeDialog = () => {
    setDeleteincomeDialog(false);
  };
  const hideUploadDialog = () => {
    setFile([]);
    setSubmitted(false);
    setincomeUploadDialog(false);
  };

  const hideDeleteincomesDialog = () => {
    setDeleteincomesDialog(false);
  };

  const saveincome = () => {
    // Dispatch createIncome or updateIncome based on whether the income has an ID
    if (income.id) {
      dispatch(updateIncome({ id: income.id, newData: income }))
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Income Updated",
            life: 3000,
          });
          // Optionally refresh the incomes list
          dispatch(fetchAllIncome(id));
        })
        .catch((error) => {
          // Handle error (e.g. display a message or log it)
          console.error("Error updating income:", error);
        });
    } else {
      // For creating a new income

      dispatch(createIncome(income))
        .then((er) => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Income Created",
            life: 3000,
          });
          // Optionally refresh the incomes list
          dispatch(fetchAllIncome(id));
        })
        .catch((error) => {
          // Handle error (e.g. display a message or log it)
          console.error("Error creating income:", error);
        });
    }

    // Close the income dialog and reset the income form
    setincomeDialog(false);
    setincome(emptyIncome);
  };

  const openNewUpload = () => {
    setSubmitted(false);
    setincomeUploadDialog(true);
  };

  const upload = async () => {
    if (!file || file.length === 0) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "No file selected",
        life: 3000,
      });
      return;
    }
  };

  const DownloadIncome = () => {};

  const hideIncomeInfoDialogue = () => {
    setIncomeInfoDialog(false);
  };

  const incomeInfo = (incomeId) => {
    const expe = Incomes.find((b) => b.id === incomeId);
    setSelectedIncome(expe);
    setIncomeInfoDialog(true);
  };

  const editincome = (income) => {
    setincome({ ...income });
    setincomeDialog(true);
  };

  const confirmDeleteincome = (income) => {
    setincome(income);
    setDeleteincomeDialog(true);
  };

  const deleteincome = () => {
    dispatch(deleteIncome(income.id));
    let _incomes = incomes.filter((val) => val.id !== income.id);
    setincomes(_incomes);
    setDeleteincomeDialog(false);
    setincome(emptyIncome);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "income Deleted",
      life: 3000,
    });
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteincomesDialog(true);
  };

  const deleteSelectedincomes = async () => {
    // Iterate through the selected incomes and dispatch deleteIncome action for each
    for (const income of selectedincomes) {
      try {
        // Dispatch the deleteIncome action for each income ID
        dispatch(deleteIncome(income.id));
      } catch (error) {
        console.error(`Failed to delete income with ID ${income.id}:`, error);
        // Optionally, handle the error (e.g. show a message or log it)
      }
    }

    // After successfully deleting selected incomes, filter out the deleted incomes from the state
    const remainingIncomes = incomes.filter(
      (income) =>
        !selectedincomes.some(
          (selectedIncome) => selectedIncome.id === income.id
        )
    );

    // Update the state to only include the remaining incomes
    setincomes(remainingIncomes);

    // Reset the selected incomes and close the confirmation dialog
    setSelectedincomes(null);
    setDeleteincomesDialog(false);

    // Display a success message
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Incomes Deleted",
      life: 3000,
    });
  };

  const onAmountChange = (e) => {
    const selectedValue = e.value;
    const updatedIncome = {
      ...income,
      amount_or_current_reading: selectedValue,
    };

    setincome(updatedIncome);
  };
  const onDateChange = (e) => {
    const selectedValue = e.value;
    const date = new Date(selectedValue);
    const updatedIncome = {
      ...income,
      date: formatDate(date),
    };
    setincome(updatedIncome);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _income = { ...income };

    _income[`${name}`] = val;

    setincome(_income);
  };

  const onBillChange = (e) => {
    const selectedValue = e.value.id;
    const updatedIncome = {
      ...income,
      bill: selectedValue,
    };

    setincome(updatedIncome);
  };

  const onPremiseChange = (e) => {
    const selectedValue = e.value.id;
    const updatedIncome = {
      ...income,
      premise: selectedValue,
    };

    setincome(updatedIncome);
  };

  const onTypeChange = (e) => {
    const selectedValue = e.value.name;
    const updatedIncome = {
      ...income,
      type: selectedValue,
    };

    setincome(updatedIncome);
  };

  const onMethodChange = (e) => {
    const selectedValue = e.value.name;
    const updatedIncome = {
      ...income,
      payment_method: selectedValue,
    };

    setincome(updatedIncome);
  };
  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New Income"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete Incomes"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedincomes || !selectedincomes.length}
        />
        <Button
          label="Add from a file"
          icon="pi pi-file-excel"
          severity="success"
          onClick={openNewUpload}
        />
      </div>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default();

        // Extract the current data from the DataTable
        const currentData = incomes; // This is the current data from the DataTable

        // Define the table headers (columns)
        const tableHeaders = [
          { title: "Income No", dataKey: "income_number" },
          { title: "Name", dataKey: "name" },
          { title: "Type", dataKey: "type" },
          { title: "Payable", dataKey: "payable" },
          { title: "Earn Commission", dataKey: "earn_commission" },
          { title: "Measuring Units", dataKey: "measuring_unit" },
          { title: "Calculate Unit Rate", dataKey: "calculate_unit_rate" },
          { title: "Deposit", dataKey: "deposit" },
          // Add more columns as needed
        ];

        // Create the PDF table
        doc.autoTable({
          startY: 20, // Adjust the starting Y position as needed
          head: [tableHeaders], // Pass the table headers
          body: currentData, // Pass the current data
        });

        // Save the PDF file
        doc.save("incomes.pdf");
      });
    });
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Export csv"
          icon="pi pi-upload"
          className="p-button-help mr-2"
          onClick={exportCSV}
        />
        <Button
          label="Export pdf"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportPdf}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editincome(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          className="mr-2"
          onClick={() => confirmDeleteincome(rowData)}
        />
        <Button
          icon="pi pi-info"
          rounded
          outlined
          style={{ color: "black" }}
          onClick={() => incomeInfo(rowData.id)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Incomes</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );
  const incomeDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveincome} />
    </React.Fragment>
  );
  const incomeDialogUploadFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideUploadDialog}
      />
    </React.Fragment>
  );
  const incomeInfoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        outlined
        onClick={hideIncomeInfoDialogue}
      />{" "}
      <Button label="Download" icon="pi pi-download" onClick={DownloadIncome} />
    </React.Fragment>
  );

  const deleteincomeDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteincomeDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteincome}
      />
    </React.Fragment>
  );
  const deleteincomesDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteincomesDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedincomes}
      />
    </React.Fragment>
  );
  const bill = Bills;
  const PaymentMethods = [
    {
      name: "Cash",
      code: "Cash",
    },
    {
      name: "Credit Card",
      code: "Credit Card",
    },
    {
      name: "Bank Transfer",
      code: "Bank Transfer",
    },
    {
      name: "Check",
      code: "Check",
    },
  ];

  const types = [
    {
      name: "Invoice",
      code: "invoice",
    },
    {
      name: "Payment",
      code: "payment",
    },
    {
      name: "Credit Note",
      code: "credit_note",
    },
    {
      name: "Refund",
      code: "refund",
    },
  ];

  if (error) {
    return <Error err={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    const selectedFiles = e.files;

    let newFiles = [];
    Object.keys(selectedFiles).forEach((key) => {
      _totalSize += selectedFiles[key].size || 0;
      newFiles.push(selectedFiles[key]);
    });

    setTotalSize(_totalSize);
    setFile(newFiles);
  };

  const onTemplateClear = () => {
    setFile([]);
    setTotalSize(0);
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Render different components based on the file extension
    let readerComponent;
    switch (fileExtension) {
      case "csv":
        readerComponent = <CsvReader uploadedFile={file} />;
        break;
      case "xls":
      case "xlsx":
        readerComponent = <ExcelReader uploadedFile={file} />;
        break;
      case "pdf":
        readerComponent = <PdfReader uploadedFile={file} />;
        break;
      default:
        readerComponent = <div>Unsupported file format: {fileExtension}</div>;
        break;
    }

    return (
      <div className="flex align-items-center justify-content-between flex-wrap w-full">
        {readerComponent}
        <Button
          icon="pi pi-times"
          severity="danger"
          label="Remove"
          onClick={() => props.onRemove(file)}
          className="mt-5"
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop ether a pdf or csv here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-folder-plus",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };
  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={incomes}
          selection={selectedincomes}
          onSelectionChange={(e) => setSelectedincomes(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} incomes"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="income_number"
            header="Income No"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>
          <Column
            field="name"
            header="Name"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column field="type" sortable header="Type"></Column>
          <Column
            field="payable"
            header="Payable"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="earn_commission"
            header="earn_commission"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="measuring_unit"
            header="Measuring Units"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="calculate_unit_rate"
            header="calculate unit rate"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="show_readings_in_invoice"
            header="show readings in invoice"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="deposit"
            header="deposit"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={incomeInfoDialogue}
        style={{ width: "39rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Income Detail"
        modal
        className="p-fluid"
        footer={incomeInfoDialogFooter}
        onHide={hideIncomeInfoDialogue}
      >
        <div class="grid">
          <div class="col-6">
            <div>
              <p className="font-bold">
                <span>Income Name:</span> <small>{selectedIncome.name}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Income Type:</span> <small>{selectedIncome.type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Measuring Units:</span>{" "}
              <small>{selectedIncome.measuring_unit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <p>
                <span> Payable:</span> <small>{selectedIncome.payable}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Earn Commission:</span>{" "}
              <small>{selectedIncome.earn_commission}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Unit rate:</span> <small>{selectedIncome.unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>calculate unit rate:</span>{" "}
              <small>{selectedIncome.calculate_unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit type:</span>{" "}
              <small>{selectedIncome.deposit_type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit:</span> <small>{selectedIncome.deposit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum units:</span>{" "}
              <small>{selectedIncome.minimum_units}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum charge:</span>{" "}
              <small>{selectedIncome.minimum_charge}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Alocate surplus:</span>{" "}
              <small>{selectedIncome.allocate_surplus}</small>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={incomeDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Income"
        modal
        className="p-fluid"
        footer={incomeDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="type" className="font-bold">
            Type
          </label>
          <Dropdown
            value={income.type} // Use the income.type as the value of the dropdown
            onChange={(e) => onTypeChange(e)} // Pass the entire event to the handler
            options={types}
            optionLabel="name"
            placeholder={income.type ? `${income.type}` : "Select income type"}
            className="w-full md:w-14rem"
          />
          {submitted && !income.type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="bill" className="font-bold">
            Bill
          </label>
          <Dropdown
            value={income.bill} // Use the income.type as the value of the dropdown
            onChange={(e) => onBillChange(e)} // Pass the entire event to the handler
            options={bill}
            optionLabel="name"
            placeholder={income.bill ? `${income.bill}` : "Select income type"}
            className="w-full md:w-14rem"
          />
          {submitted && !income.type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="premise" className="font-bold">
            Premise
          </label>
          <Dropdown
            value={income.premise} // Use the income.type as the value of the dropdown
            onChange={(e) => onPremiseChange(e)} // Pass the entire event to the handler
            options={premises}
            optionLabel="name"
            placeholder={
              income.premise ? `${income.premise}` : "Select premise"
            }
            className="w-full md:w-14rem"
          />
          {submitted && !income.premise && (
            <small className="p-error">premise is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="date" className="font-bold">
            Date
          </label>
          <Calendar
            id="date"
            dateFormat="yy-mm-dd."
            required
            value={income.date}
            onChange={(e) => onDateChange(e)}
          />
          {submitted && income.unit_rate <= 0 && (
            <small className="p-error">enter date</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="amount_or_current_reading" className="font-bold">
            amount or current reading
          </label>
          <InputNumber
            id="amount_or_current_reading"
            value={income.amount_or_current_reading}
            onChange={(e) => onAmountChange(e)}
            required
            mode="decimal"
            min={0}
            max={100000}
          />
          {submitted && income.unit_rate <= 0 && (
            <small className="p-error">
              amount or current reading must be positive.
            </small>
          )}
        </div>

        <div className="field">
          <label htmlFor="payment_method" className="font-bold">
            PaymentMethods
          </label>
          <Dropdown
            value={income.payment_method} // Use the income.type as the value of the dropdown
            onChange={(e) => onMethodChange(e)} // Pass the entire event to the handler
            options={PaymentMethods}
            optionLabel="name"
            placeholder={
              income.payment_method
                ? `${income.payment_method}`
                : "Select payment method"
            }
            className="w-full md:w-14rem"
          />
          {submitted && !income.type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="transaction_ref" className="font-bold">
            transaction ref
          </label>
          <InputText
            id="measuring_unit"
            value={income.transaction_ref}
            onChange={(e) => onInputChange(e, "transaction_ref")}
            required
          />
          {submitted && !income.measuring_unit && (
            <small className="p-error">transaction_ref is required.</small>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteincomeDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteincomeDialogFooter}
        onHide={hideDeleteincomeDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {income && (
            <span>
              Are you sure you want to delete <b>{income.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={incomeUploadDialog}
        style={{ width: "60rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add an Income"
        modal
        className="p-fluid"
        footer={incomeDialogUploadFooter}
        onHide={hideUploadDialog}
      >
        <div>
          <Toast ref={toast}></Toast>

          <Tooltip
            target=".custom-choose-btn"
            content="Choose"
            position="bottom"
          />
          <Tooltip
            target=".custom-cancel-btn"
            content="Clear"
            position="bottom"
          />

          <FileUpload
            ref={fileUploadRef}
            name="demo[]"
            multiple
            accept=".pdf,.csv"
            maxFileSize={1000000}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            cancelOptions={cancelOptions}
            onUpload={(e) => upload(e)}
            encType="multipart/form-data"
          />
        </div>
      </Dialog>

      <Dialog
        visible={deleteincomesDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteincomesDialogFooter}
        onHide={hideDeleteincomesDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {income && (
            <span>Are you sure you want to delete the selected incomes?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
