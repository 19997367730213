import axios from 'axios';
import Cookies from 'js-cookie';
import { BaseUrl } from '../Constants';

const BASE_URL = `${BaseUrl}/api/users/`;

const fetchAllUsersAPI = async () => {
  try {
    const token = Cookies.get("token"); 
    const response = await axios.get(BASE_URL, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export { fetchAllUsersAPI };
