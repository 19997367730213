import React, {  useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { TieredMenu } from "primereact/tieredmenu";
import { logoutUser } from "../Redux/Auth/authSlice";
import Cookies from "js-cookie";
import { deleteUser } from "../Redux/Auth/authSlice";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import logo from "../Images/logo.png"
import { useAuth } from "../Authentication/Hooks/useAuth";

export default function Header({ setLoginVisible, setRegisterVisible }) {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toast = useRef(null);
  const menu = useRef(null);
  const [isAuthenticated, setIsAuthenticated] = useState(
    useSelector((state) => state.auth.isAuthenticated)
  );
  const userData = JSON.parse(localStorage.getItem("user"));
  const userRole = userData ? userData.role : null;
  const token = Cookies.get('token');

  const accept = () => {
    dispatch(deleteUser(userData.id));
    Cookies.remove("token");
    logout()
    setIsAuthenticated(false);
    toast.current.show({
      severity: "warn",
      summary: "Confirmed",
      detail: "You have delete your Acc",
      life: 3000,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "info",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirm2 = () => {
    confirmDialog({
      message: "Do you want to delete this Account?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const avarter = [
    {
      label: "Profile",
      icon: "pi pi-file",
      items: [
        {
          label: <Link to="/update-profile">Update Profile</Link>,
          icon: "pi pi-pencil",
          command: () => navigate("/update-profile/1"),
        },
        {
          label: "Delete Profile",
          icon: "pi pi-trash",
          command: () => confirm2(),
        },
      ],
    },
    {
      label: <Link to="/dashboard">Dashboard</Link>,
      icon: "pi pi-align-right",
      command: () => navigate("/dashboard"),
    },
    {
      label: <Link to="/settings">Settings</Link>,
      icon: "pi pi-cog",
      command: () => navigate("/settings"),
    },
    {
      separator: true,
    },
    {
      label: "Share",
      icon: "pi pi-share-alt",
      items: [
        {
          label: "Facebook",
          icon: "pi pi-facebook",
          command: () => window.open("https://facebook.com", "_blank"),
        },
        {
          label: "WhatsApp",
          icon: "pi pi-whatsapp",
          command: () => window.open("https://whatsapp.com", "_blank"),
        },
      ],
    },
  ];

  const itemRenderer = (item) => (
    <Link
      to={item.path}
      className="flex w-50 align-items-center p-menuitem-link"
      key={item.label}
    >
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </Link>
  );

  const items = [
    {
      label: "Home",
      icon: "pi pi-home",
      path: "/",
      template: itemRenderer,
    },
    {
      label: "About",
      icon: "pi pi-info-circle",
      path: "/about",
      template: itemRenderer,
    },
    {
      label: "Contact",
      icon: "pi pi-envelope",
      path: "/contact",
      template: itemRenderer,
    },
    {
      label: "Features",
      icon: "pi pi-envelope",
      path: "/features",
      template: itemRenderer,
    },
    {
      label: "pricing",
      icon: "pi pi-tag",
      path: "/pricing",
      template: itemRenderer,
    },
    userRole === "admin" && {
      label: "Admin Dashboard",
      icon: "pi pi-cog",
      path: "/admin-dashboard",
      template: itemRenderer,
    },
    userRole === "client" && {
      label: "Dashboard",
      icon: "pi pi-user",
      path: "/dashboard",
      template: itemRenderer,
    },
    {
      label: "Other Systems",
      icon: "pi pi-search",
      items: [
        {
          label: "HRMS system",
          icon: "pi pi-bolt",
          shortcut: "⌘+S",
          path: "/projects/core",
          template: itemRenderer,
        },
        {
          label: "FMS system",
          icon: "pi pi-server",
          shortcut: "⌘+B",
          path: "/projects/blocks",
          template: itemRenderer,
        },
        {
          label: "UI Kit",
          icon: "pi pi-pencil",
          shortcut: "⌘+U",
          path: "/projects/ui-kit",
          template: itemRenderer,
        },
        {
          separator: true,
        },
        {
          label: "Templates",
          icon: "pi pi-palette",
          items: [
            {
              label: "Apollo",
              icon: "pi pi-palette",
              badge: 2,
              path: "/projects/templates/apollo",
              template: itemRenderer,
            },
            {
              label: "Ultima",
              icon: "pi pi-palette",
              badge: 3,
              path: "/projects/templates/ultima",
              template: itemRenderer,
            },
          ],
        },
      ],
    },
  ].filter(Boolean);

  const handleLogout = () => {
    dispatch(logoutUser());
    Cookies.remove("token");
    logout();
  };

  const start = (
    <img
      alt="logo"
      src={logo}
      height="50"
      className="mr-2"
    />
  );

  const end = (
    <div className="flex align-items-center gap-2">
      {token ? (
        <>
          <Button
            label="Logout"
            severity="danger"
            text
            raised
            onClick={handleLogout}
          />
          <div className="card flex justify-content-center">
            <TieredMenu model={avarter} popup ref={menu} breakpoint="767px" />
            <Button
              label={userData ? userData.first_name : ""}
              icon="pi pi-user"
              outlined
              onClick={(e) => menu.current.toggle(e)}
            />
          </div>
        </>
      ) : (
        <div className="flex align-items-center gap-4">
          <Button
            label="Login"
            icon="pi pi-sign-in"
            onClick={() => setLoginVisible(true)}
          />
          <Button
            label="Register"
            icon="pi pi-user-plus"
            onClick={() => setRegisterVisible(true)}
            outlined
            severity="success"
          />
        </div>
      )}
    </div>
  );

  return (
    <div >
      <Toast ref={toast} />
      <ConfirmDialog />
      <Menubar model={items} start={start} end={end} />
    </div>
  );
}
