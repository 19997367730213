import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchBillAPI,
  createBillAPI,
  updateBillAPI,
  deleteBillAPI,
  fetchAllBillsAPI,
  uploadBillsAPI,
} from "./billAPI";

export const fetchAllBills = createAsyncThunk(
  "bill/fetchAllBills",
  async (id, { rejectWithValue }) => {
      try {
          const bills = await fetchAllBillsAPI(id);
          return bills;
      } catch (error) {
          return rejectWithValue(error.message);
      }
  }
);

export const fetchBill = createAsyncThunk(
  "bill/fetchBill",
  async (id, { rejectWithValue }) => {
    try {
      const bill = await fetchBillAPI(id);
      return bill;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createBill = createAsyncThunk(
  "bill/createBill",
  async (billData, { rejectWithValue }) => {
    try {
      const bill = await createBillAPI(billData);
      return bill;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadBill = createAsyncThunk(
  "bill/uploadBill",
  async (billData, { rejectWithValue }) => {
    try {
      const bill = await uploadBillsAPI(billData);
      return bill;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBill = createAsyncThunk(
  "bill/updateBill",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedBill = await updateBillAPI(id, newData);
      return updatedBill;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteBill = createAsyncThunk(
  "bill/deleteBill",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteBillAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  bill: [],
  loading: false,
  error: null,
};


const billSlice = createSlice({
  name: "bill",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      
      .addCase(fetchBill.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBill.fulfilled, (state, action) => {
        state.loading = false;
        state.bill = action.payload;
      })
      .addCase(fetchBill.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createBill.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBill.fulfilled, (state, action) => {
        state.loading = false;
        state.bill = action.payload;
      })
      .addCase(createBill.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateBill.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBill.fulfilled, (state, action) => {
        state.loading = false;
        state.bill = action.payload;
      })
      .addCase(updateBill.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteBill.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBill.fulfilled, (state) => {
        state.loading = false;
        state.bill = null;
      })
      .addCase(deleteBill.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllBills.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllBills.fulfilled, (state, action) => {
        state.loading = false;
        state.bills = action.payload;
      })
      .addCase(fetchAllBills.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default billSlice.reducer;
