import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./LandingPage/Header";
import Footer from "./LandingPage/Footer";
import Home from "./LandingPage/Home";
import About from "./LandingPage/About";
import Pricing from "./LandingPage/Pricing";
import Contact from "./LandingPage/Contact";
import Register from "./Authentication/Register";
import Login from "./Authentication/Login";
import AdminDash from "./AdminDashboard/AdminDash";
import Bills from "./ClientDashboard/Bills";
import Expenses from "./ClientDashboard/Expenses";
import Income from "./ClientDashboard/Income";
import Landlords from "./ClientDashboard/Landlords";
import Leases from "./ClientDashboard/Leases";
import Premises from "./ClientDashboard/Premises";
import Supplier from "./ClientDashboard/Supplier";
import Tenant from "./ClientDashboard/Tenant";
import Sidebar from "./ClientDashboard/Sidebar";
import MyProperties from "./ClientDashboard/MyProperties";
import Reports from "./ClientDashboard/Reports";
import NotFound from "./LandingPage/NotFound";
import PasswordReset from "./Authentication/PasswordReset";
import PasswordResetConfirm from "./Authentication/PasswordResetConfirm";
import UpdateProfile from "./Authentication/Profile/UpdateProfile";
import AdminDashNav from "./AdminDashboard/AdminDashNav";
import TopBar from "./LandingPage/TopBar";
import Features from "./LandingPage/Features";
import Settings from "./ClientDashboard/Settings";
import { AuthProvider } from "./Authentication/Hooks/useAuth";
import { ProtectedRoute } from "./Authentication/PrivateRoute";
import AdminSettings from "./AdminDashboard/AdminSettings";
import AdminReports from "./AdminDashboard/AdminReports";
import PropertyAdvets from "./AdminDashboard/PropertyAdvets";

const AppContent = () => {
  const location = useLocation();
  const [isLoginVisible, setLoginVisible] = useState(false);
  const [isRegisterVisible, setRegisterVisible] = useState(false);
  const [receivedData, setReceivedData] = useState("");

  const handleDataFromChild = (data) => {
    setReceivedData(data);
    localStorage.setItem("_cur_item_id", data);
  };

  const hideHeaderAndFooter =
    location.pathname === "/admin-dashboard" ||
    location.pathname.startsWith("/property");

  const [adminRoute, setAdminRoute] = useState([]);

  useEffect(() => {
    const userData = localStorage.getItem("user");

    if (userData) {
      try {
        const parsedUserData = JSON.parse(userData);
        if (parsedUserData && parsedUserData.role === "admin") {
          setAdminRoute([
            { text: "Admin Dashboard", path: "/admin-dashboard" },
          ]);
        }
      } catch (error) {
        console.error("Failed to parse userData:", error);
      }
    }
  }, []);

  return (
    <>
      {location.pathname.startsWith("/property") ? (
        <Sidebar itemId={receivedData} />
      ) : location.pathname.startsWith("/admin-dashboard") ? (
        <AdminDashNav />
      ) : (
        !hideHeaderAndFooter && (
          <>
            <TopBar />
            <Header
              setRegisterVisible={setRegisterVisible}
              setLoginVisible={setLoginVisible}
              adminRoutes={adminRoute}
            />
          </>
        )
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/features" element={<Features />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route
          path="/password-reset/confirm"
          element={<PasswordResetConfirm />}
        />
        <Route path="/update-profile/:id" element={<UpdateProfile />} />
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute>
              <AdminDash />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-dashboard/admin-settings"
          element={
            <ProtectedRoute>
              <AdminSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-dashboard/admin-reports"
          element={
            <ProtectedRoute>
              <PropertyAdvets />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-dashboard/advertise-properties"
          element={
            <ProtectedRoute>
              <AdminReports />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/:id/expenses"
          element={
            <ProtectedRoute>
              <Expenses />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/:id/income"
          element={
            <ProtectedRoute>
              <Income />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/:id/landlords"
          element={
            <ProtectedRoute>
              <Landlords />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/:id/leases"
          element={
            <ProtectedRoute>
              <Leases />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/:id/premises"
          element={
            <ProtectedRoute>
              <Premises />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/:id/suppliers"
          element={
            <ProtectedRoute>
              <Supplier />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/:id/tenant"
          element={
            <ProtectedRoute>
              <Tenant />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/:id/reports"
          element={
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <MyProperties sendDataToParent={handleDataFromChild} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property/:id/bills"
          element={
            <ProtectedRoute>
              <Bills />
            </ProtectedRoute>
          }
        />
        <Route
          path="settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Login visible={isLoginVisible} setVisible={setLoginVisible} />
      <Register visible={isRegisterVisible} setVisible={setRegisterVisible} />
      {!hideHeaderAndFooter && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
};

export default App;
