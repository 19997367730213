import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css"; // Ensure you have the correct theme
import "primereact/resources/primereact.min.css"; // Core PrimeReact styles
import "primeicons/primeicons.css"; // PrimeIcons

const Settings = () => {
  const [visible, setVisible] = useState(false);
  const [bankAccount, setBankAccount] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleBankAccountChange = (e) => {
    setBankAccount(e.target.value);
  };

  const handleBusinessNumberChange = (e) => {
    setBusinessNumber(e.target.value);
  };

  const handleSubmit = () => {
    // Handle form submission logic here
    console.log("Bank Account:", bankAccount);
    console.log("Business Number:", businessNumber);
    closeModal();
  };

  return (
    <div className="m-4">
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Payments Settings
                </span>
                <div className="text-900 font-medium text-xl">
                  5999 <span>ksh/=</span>
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
                onClick={openModal}
              >
                <i className="pi pi-plus text-blue-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">24 new </span>
            <span className="text-500">since last visit</span>
          </div>
        </div>

        {/* Other cards remain unchanged */}
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Property Listings
                </span>
                <div className="text-900 font-medium text-xl">150</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-green-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-home text-green-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">10 new </span>
            <span className="text-500">since last visit</span>
          </div>
        </div>

        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Leases & Contracts
                </span>
                <div className="text-900 font-medium text-xl">75 Active</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-yellow-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-file text-yellow-500 text-xl"></i>
              </div>
            </div>
            <span className="text-orange-500 font-medium">
              5 expiring soon{" "}
            </span>
          </div>
        </div>

        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  Maintenance Requests
                </span>
                <div className="text-900 font-medium text-xl">12 Pending</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-red-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-wrench text-red-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">3 resolved </span>
            <span className="text-500">this week</span>
          </div>
        </div>
      </div>

      {/* Modal Dialog */}
      <Dialog
        header="Add Payment Method"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={closeModal}
        footer={
          <div>
            <Button label="Submit" icon="pi pi-check" onClick={handleSubmit} />
            <Button
              label="Close"
              icon="pi pi-times"
              onClick={closeModal}
              className="p-button-text"
            />
          </div>
        }
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="bankAccount">Bank Account</label>
            <InputText
              id="bankAccount"
              value={bankAccount}
              onChange={handleBankAccountChange}
            />
          </div>
          <div className="p-field">
            <label htmlFor="businessNumber">Business Number</label>
            <InputText
              id="businessNumber"
              value={businessNumber}
              onChange={handleBusinessNumberChange}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Settings;
