import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchSupplierDocumentAPI,
  createSupplierDocumentAPI,
  updateSupplierDocumentAPI,
  deleteSupplierDocumentAPI,
} from "./supplierDocumentAPI";

export const fetchSupplierDocument = createAsyncThunk(
  "supplierDocument/fetchSupplierDocument",
  async (id, { rejectWithValue }) => {
    try {
      const supplierDocument = await fetchSupplierDocumentAPI(id);
      return supplierDocument;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createSupplierDocument = createAsyncThunk(
  "supplierDocument/createSupplierDocument",
  async (supplierDocumentData, { rejectWithValue }) => {
    try {
      const supplierDocument = await createSupplierDocumentAPI(
        supplierDocumentData
      );
      return supplierDocument;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateSupplierDocument = createAsyncThunk(
  "supplierDocument/updateSupplierDocument",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedSupplierDocument = await updateSupplierDocumentAPI(
        id,
        newData
      );
      return updatedSupplierDocument;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSupplierDocument = createAsyncThunk(
  "supplierDocument/deleteSupplierDocument",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteSupplierDocumentAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  supplierDocument: null,
  loading: false,
  error: null,
};

const supplierDocumentSlice = createSlice({
  name: "supplierDocument",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplierDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.supplierDocument = action.payload;
      })
      .addCase(fetchSupplierDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createSupplierDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSupplierDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.supplierDocument = action.payload;
      })
      .addCase(createSupplierDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSupplierDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSupplierDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.supplierDocument = action.payload;
      })
      .addCase(updateSupplierDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteSupplierDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSupplierDocument.fulfilled, (state) => {
        state.loading = false;
        state.supplierDocument = null;
      })
      .addCase(deleteSupplierDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supplierDocumentSlice.reducer;
