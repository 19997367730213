import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/api/bills/`;
const UPLOAD_URL = `${BaseUrl}/api/upload-bills/`;

const fetchAllBillsAPI = async (property) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}?property=${property}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchBillAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const createBillAPI = async (billData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(BASE_URL, billData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const uploadBillsAPI = async (billData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(UPLOAD_URL, billData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const updateBillAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.put(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteBillAPI = async (ids) => {
  try {
    const token = Cookies.get("token");
    let url = `${BASE_URL}`;
    let requestBody = null;

    // Check if ids is an array (multiple deletion)
    if (Array.isArray(ids)) {
      // Send a DELETE request with the list of IDs in the request body
      requestBody = { ids: ids };
    } else {
      // Assume single ID deletion
      url += `${ids}/`;
    }

    const response = await axios({
      method: requestBody ? "DELETE" : "delete",
      url: url,
      data: requestBody,
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error and throw it for further processing
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || "Error deleting bill");
    }
    throw new Error("Network error while deleting bill");
  }
};

export {
  fetchAllBillsAPI,
  fetchBillAPI,
  createBillAPI,
  updateBillAPI,
  deleteBillAPI,
  uploadBillsAPI
};
