import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { useSelector, useDispatch } from 'react-redux';
import { editUser, fetchUser } from '../../Redux/user/userSlice';

const ROLE_CHOICES = [
  { label: 'Admin', value: 'admin' },
  { label: 'Client', value: 'client' },
  { label: 'Trial', value: 'trial' },
];

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.data);
  const id = JSON.parse(localStorage.getItem('user')).id;
  const [profile, setProfile] = useState({});

  useEffect(() => {
    dispatch(fetchUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (userProfile) {
      setProfile(userProfile);
    }
  }, [userProfile]);

  const handleInputChange = (e, field) => {
    const value = e.target ? e.target.value : e.value;
    setProfile({ ...profile, [field]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
     // Debugging line
    dispatch(editUser({ id, newData: profile }))
      .unwrap()
      .then(() => {
        console.log('Profile updated successfully');
      })
      .catch((error) => {
        console.error('Failed to update profile:', error);
      });
  };

  return (
    <div className="surface-100 flex justify-content-center align-items-center">
      <div className="p-4 w-11 md:w-9">
        <div>
          <h3 className="text-2xl text-black text-center">My Profile</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid p-5 formgrid">
            <div className="col-12 md:col-4">
              <div className="p-field">
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  placeholder={profile.email}
                  onChange={(e) => handleInputChange(e, 'email')}
                  className="w-full"
                />
              </div>
              <div className="p-field">
                <label htmlFor="first_name">First Name</label>
                <InputText
                  id="first_name"
                  placeholder={profile.first_name}
                  onChange={(e) => handleInputChange(e, 'first_name')}
                  className="w-full"
                />
              </div>
              <div className="p-field">
                <label htmlFor="last_name">Last Name</label>
                <InputText
                  id="last_name"
                  placeholder={profile.last_name}
                  onChange={(e) => handleInputChange(e, 'last_name')}
                  className="w-full"
                />
              </div>
              <div className="p-field">
                <label htmlFor="username">Username</label>
                <InputText
                  id="username"
                  placeholder={profile.username}
                  onChange={(e) => handleInputChange(e, 'username')}
                  className="w-full"
                />
              </div>
            </div>
            <div className="col-12 md:col-4">
              <div className="p-field">
                <label htmlFor="phone">Phone</label>
                <InputText
                  id="phone"
                  placeholder={profile.phone_number}
                  onChange={(e) => handleInputChange(e, 'phone_number')}
                  className="w-full"
                />
              </div>
              <div className="p-field">
                <label htmlFor="company">Company</label>
                <InputText
                  id="company"
                  placeholder={profile.company_name}
                  onChange={(e) => handleInputChange(e, 'company_name')}
                  className="w-full"
                />
              </div>
              <div className="p-field">
                <label htmlFor="balance">Balance</label>
                <InputText
                  id="balance"
                  type="number"
                  placeholder={profile.balance}
                  onChange={(e) => handleInputChange(e, 'balance')}
                  className="w-full"
                />
              </div>
              <div className="p-field">
                <label htmlFor="role">Role</label>
                <Dropdown
                  id="role"
                  value={profile.role}
                  options={ROLE_CHOICES}
                  onChange={(e) => handleInputChange(e, 'role')}
                  placeholder={profile.role}
                  className="w-full"
                />
              </div>
            </div>
            <div className="col-12 md:col-4">
              <div className="p-field">
                <label htmlFor="subscriptionStartDate">Subscription Start Date</label>
                <Calendar
                  id="subscriptionStartDate"
                  placeholder={profile.subscriptionStartDate}
                  onChange={(e) => handleInputChange(e, 'subscriptionStartDate')}
                  showIcon
                  className="w-full"
                />
              </div>
              <div className="p-field">
                <label htmlFor="subscriptionEndDate">Subscription End Date</label>
                <Calendar
                  id="subscriptionEndDate"
                  placeholder={profile.subscriptionEndDate}
                  onChange={(e) => handleInputChange(e, 'subscriptionEndDate')}
                  showIcon
                  className="w-full"
                />
              </div>
              <div className="p-field-checkbox mt-2 p-3">
                <Checkbox
                  id="is_email_valid"
                  checked={profile.is_email_valid}
                  onChange={(e) => handleInputChange(e, 'is_email_valid')}
                />
                <label htmlFor="is_email_valid" className="p-checkbox-label">
                  Email Verified
                </label>
              </div>
              <div className="flex justify-content-end mt-3">
                <Button
                  label="Update Profile"
                  icon="pi pi-check"
                  type="submit"
                  className="p-button-success"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfile;
