// authService.js
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const API_URL = `${BaseUrl}/api`;
const authService = {
  login: async (credentials) => {
    try {
      const response = await fetch(`${API_URL}/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        throw new Error("Invalid credentials");
      }

      const user = await response.json();
      const token = user.data.token;
      Cookies.set("token", token, { expires: 1 });
      return user;
    } catch (error) {
      throw new Error(error);
    }
  },

  logout: async () => {
    try {
      const token = Cookies.get("token");
      await fetch(`${API_URL}/logout/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
    } catch (error) {
      throw new Error("Failed to log out");
    }
  },

  register: async (userData) => {
    try {
      const response = await fetch(`${API_URL}/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error("Failed to register user");
      }

      return "Registration successful";
    } catch (error) {
      throw new Error("Failed to register user");
    }
  },

  changePassword: async (passwordData) => {
    try {
      const response = await fetch(`${API_URL}/change-password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passwordData),
      });

      if (!response.ok) {
        throw new Error("Failed to change password");
      }

      return "Password changed successfully";
    } catch (error) {
      throw new Error("Failed to change password");
    }
  },

  deleteUser: async (userId) => {
    try {
      const token = Cookies.get("token");
      const response = await fetch(`${API_URL}/delete-user/${userId}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete user");
      }

      return "User deleted successfully";
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default authService;
