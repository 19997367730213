import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { fetchAllProperties } from '../../Redux/Properties/PropertySlice';
import { useParams } from 'react-router-dom';

export default function ExpensesChart() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { properties, loading } = useSelector((state) => state.properties); // Get loading state from the store
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        dispatch(fetchAllProperties(id)); // Fetch properties when component mounts
    }, [dispatch, id]);

    useEffect(() => {
        if (properties.length > 0) {
            setSelectedProperty(properties[0]); // Select the first property initially
        }
    }, [properties]);

    const propertiesOptions = properties.map((property) => ({
        label: property.property_name,
        value: property,
    }));

    useEffect(() => {
        if (selectedProperty) {
            const documentStyle = getComputedStyle(document.documentElement);
            const selectedData = selectedProperty.data; // Assuming each property object has a 'data' field with chart data

            const datasets = selectedProperty.amenities.map((amenity, index) => ({
                label: amenity,
                data: selectedData.map(item => item[amenity]), // Assuming 'data' has fields corresponding to amenities
                backgroundColor: documentStyle.getPropertyValue(`--chart-color-${index + 1}`), // Example color variable
                borderColor: documentStyle.getPropertyValue(`--chart-color-${index + 1}`), // Example color variable
            }));

            const data = {
                labels: [
                    'January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'
                ],
                datasets: datasets,
            };

            const options = {
                maintainAspectRatio: false,
                aspectRatio: 2,
                plugins: {
                    legend: {
                        labels: {
                            color: documentStyle.getPropertyValue('--text-color') // Adjust as needed
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: documentStyle.getPropertyValue('--text-color-secondary'), // Adjust as needed
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    },
                    y: {
                        ticks: {
                            color: documentStyle.getPropertyValue('--text-color-secondary') // Adjust as needed
                        },
                        grid: {
                            color: documentStyle.getPropertyValue('--surface-border'), // Adjust as needed
                            drawBorder: false
                        }
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [selectedProperty]);

    const handlePropertyChange = (e) => {
        setSelectedProperty(e.value);
    };

    if (loading) {
        return <div> Loading properties...</div>; // Handle loading state
    }

    if (!loading && properties.length === 0) {
        return <div> <span className='pi pi-exclamation-triangle'></span>No properties available.</div>; // Show this message if no properties are available
    }

    return (
        <div>
            <Dropdown
                value={selectedProperty}
                options={propertiesOptions}
                onChange={handlePropertyChange}
                placeholder="Select a Property"
                className="mb-4 p-inputtext-sm"
                optionLabel="label"
                optionValue="value"
            />
            <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
    );
}
