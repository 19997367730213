import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { createBill } from "../../Redux/Bills/billSlice";

// Configure PDF.js worker for `react-pdf`
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfReader = ({ uploadedFile }) => {
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState("");

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error) => {
    setError("Failed to load PDF document. Please check the file or try again later.");
    console.error("PDF load error:", error);
  };

  const handlePreviousPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const handleNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const upload = () => {
    // Example dispatch for creating bills
    // Replace with your actual logic
    const billData = {
      // Example data, replace with actual data extraction from PDF
      description: "Bill description",
      amount: 100.0,
    };
    dispatch(createBill(billData));
  };

  return (
    <div className="p-grid p-nogutter">
      <div className="p-col-12">
        <div className="p-shadow-5 p-mb-4">
          {uploadedFile ? (
            <Document
              file={uploadedFile}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          ) : (
            <div>No PDF file selected</div>
          )}
        </div>
        <div className="p-d-flex p-jc-between">
          <div>
            {pageNumber > 1 && (
              <Button
                label="Previous Page"
                onClick={handlePreviousPage}
                className="p-button-secondary p-mr-2"
              />
            )}
            {pageNumber < numPages && (
              <Button
                label="Next Page"
                onClick={handleNextPage}
                className="p-button-secondary"
              />
            )}
          </div>
          <Button
            label="Upload"
            icon="pi pi-cloud-upload"
            onClick={upload}
            className="p-button-success"
          />
        </div>
      </div>
      <div className="p-col-12">
        {error && <div className="p-error">{error}</div>}
      </div>
    </div>
  );
};

export default PdfReader;
