import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

import Error from "./Error";
import Loading from "./Loading";
import {
  createPremise,
  fetchAllPremises,
  deletePremise,
  updatePremise,
} from "../Redux/Premises/premiseSlice";
import { fetchAllLandlords } from "../Redux/Landlords/landlordSlice";
import CsvReader from "./fileReaders/CsvReader";
import PdfReader from "./fileReaders/PdfReader";
import ExcelReader from "./fileReaders/ExelReader";

export default function Premises() {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState([]);
  const { id } = useParams();
  let emptyPremise = {
    occupied: false,
    type: "",
    house_or_door_number: "",
    landlord_id: null,
    floor_area: 0.0,
    floor_area_billable: 0.0,
    floor: 0,
    electricity_meter_number: "",
    water_meter_number: "",
    start_date: null,
    expiry_date: null,
    property: id,
    created_by: JSON.parse(localStorage.getItem("user"))?.id || null,
    landLordName: "",
  };

  const dispatch = useDispatch();
  const [premises, setpremises] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState([]);
  const [premiseInfoDialog, setPremiseInfoDialog] = useState(false);
  const [premiseDialog, setpremiseDialog] = useState(false);
  const [premiseUploadDialog, setpremiseUploadDialog] = useState(false);
  const [deletepremiseDialog, setDeletepremiseDialog] = useState(false);
  const [deletepremisesDialog, setDeletepremisesDialog] = useState(false);
  const [premise, setpremise] = useState(emptyPremise);
  const [selectedpremises, setSelectedpremises] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const types = [
    { name: "Residential", code: "FX" },
    { name: "Commercial", code: "VR" },
  ];
  const Premises = useSelector((state) => state.premise.premises);
  const loading = useSelector((state) => state.premise.loading);
  const error = useSelector((state) => state.premise.error);
  const Landlords = useSelector((state) => state.landlord.landlords);

  useEffect(() => {
    dispatch(fetchAllPremises(id));
    dispatch(fetchAllLandlords(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (Premises && Premises.length > 0) {
      setpremises(Premises);
    }
  }, [Premises]);

  const openNew = () => {
    setpremise(emptyPremise);
    setSubmitted(false);
    setpremiseDialog(true);
  };

  const openNewUpload = () => {
    setSubmitted(false);
    setpremiseUploadDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setpremiseDialog(false);
  };

  const hideDeletepremiseDialog = () => {
    setDeletepremiseDialog(false);
  };

  const hideUploadDialog = () => {
    setFile([]);
    setSubmitted(false);
    setpremiseUploadDialog(false);
  };

  const hideDeletepremisesDialog = () => {
    setDeletepremisesDialog(false);
  };

  const savepremise = () => {
    if (premise.id) {
      dispatch(updatePremise({ id: premise.id, newData: premise }))
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Premise Updated",
            life: 3000,
          });
          // Optionally refresh the premises list
          dispatch(fetchAllPremises(id));
        })
        .catch((error) => {
          // Handle error (e.g. display a message or log it)
          console.error("Error updating premise:", error);
        });
    } else {
      // For creating a new premise

      dispatch(createPremise(premise))
        .then((er) => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Premise Created",
            life: 3000,
          });
          // Optionally refresh the premises list
          dispatch(fetchAllPremises(id));
        })
        .catch((error) => {
          // Handle error (e.g. display a message or log it)
          console.error("Error creating premise:", error);
        });
    }

    // Close the premise dialog and reset the premise form
    setpremiseDialog(false);
    setpremise(emptyPremise);
  };

  const upload = async () => {
    if (!file || file.length === 0) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "No file selected",
        life: 3000,
      });
      return;
    }
  };

  const DownloadPremise = () => {};

  const hidePremiseInfoDialogue = () => {
    setPremiseInfoDialog(false);
  };

  const premiseInfo = (premiseId) => {
    const expe = Premises.find((b) => b.id === premiseId);
    setSelectedPremise(expe);
    setPremiseInfoDialog(true);
  };

  const editpremise = (premise) => {
    setpremise({ ...premise });
    setpremiseDialog(true);
  };

  const confirmDeletepremise = (premise) => {
    setpremise(premise);
    setDeletepremiseDialog(true);
  };

  const deletepremise = () => {
    dispatch(deletePremise(premise.id));
    let _premises = premises.filter((val) => val.id !== premise.id);
    setpremises(_premises);
    setDeletepremiseDialog(false);
    setpremise(emptyPremise);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "premise Deleted",
      life: 3000,
    });
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeletepremisesDialog(true);
  };

  const deleteSelectedpremises = async () => {
    for (const premise of selectedpremises) {
      try {
        dispatch(deletePremise(premise.id));
      } catch (error) {
        console.error(`Failed to delete premise with ID ${premise.id}:`, error);
      }
    }
    const remainingPremises = premises.filter(
      (premise) =>
        !selectedpremises.some(
          (selectedPremise) => selectedPremise.id === premise.id
        )
    );

    // Update the state to only include the remaining premises
    setpremises(remainingPremises);

    // Reset the selected premises and close the confirmation dialog
    setSelectedpremises(null);
    setDeletepremisesDialog(false);

    // Display a success message
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Premises Deleted",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _premise = { ...premise };

    _premise[`${name}`] = val;

    setpremise(_premise);
  };

  const onTypeChange = (e) => {
    const selectedValue = e.value.name;
    const updatedPremise = {
      ...premise,
      type: selectedValue,
    };

    setpremise(updatedPremise);
  };

  const onLandlordChange = (e) => {
    const selectedValue = e.value.id;
    const selectedName = e.value.first_name;
    const updatedPremise = {
      ...premise,
      landlord_id: selectedValue,
      landLordName: selectedName,
    };

    setpremise(updatedPremise);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New Premise"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete Premises"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedpremises || !selectedpremises.length}
        />
        <Button
          label="Add from a file"
          icon="pi pi-file-excel"
          severity="success"
          onClick={openNewUpload}
        />
      </div>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default();

        const currentData = premises; // This is the current data from the DataTable

        const tableHeaders = [
          { title: "Premise No", dataKey: "premise_number" },
          { title: "Name", dataKey: "name" },
          { title: "Type", dataKey: "type" },
          { title: "Payable", dataKey: "payable" },
          { title: "Earn Commission", dataKey: "earn_commission" },
          { title: "Measuring Units", dataKey: "measuring_unit" },
          { title: "Calculate Unit Rate", dataKey: "calculate_unit_rate" },
          { title: "Deposit", dataKey: "deposit" },
        ];

        doc.autoTable({
          startY: 20, // Adjust the starting Y position as needed
          head: [tableHeaders], // Pass the table headers
          body: currentData, // Pass the current data
        });

        // Save the PDF file
        doc.save("premises.pdf");
      });
    });
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Export csv"
          icon="pi pi-upload"
          className="p-button-help mr-2"
          onClick={exportCSV}
        />
        <Button
          label="Export pdf"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportPdf}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editpremise(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          className="mr-2"
          onClick={() => confirmDeletepremise(rowData)}
        />
        <Button
          icon="pi pi-info"
          rounded
          outlined
          style={{ color: "black" }}
          onClick={() => premiseInfo(rowData.id)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Premises</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );
  const premiseDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={savepremise} />
    </React.Fragment>
  );

  const premiseInfoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        outlined
        onClick={hidePremiseInfoDialogue}
      />{" "}
      <Button
        label="Download"
        icon="pi pi-download"
        onClick={DownloadPremise}
      />
    </React.Fragment>
  );
  const premiseDialogUploadFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideUploadDialog}
      />
    </React.Fragment>
  );
  const deletepremiseDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletepremiseDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deletepremise}
      />
    </React.Fragment>
  );
  const deletepremisesDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletepremisesDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedpremises}
      />
    </React.Fragment>
  );

  if (error) {
    return <Error err={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const landlords = Landlords;

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    const selectedFiles = e.files;

    let newFiles = [];
    Object.keys(selectedFiles).forEach((key) => {
      _totalSize += selectedFiles[key].size || 0;
      newFiles.push(selectedFiles[key]);
    });

    setTotalSize(_totalSize);
    setFile(newFiles);
  };

  const onTemplateClear = () => {
    setFile([]);
    setTotalSize(0);
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}   
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Render different components based on the file extension
    let readerComponent;
    switch (fileExtension) {
      case "csv":
        readerComponent = <CsvReader uploadedFile={file} />;
        break;
      case "xls":
      case "xlsx":
        readerComponent = <ExcelReader uploadedFile={file} />;
        break;
      case "pdf":
        readerComponent = <PdfReader uploadedFile={file} />;
        break;
      default:
        readerComponent = <div>Unsupported file format: {fileExtension}</div>;
        break;
    }

    return (
      <div className="flex align-items-center justify-content-between flex-wrap w-full">
        {readerComponent}
        <Button
          icon="pi pi-times"
          severity="danger"
          label="Remove"
          onClick={() => props.onRemove(file)}
          className="mt-5"
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop ether a pdf or csv here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-folder-plus",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={premises}
          selection={selectedpremises}
          onSelectionChange={(e) => setSelectedpremises(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} premises"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="house_or_door_number"
            header="Premise No"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>
          <Column
            field="type"
            header="Type"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="occupied"
            header="Occupied"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="landlord_name"
            header="landLord Name"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="electricity_meter_number"
            sortable
            header="Water Meter No"
          ></Column>
          <Column
            field="electricity_meter_number"
            header=" Electricity Meter No"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="floor"
            header="Floor NO"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="floor_area"
            header="Floor Area"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="floor_area_billable"
            header="FloorArea_ Billable"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={premiseInfoDialog}
        style={{ width: "39rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Premise Detail"
        modal
        className="p-fluid"
        footer={premiseInfoDialogFooter}
        onHide={hidePremiseInfoDialogue}
      >
        <div class="grid">
          <div class="col-6">
            <div>
              <p className="font-bold">
                <span>Premise Name:</span> <small>{selectedPremise.name}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Premise Type:</span> <small>{selectedPremise.type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Measuring Units:</span>{" "}
              <small>{selectedPremise.measuring_unit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <p>
                <span> Payable:</span> <small>{selectedPremise.payable}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Earn Commission:</span>{" "}
              <small>{selectedPremise.earn_commission}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Unit rate:</span> <small>{selectedPremise.unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>calculate unit rate:</span>{" "}
              <small>{selectedPremise.calculate_unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit type:</span>{" "}
              <small>{selectedPremise.deposit_type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit:</span> <small>{selectedPremise.deposit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum units:</span>{" "}
              <small>{selectedPremise.minimum_units}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum charge:</span>{" "}
              <small>{selectedPremise.minimum_charge}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Alocate surplus:</span>{" "}
              <small>{selectedPremise.allocate_surplus}</small>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={premiseDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Premise"
        modal
        className="p-fluid"
        footer={premiseDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="house_or_door_number" className="font-bold">
            house or door number
          </label>
          <InputText
            id="name"
            value={premise.house_or_door_number}
            onChange={(e) => onInputChange(e, "house_or_door_number")}
            required={true}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !premise.house_or_door_number,
            })}
          />
          {submitted && !premise.house_or_door_number && (
            <small className="p-error">house_or_door_number is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="type" className="font-bold">
            Type
          </label>
          <Dropdown
            value={premise.type} // Use the premise.type as the value of the dropdown
            onChange={(e) => onTypeChange(e)} // Pass the entire event to the handler
            options={types}
            optionLabel="name"
            placeholder={
              premise.type ? `${premise.type}` : "Select premise type"
            }
            className="w-full md:w-14rem"
          />
          {submitted && !premise.type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="floor" className="font-bold">
            floor
          </label>
          <InputText
            id="measuring_unit"
            value={premise.floor}
            onChange={(e) => onInputChange(e, "floor")}
            required
          />
          {submitted && !premise.landlord && (
            <small className="p-error">floor is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="landlord_id" className="font-bold">
            landlord
          </label>
          <Dropdown
            value={premise.landlord_id}
            onChange={(e) => onLandlordChange(e)}
            options={landlords}
            optionLabel="first_name"
            placeholder={
              premise.landLordName
                ? `${premise.landLordName}`
                : "Select a landlord"
            }
            className="w-full md:w-14rem"
            checkmark={true}
            highlightOnSelect={false}
          />
          {submitted && !premise.landlord && (
            <small className="p-error">Payable frequency is required.</small>
          )}
        </div>

        <div className="field flex">
          <label htmlFor="occupied" className="font-bold pr-5">
            occupied
          </label>
          <Checkbox
            inputId="occupied"
            checked={premise.occupied}
            onChange={(e) => setpremise({ ...premise, occupied: e.checked })}
          />
          {submitted && premise.occupied && <small className="p-error"></small>}
        </div>
        <div className="field">
          <label htmlFor="floor_area_billable" className="font-bold">
            floor area billable
          </label>
          <InputText
            id="name"
            value={premise.floor_area_billable}
            onChange={(e) => onInputChange(e, "floor_area_billable")}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !premise.floor_area_billable,
            })}
          />
          {submitted && !premise.floor_area_billable && (
            <small className="p-error">floor_area_billable is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="electricity_meter_number" className="font-bold">
            electricity meter number
          </label>
          <InputText
            id="name"
            value={premise.electricity_meter_number}
            onChange={(e) => onInputChange(e, "electricity_meter_number")}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !premise.electricity_meter_number,
            })}
          />
          {submitted && !premise.electricity_meter_number && (
            <small className="p-error">electricity_meter_number.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="water_meter_number" className="font-bold">
            water_meter_number
          </label>
          <InputText
            id="name"
            value={premise.water_meter_number}
            onChange={(e) => onInputChange(e, "water_meter_number")}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !premise.water_meter_number,
            })}
          />
          {submitted && !premise.water_meter_numberr && (
            <small className="p-error">water_meter_number is requied.</small>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deletepremiseDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deletepremiseDialogFooter}
        onHide={hideDeletepremiseDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {premise && (
            <span>
              Are you sure you want to delete <b>{premise.name}</b>?
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={premiseUploadDialog}
        style={{ width: "60rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Premise"
        modal
        className="p-fluid"
        footer={premiseDialogUploadFooter}
        onHide={hideUploadDialog}
      >
        <div>
          <Toast ref={toast}></Toast>

          <Tooltip
            target=".custom-choose-btn"
            content="Choose"
            position="bottom"
          />
          <Tooltip
            target=".custom-cancel-btn"
            content="Clear"
            position="bottom"
          />

          <FileUpload
            ref={fileUploadRef}
            name="demo[]"
            multiple
            accept=".pdf,.csv"
            maxFileSize={1000000}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            cancelOptions={cancelOptions}
            onUpload={(e) => upload(e)}
            encType="multipart/form-data"
          />
        </div>
      </Dialog>

      <Dialog
        visible={deletepremisesDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deletepremisesDialogFooter}
        onHide={hideDeletepremisesDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {premise && (
            <span>Are you sure you want to delete the selected premises?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
