import React from "react";
import ReactDOM from "react-dom/client";
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import CheckTokenValidity from "./Authentication/CheckTokenValidity";

import App from "./App";
import "../src/css/App.css";

const root = ReactDOM.createRoot(document.getElementById("WholeApp"));
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <Provider store={store}>
        <CheckTokenValidity />
        <App />
      </Provider>
    </PrimeReactProvider>
  </React.StrictMode>
);
