import React from "react";
import { Button } from "primereact/button";

const PriceCard = () => {
  return (
    <div className="surface-0">
      <div className="grid m-0">
        <div className="col-12 lg:col-3">
          <div className="p-3 h-full">
            <div
              className="shadow-2 p-3 h-full flex flex-column"
              style={{ borderRadius: "6px" }}
            >
              <div className="text-900 font-medium text-xl mb-2">Bronze</div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
              <div className="flex align-items-center">
                <span className="font-bold text-2xl text-900">ksh 4999/=</span>
                <span className="ml-2 font-medium text-600">per month</span>
              </div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
              <ul className="list-none p-0 m-0 flex-grow-1">
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>from 1 unit to 50 units</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Automated invoicing</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Automated Reports</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>free SMS</span>
                </li>
              </ul>
              <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
              <Button label="Buy Now" className="p-3 w-full mt-auto" />
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-3">
          <div className="p-3 h-full">
            <div
              className="shadow-2 p-3 h-full flex flex-column"
              style={{ borderRadius: "6px" }}
            >
              <div className="text-900 font-medium text-xl mb-2">Silver</div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
              <div className="flex align-items-center">
                <span className="font-bold text-2xl text-900">ksh 6999/=</span>
                <span className="ml-2 font-medium text-600">per month</span>
              </div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
              <ul className="list-none p-0 m-0 flex-grow-1">
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>from 51 unit to 100 units</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Automated invoicing</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Automated Reports</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>free SMS</span>
                </li>
              </ul>
              <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
              <Button label="Buy Now" className="p-3 w-full" />
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-3">
          <div className="p-3 h-full">
            <div
              className="shadow-2 p-3 h-full flex flex-column"
              style={{ borderRadius: "6px" }}
            >
              <div className="text-900 font-medium text-xl mb-2">Gold</div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
              <div className="flex align-items-center">
                <span className="font-bold text-2xl text-900">ksh 8999/=</span>
                <span className="ml-2 font-medium text-600">per month</span>
              </div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
              <ul className="list-none p-0 m-0 flex-grow-1">
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>from 101 unit to 150 units</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Automated invoicing</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Automated Reports</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>free SMS</span>
                </li>
              </ul>
              <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
              <Button label="Buy Now" className="p-3 w-full" />
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-3">
          <div className="p-3 h-full">
            <div
              className="shadow-2 p-3 flex flex-column"
              style={{ borderRadius: "6px" }}
            >
              <div className="text-900 font-medium text-xl mb-2">Platinum</div>
              <div className="text-600">We have a custom pricing for you</div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
              <div className="flex align-items-center"></div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
              <ul className="list-none p-0 m-0 flex-grow-1">
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>from 150 unit and above</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Automated invoicing</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Automated Reports</span>
                </li>
                <li className="flex align-items-center mb-3">
                  <i className="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>free SMS</span>
                </li>
              </ul>
              <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
              <Button
                label="Buy Now"
                className="p-3 w-full p-button-outlined"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
