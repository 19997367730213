import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAllBills } from "../Redux/Bills/billSlice";
import { fetchAllExpenses } from "../Redux/expense/expenseSlice";
import { fetchAllIncome } from "../Redux/Income/incomeSlice";
import { fetchAllLandlords } from "../Redux/Landlords/landlordSlice";
import { fetchAllLeases } from "../Redux/lease/leaseSlice";
import { fetchAllSupplier } from "../Redux/supplier/supplierSlice";
import { fetchAllTenant } from "../Redux/Tenant/tenantSlice";
import { fetchAllPremises } from "../Redux/Premises/premiseSlice";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from "primereact/message";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const Reports = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [selectedPremise, setSelectedPremise] = useState(null);
  const [filters, setFilters] = useState({});
  const [reportData, setReportData] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState(false);

  const Premises = useSelector((state) => state.premise.premises);
  const Landlords = useSelector((state) => state.landlord.landlords);
  const Incomes = useSelector((state) => state.income.income);
  const Expenses = useSelector((state) => state.expense.expenses);
  const Leases = useSelector((state) => state.lease.Leases);
  const Suppliers = useSelector((state) => state.supplier.suppliers);
  const Tenants = useSelector((state) => state.tenant.tenants);
  useEffect(() => {
    dispatch(fetchAllLandlords(id));
    dispatch(fetchAllIncome(id));
    dispatch(fetchAllExpenses(id));
    dispatch(fetchAllLeases(id));
    dispatch(fetchAllSupplier(id));
    dispatch(fetchAllTenant(id));
    dispatch(fetchAllPremises(id));
    dispatch(fetchAllBills(id));
  }, [dispatch, id]);

  const reportTypes = [
    { label: "Landlord Report", value: "landlord_report" },
    { label: "Income Statement (P&L)", value: "income_statement" },
    { label: "Expense Report", value: "expense_report" },
    { label: "Running Statement", value: "running_statement" },
    { label: "Summary Statement", value: "summary_statement" },
    {
      label: "Accounts Receivable and Payable",
      value: "accounts_receivable_payable",
    },
    { label: "Supplier Statement of Account", value: "supplier_statement" },
    { label: "Tenant Statement of Account", value: "tenant_statement" },
    { label: "Remittance Statement", value: "remittance_statement" },
    { label: "Closed Lease with Balances", value: "closed_lease_balances" },
    { label: "Lease Expiry Report", value: "lease_expiry_report" },
    { label: "Deposit Liability Report", value: "deposit_liability_report" },
    { label: "Profit and Loss Report", value: "profit_and_loss" },
  ];

  const applyFilters = (data) => {
    if (!filters || Object.keys(filters).length === 0) return data;

    return data.filter((item) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        return String(item[key])
          .toLowerCase()
          .includes(String(value).toLowerCase());
      });
    });
  };

  const applyDateFilter = (data) => {
    if (!startDate || !endDate) return data;

    const start = new Date(startDate);
    const end = new Date(endDate);

    return data.filter((item) => {
      const date = new Date(
        item.date || item.month || item.year || item.expiryDate
      );
      return date >= start && date <= end;
    });
  };

  const generateProfitAndLossReport = () => {
    const filteredIncomeStatements = applyDateFilter(Incomes);
    const filteredExpenses = applyDateFilter(Expenses);

    const totalIncome = filteredIncomeStatements.reduce(
      (sum, item) => sum + item.income,
      0
    );
    const totalExpenses = filteredExpenses.reduce(
      (sum, item) => sum + item.amount,
      0
    );
    const profit = totalIncome - totalExpenses;

    return [
      { label: "Total Income", value: totalIncome },
      { label: "Total Expenses", value: totalExpenses },
      { label: "Profit", value: profit },
    ];
  };

  const generateLandlordReportData = () => {
    const filteredLeases = applyDateFilter(Leases);

    const landlordReportData = Landlords.map((landlord) => {
      const leasesForLandlord = filteredLeases.filter(
        (lease) => lease.landlordId === landlord._id
      );

      const totalRentPaid = leasesForLandlord.reduce(
        (sum, lease) => sum + lease.rentPaid,
        0
      );
      const totalServiceChargePaid = leasesForLandlord.reduce(
        (sum, lease) => sum + lease.serviceChargePaid,
        0
      );
      const totalPaid = totalRentPaid + totalServiceChargePaid;

      const totalReceivable = leasesForLandlord.reduce(
        (sum, lease) => sum + lease.receivable,
        0
      );

      return {
        landlordName: landlord.name,
        totalReceivable: totalReceivable.toFixed(2),
        totalRentPaid: totalRentPaid.toFixed(2),
        totalServiceChargePaid: totalServiceChargePaid.toFixed(2),
        totalPaid: totalPaid.toFixed(2),
        arrears: (totalReceivable - totalPaid).toFixed(2),
      };
    });

    console.log("Landlord Report Data:", landlordReportData);

    return landlordReportData;
  };

  const generateReport = () => {
    let data = [];

    switch (reportType) {
      case "landlord_report":
        data = generateLandlordReportData();
        break;
      case "income_statement":
        data = Incomes;
        break;
      case "expense_report":
        data = Expenses;
        break;
      case "running_statement":
        data = Leases.map((lease, index) => ({
          ...lease,
          expense: Expenses[index]?.amount || 0,
          runningBalance: lease.income - (Expenses[index]?.amount || 0),
        }));
        break;
      case "summary_statement":
        data = [
          {
            year: new Date().getFullYear(),
            totalIncome: Incomes.reduce((sum, item) => sum + item.income, 0),
            totalExpense: Expenses.reduce((sum, item) => sum + item.amount, 0),
          },
        ];
        break;
      case "accounts_receivable_payable":
        data = [
          { type: "Receivable", amount: 3000 },
          { type: "Payable", amount: 1500 },
        ];
        break;
      case "supplier_statement":
        data = Suppliers;
        break;
      case "tenant_statement":
        data = Tenants;
        break;
      case "remittance_statement":
        data = Leases.filter((lease) => lease.status === "remittance").map(
          (lease) => ({
            date: lease.remittanceDate,
            amount: lease.remittanceAmount,
          })
        );
        break;
      case "closed_lease_balances":
        data = Leases.filter((lease) => lease.status === "closed").map(
          (lease) => ({
            tenant: lease.tenantName,
            balance: lease.balance,
          })
        );
        break;
      case "lease_expiry_report":
        data = Leases.filter((lease) => lease.status === "active").map(
          (lease) => ({
            tenant: lease.tenantName,
            expiryDate: lease.expiryDate,
          })
        );
        break;
      case "deposit_liability_report":
        data = Leases.filter((lease) => lease.status === "active").map(
          (lease) => ({
            tenant: lease.tenantName,
            deposit: lease.depositAmount,
          })
        );
        break;
      case "profit_and_loss":
        data = generateProfitAndLossReport();
        break;
      default:
        data = [];
    }
    data = applyDateFilter(data);
    data = applyFilters(data);
    setReportData(data);
    setNoDataMessage(data.length === 0);
  };

  const exportToCSV = () => {
    const csvData = reportData
      .map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`)
          .join(",")
      )
      .join("\n");
    const csvHeader = Object.keys(reportData[0] || {}).join(",");

    const blob = new Blob([csvHeader + "\n" + csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${reportType}.csv`;
    a.click();

    URL.revokeObjectURL(url);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    const tableColumn = Object.keys(reportData[0] || {});
    const tableRows = reportData.map((row) => Object.values(row));

    doc.autoTable(tableColumn, tableRows, { startY: 20 });

    doc.save(`${reportType}.pdf`);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(reportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    XLSX.writeFile(workbook, `${reportType}.xlsx`);
  };

  // Map Premises array to dropdown options
  const premiseOptions = Premises.map((premise) => ({
    label: premise.house_or_door_number,
    value: premise._id,
  }));

  return (
    <div>
      <Card title="Generate Report" className="bg-bluegray-900 text-center">
        <div className="filters flex p-5 gap-5 align-items-center w-full justify-content-center ">
          <div className="p-field">
            <label htmlFor="reportType"/>
            <Dropdown
              id="reportType"
              value={reportType}
              options={reportTypes}
              onChange={(e) => setReportType(e.value)}
              placeholder="Select a Report Type"
            />
          </div>
          <div className="p-field">
            <label htmlFor="startDate"/>
            <Calendar
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.value)}
              placeholder="Select Start Date"
              showIcon
            />
          </div>
          <div className="p-field">
            <label htmlFor="endDate"/>
            <Calendar
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.value)}
              placeholder="Select End Date"
              showIcon
            />
          </div>
          <div className="p-field">
            <label htmlFor="premise"/>
            <Dropdown
              id="premise"
              value={selectedPremise}
              options={premiseOptions}
              onChange={(e) => setSelectedPremise(e.value)}
              placeholder="Select a Premise"
            />
          </div>
          <div className="p-field">
            <Button label="Generate" onClick={generateReport} />
          </div>
        </div>
      </Card>
      <Card title="Report Data">
        {noDataMessage ? (
          <Message
            severity="info"
            text="No data available for the selected report."
          />
        ) : (
          <DataTable value={reportData}>
            {Object.keys(reportData[0] || {}).map((col) => (
              <Column key={col} field={col} header={col} />
            ))}
          </DataTable>
        )}
      </Card>
      {reportData.length > 0 && (
        <Card title="Export Options" className="flex gap-5">
          <Button
            label="Export to CSV"
            onClick={exportToCSV}
            className="p-mr-2"
          />
          <Button
            label="Export to PDF"
            onClick={exportToPDF}
            className="p-mr-2"
          />
          <Button label="Export to Excel" onClick={exportToExcel} />
        </Card>
      )}
    </div>
  );
};

export default Reports;
