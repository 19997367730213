import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

import Error from "./Error";
import Loading from "./Loading";
import {
  fetchAllLandlords,
  deleteLandlord,
  updateLandlord,
  createLandlord,
  uploadLandlord,
} from "../Redux/Landlords/landlordSlice";
import CsvReader from "./fileReaders/CsvReader";
import PdfReader from "./fileReaders/PdfReader";
import ExcelReader from "./fileReaders/ExelReader";
import { useParams } from "react-router-dom";

export default function Landlords() {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState([]);
  const { id } = useParams();
  let emptyLandlord = {
    created_by: JSON.parse(localStorage.getItem("user"))?.id || null, // Assuming "created_by" should be the user's ID from localStorage
    type: "", // Required field, must be one of the choices defined in LANDLORD_TYPES
    first_name: "", // Required field for the landlord's first name
    last_name: "", // Required field for the landlord's last name
    company: "", // Optional field for the landlord's company name
    phone: "", // Required field for the landlord's phone number
    secondary_phone: "", // Optional field for a secondary phone number
    email: "", // Required field for the landlord's email address
    secondary_email: "", // Optional field for a secondary email address
    document_type: "", // Optional field for the landlord's document type
    document_number: "", // Optional field for the landlord's document number
    occupation: "", // Optional field for the landlord's occupation
    pin: "", // Optional field for the landlord's PIN number
    address: "", // Required field for the landlord's address
    postal_code: "", // Optional field for the landlord's postal code
    property: id,
  };

  const dispatch = useDispatch();
  const [landlords, setlandlords] = useState([]);
  const [selectedLandlord, setSelectedLandlord] = useState([]);
  const [landlordInfoDialogue, setLandlordInfoDialog] = useState(false);
  const [landlordDialog, setlandlordDialog] = useState(false);
  const [landlordUploadDialog, setlandlordUploadDialog] = useState(false);
  const [deletelandlordDialog, setDeletelandlordDialog] = useState(false);
  const [deletelandlordsDialog, setDeletelandlordsDialog] = useState(false);
  const [landlord, setlandlord] = useState(emptyLandlord);
  const [selectedlandlords, setSelectedlandlords] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const DocTypes = [
    { name: "ID card" },
    { name: "Passport" },
    { name: "Visa" },
  ];

  const types = [
    { name: "Individual", code: "FX" },
    { name: "Company", code: "VR" },
  ];
  const Landlords = useSelector((state) => state.landlord.landlords);
  const loading = useSelector((state) => state.landlord.loading);
  const error = useSelector((state) => state.landlord.error);

  useEffect(() => {
    dispatch(fetchAllLandlords(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (Landlords && Landlords.length > 0) {
      setlandlords(Landlords);
    }
  }, [Landlords]);

  const openNew = () => {
    setlandlord(emptyLandlord);
    setSubmitted(false);
    setlandlordDialog(true);
  };

  const openNewUpload = () => {
    setSubmitted(false);
    setlandlordUploadDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setlandlordDialog(false);
  };

  const hideUploadDialog = () => {
    setFile([]);
    setSubmitted(false);
    setlandlordUploadDialog(false);
  };

  const hideDeletelandlordDialog = () => {
    setDeletelandlordDialog(false);
  };

  const hideDeletelandlordsDialog = () => {
    setDeletelandlordsDialog(false);
  };

  const hideLandlordInfoDialogue = () => {
    setLandlordInfoDialog(false);
  };

  const savelandlord = () => {
    // Dispatch createLandlord or updateLandlord based on whether the landlord has an ID
    if (landlord.id) {
      dispatch(updateLandlord({ id: landlord.id, newData: landlord }))
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Landlord Updated",
            life: 3000,
          });
          // Optionally refresh the landlords list
          dispatch(fetchAllLandlords(id));
        })
        .catch((error) => {
          // Handle error (e.g. display a message or log it)
          console.error("Error updating landlord:", error);
        });
    } else {
      // For creating a new landlord

      dispatch(createLandlord(landlord))
        .then((er) => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Landlord Created",
            life: 3000,
          });
          // Optionally refresh the landlords list
          dispatch(fetchAllLandlords(id));
        })
        .catch((error) => {
          // Handle error (e.g. display a message or log it)
          console.error("Error creating landlord:", error);
        });
    }

    // Close the landlord dialog and reset the landlord form
    setlandlordDialog(false);
    setlandlord(emptyLandlord);
  };
  const landlordInfo = (landlordId) => {
    const expe = Landlords.find((b) => b.id === landlordId);
    setSelectedLandlord(expe);
    setLandlordInfoDialog(true);
  };

  const editlandlord = (landlord) => {
    setlandlord({ ...landlord });
    setlandlordDialog(true);
  };

  const confirmDeletelandlord = (landlord) => {
    setlandlord(landlord);
    setDeletelandlordDialog(true);
  };

  const deletelandlord = () => {
    dispatch(deleteLandlord(landlord.id));
    let _landlords = landlords.filter((val) => val.id !== landlord.id);
    setlandlords(_landlords);
    setDeletelandlordDialog(false);
    setlandlord(emptyLandlord);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "landlord Deleted",
      life: 3000,
    });
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const downloadLandlord = () => {};

  const confirmDeleteSelected = () => {
    setDeletelandlordsDialog(true);
  };

  const deleteSelectedlandlords = async () => {
    // Iterate through the selected landlords and dispatch deleteLandlord action for each
    for (const landlord of selectedlandlords) {
      try {
        // Dispatch the deleteLandlord action for each landlord ID
        dispatch(deleteLandlord(landlord.id));
      } catch (error) {
        console.error(
          `Failed to delete landlord with ID ${landlord.id}:`,
          error
        );
        // Optionally, handle the error (e.g. show a message or log it)
      }
    }

    // After successfully deleting selected landlords, filter out the deleted landlords from the state
    const remainingLandlords = landlords.filter(
      (landlord) =>
        !selectedlandlords.some(
          (selectedLandlord) => selectedLandlord.id === landlord.id
        )
    );

    // Update the state to only include the remaining landlords
    setlandlords(remainingLandlords);

    // Reset the selected landlords and close the confirmation dialog
    setSelectedlandlords(null);
    setDeletelandlordsDialog(false);

    // Display a success message
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Landlords Deleted",
      life: 3000,
    });
  };

  const upload = async () => {
    if (!file || file.length === 0) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "No file selected",
        life: 3000,
      });
      return;
    }

    const formData = new FormData();
    file.forEach((file) => {
      formData.append("file", file);
    });
    dispatch(uploadLandlord(formData))
      .then((er) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Landlord file Uploaded",
          life: 3000,
        });
        setlandlordUploadDialog(false);
        setFile([]);
      })
      .catch((error) => {
        toast.current.show({
          severity: "success",
          summary: "failed",
          detail: error,
          life: 3000,
        });
      });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _landlord = { ...landlord };

    _landlord[`${name}`] = val;

    setlandlord(_landlord);
  };

  const onTypeChange = (e) => {
    const selectedValue = e.value.name;
    const updatedLandlord = {
      ...landlord,
      type: selectedValue,
    };

    setlandlord(updatedLandlord);
  };

  const onDocTypeChange = (e) => {
    const selectedValue = e.value.name;
    const updatedLandlord = {
      ...landlord,
      document_type: selectedValue,
    };

    setlandlord(updatedLandlord);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New Landlord"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete Landlords"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedlandlords || !selectedlandlords.length}
        />
        <Button
          label="Add from a file"
          icon="pi pi-file-excel"
          severity="success"
          onClick={openNewUpload}
        />
      </div>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default();

        // Extract the current data from the DataTable
        const currentData = landlords; // This is the current data from the DataTable

        // Define the table headers (columns)
        const tableHeaders = [
          { title: "Landlord No", dataKey: "landlord_number" },
          { title: "Name", dataKey: "name" },
          { title: "Type", dataKey: "type" },
          { title: "Payable", dataKey: "payable" },
          { title: "Earn Commission", dataKey: "earn_commission" },
          { title: "Measuring Units", dataKey: "measuring_unit" },
          { title: "Calculate Unit Rate", dataKey: "calculate_unit_rate" },
          { title: "Deposit", dataKey: "deposit" },
          // Add more columns as needed
        ];

        // Create the PDF table
        doc.autoTable({
          startY: 20, // Adjust the starting Y position as needed
          head: [tableHeaders], // Pass the table headers
          body: currentData, // Pass the current data
        });

        // Save the PDF file
        doc.save("landlords.pdf");
      });
    });
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Export csv"
          icon="pi pi-upload"
          className="p-button-help mr-2"
          onClick={exportCSV}
        />
        <Button
          label="Export pdf"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportPdf}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editlandlord(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          className="mr-2"
          onClick={() => confirmDeletelandlord(rowData)}
        />
        <Button
          icon="pi pi-info"
          rounded
          outlined
          style={{ color: "black" }}
          onClick={() => landlordInfo(rowData.id)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Landlords</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );
  const landlordDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={savelandlord} />
    </React.Fragment>
  );
  const deletelandlordDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletelandlordDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deletelandlord}
      />
    </React.Fragment>
  );
  const landlordDialogUploadFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideUploadDialog}
      />
    </React.Fragment>
  );
  const landlordInfoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        outlined
        onClick={hideLandlordInfoDialogue}
      />{" "}
      <Button
        label="Download"
        icon="pi pi-download"
        onClick={downloadLandlord}
      />
    </React.Fragment>
  );
  const deletelandlordsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletelandlordsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedlandlords}
      />
    </React.Fragment>
  );

  if (error) {
    return <Error err={error} />;
  }

  if (loading) {
    return <Loading />;
  }
  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    const selectedFiles = e.files;

    let newFiles = [];
    Object.keys(selectedFiles).forEach((key) => {
      _totalSize += selectedFiles[key].size || 0;
      newFiles.push(selectedFiles[key]);
    });

    setTotalSize(_totalSize);
    setFile(newFiles);
  };

  const onTemplateClear = () => {
    setFile([]);
    setTotalSize(0);
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Render different components based on the file extension
    let readerComponent;
    switch (fileExtension) {
      case "csv":
        readerComponent = <CsvReader uploadedFile={file} />;
        break;
      case "xls":
      case "xlsx":
        readerComponent = <ExcelReader uploadedFile={file} />;
        break;
      case "pdf":
        readerComponent = <PdfReader uploadedFile={file} />;
        break;
      default:
        readerComponent = <div>Unsupported file format: {fileExtension}</div>;
        break;
    }

    return (
      <div className="flex align-items-center justify-content-between flex-wrap w-full">
        {readerComponent}
        <Button
          icon="pi pi-times"
          severity="danger"
          label="Remove"
          onClick={() => props.onRemove(file)}
          className="mt-5"
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop ether a pdf or csv here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-folder-plus",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={landlords}
          selection={selectedlandlords}
          onSelectionChange={(e) => setSelectedlandlords(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} landlords"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="first_name"
            header="First Name"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>
          <Column
            field="last_name"
            header="Last Name"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column field="type" sortable header="Type"></Column>
          <Column
            field="company"
            header="company"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="phone"
            header="phone Number"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="email"
            header="Email"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="address"
            header="Adress"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="document_type"
            header="Documet Type"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="document_number"
            header="Document Number"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={landlordInfoDialogue}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Landlord Detail"
        modal
        className="p-fluid"
        footer={landlordInfoDialogFooter}
        onHide={hideLandlordInfoDialogue}
      >
        <div class="grid">
          <div class="col-6">
            <div>
              <p className="font-bold">
                <span>first name:</span>{" "}
                <small>{selectedLandlord.name}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Expense Type:</span> <small>{selectedLandlord.type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Measuring Units:</span>{" "}
              <small>{selectedLandlord.measuring_unit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <p>
                <span> Payable:</span> <small>{selectedLandlord.payable}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Earn Commission:</span>{" "}
              <small>{selectedLandlord.earn_commission}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Unit rate:</span>{" "}
              <small>{selectedLandlord.unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>calculate unit rate:</span>{" "}
              <small>{selectedLandlord.calculate_unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit type:</span>{" "}
              <small>{selectedLandlord.deposit_type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Deposit:</span> <small>{selectedLandlord.deposit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum units:</span>{" "}
              <small>{selectedLandlord.minimum_units}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Minimum charge:</span>{" "}
              <small>{selectedLandlord.minimum_charge}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <span>Alocate surplus:</span>{" "}
              <small>{selectedLandlord.allocate_surplus}</small>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={landlordDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Landlord"
        modal
        className="p-fluid"
        footer={landlordDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="first_name" className="font-bold">
            First Name
          </label>
          <InputText
            id="first_name"
            value={landlord.first_name}
            onChange={(e) => onInputChange(e, "first_name")}
            required={true}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !landlord.first_name,
            })}
          />
          {submitted && !landlord.first_name && (
            <small className="p-error">first_name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="last_name" className="font-bold">
            Last Name
          </label>
          <InputText
            id="first_name"
            value={landlord.last_name}
            onChange={(e) => onInputChange(e, "last_name")}
            required={true}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !landlord.last_name,
            })}
          />
          {submitted && !landlord.last_name && (
            <small className="p-error">last_name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="company" className="font-bold">
            Company Name
          </label>
          <InputText
            id="first_name"
            value={landlord.company}
            onChange={(e) => onInputChange(e, "company")}
            required={true}
            autoFocus
            className={classNames({
              "p-invalid": submitted && !landlord.company,
            })}
          />
          {submitted && !landlord.company && (
            <small className="p-error">last_name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="type" className="font-bold">
            Type
          </label>
          <Dropdown
            value={landlord.type} // Use the landlord.type as the value of the dropdown
            onChange={(e) => onTypeChange(e)} // Pass the entire event to the handler
            options={types}
            optionLabel="name"
            placeholder={
              landlord.type ? `${landlord.type}` : "Select landlord type"
            }
            className="w-full md:w-14rem"
          />
          {submitted && !landlord.type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="phone" className="font-bold">
            phone Number
          </label>
          <InputText
            id="measuring_unit"
            value={landlord.phone}
            onChange={(e) => onInputChange(e, "phone")}
            required
          />
          {submitted && !landlord.phone && (
            <small className="p-error">phone is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="secondary_phone" className="secondary_phone">
            secondary phone
          </label>
          <InputText
            id="secondary_phone"
            value={landlord.secondary_phone}
            onChange={(e) => onInputChange(e, "secondary_phone")}
            required
          />
          {submitted && !landlord.secondary_phone && (
            <small className="p-error">secondary_phone is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="email" className="email">
            Email
          </label>
          <InputText
            id="email"
            value={landlord.email}
            onChange={(e) => onInputChange(e, "email")}
            required
          />
          {submitted && !landlord.email && (
            <small className="p-error">email is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="address" className="email">
            address
          </label>
          <InputText
            id="address"
            value={landlord.address}
            onChange={(e) => onInputChange(e, "address")}
            required
          />
          {submitted && !landlord.address && (
            <small className="p-error">address is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="document_type" className="font-bold">
            Document Type
          </label>
          <Dropdown
            value={landlord.document_type} // Use the landlord.type as the value of the dropdown
            onChange={(e) => onDocTypeChange(e)} // Pass the entire event to the handler
            options={DocTypes}
            optionLabel="name"
            placeholder={
              landlord.type ? `${landlord.document_type}` : "Document Type"
            }
            className="w-full md:w-14rem"
          />
          {submitted && !landlord.document_type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="document_number" className="bold">
            Document Number
          </label>
          <InputText
            id="document_number"
            value={landlord.document_number}
            onChange={(e) => onInputChange(e, "document_number")}
            required
          />
          {submitted && !landlord.document_number && (
            <small className="p-error">document number is required.</small>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deletelandlordDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deletelandlordDialogFooter}
        onHide={hideDeletelandlordDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {landlord && (
            <span>
              Are you sure you want to delete <b>{landlord.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={landlordUploadDialog}
        style={{ width: "60rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Landlord"
        modal
        className="p-fluid"
        footer={landlordDialogUploadFooter}
        onHide={hideUploadDialog}
      >
        <div>
          <Toast ref={toast}></Toast>

          <Tooltip
            target=".custom-choose-btn"
            content="Choose"
            position="bottom"
          />
          <Tooltip
            target=".custom-cancel-btn"
            content="Clear"
            position="bottom"
          />

          <FileUpload
            ref={fileUploadRef}
            name="demo[]"
            multiple
            accept=".pdf,.csv"
            maxFileSize={1000000}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            cancelOptions={cancelOptions}
            onUpload={(e) => upload(e)}
            encType="multipart/form-data"
          />
        </div>
      </Dialog>

      <Dialog
        visible={deletelandlordsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deletelandlordsDialogFooter}
        onHide={hideDeletelandlordsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {landlord && (
            <span>Are you sure you want to delete the selected landlords?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
