import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

const PasswordResetConfirm = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (token) {
      setToken(token);
    } else {
      setMessage('Invalid or missing token.');
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      setMessage('Invalid or missing token.');
      return;
    }
    try {
      const response = await fetch(`https://backend.primdigitalsolutions.com/api/password_reset/confirm/?token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: password,
          token: token,
        }),
      });
  
      if (response.ok) {
        setMessage('Password reset successfully.');
      } else {
        const errorData = await response.json();
        setMessage(`Failed to reset password: ${errorData.detail}`);
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    }
  };
  
  return (
    <div className="flex align-items-center justify-content-center h-19rem">
      <div className="card flex justify-content-center align-items-center">
        <form onSubmit={handleSubmit}>
          <h2>Reset Password</h2>
          {message && <Message severity="error" text={message}/>}
          <InputText
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter new password"
            required
          />
          <Button className='mt-3' type="submit" label="Reset Password" />
        </form>
      </div>
    </div>
  );
};

export default PasswordResetConfirm;
