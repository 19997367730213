import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/api/premises/`;
const UPLOAD_URL = "https://backend.primdigitalsolutions.com/api/upload-premises/";



const fetchAllPremisesAPI = async (property) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}?property=${property}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data; // Return the response data, which should contain a list of premises
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error if there is an issue with the request
  }
};

const fetchPremiseAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const createPremiseAPI = async (premiseData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(BASE_URL, premiseData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


const uploadPremiseAPI = async (premiseData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(UPLOAD_URL, premiseData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const updatePremiseAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.put(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deletePremiseAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export {
  fetchPremiseAPI,
  createPremiseAPI,
  updatePremiseAPI,
  deletePremiseAPI,
  fetchAllPremisesAPI,
  uploadPremiseAPI,
};
