import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/supplier-documents/`;

const fetchSupplierDocumentAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const createSupplierDocumentAPI = async (supplierDocumentData) => {
  try {
    const token = Cookies.get("token");
    const formData = new FormData();
    formData.append("image", supplierDocumentData.image);
    // Add other fields if needed
    const response = await axios.post(BASE_URL, formData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const updateSupplierDocumentAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    const formData = new FormData();
    formData.append("image", newData.image);
    // Add other fields if needed
    const response = await axios.put(`${BASE_URL}${id}/`, formData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteSupplierDocumentAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export {
  fetchSupplierDocumentAPI,
  createSupplierDocumentAPI,
  updateSupplierDocumentAPI,
  deleteSupplierDocumentAPI,
};
