import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/tenant-documents/`;

const fetchTenantDocumentAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const createTenantDocumentAPI = async (tenantDocumentData) => {
  try {
    const token = Cookies.get("token");
    const formData = new FormData();
    formData.append("image", tenantDocumentData.image);
    formData.append("otherData", tenantDocumentData.otherData); // Include other data if needed

    const response = await axios.post(BASE_URL, formData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const updateTenantDocumentAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    const formData = new FormData();
    formData.append("image", newData.image);
    formData.append("otherData", newData.otherData); // Include other data if needed

    const response = await axios.put(`${BASE_URL}${id}/`, formData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteTenantDocumentAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export {
  fetchTenantDocumentAPI,
  createTenantDocumentAPI,
  updateTenantDocumentAPI,
  deleteTenantDocumentAPI,
};
