import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/api/tenants/`;
const UPLOAD_URL = "https://backend.primdigitalsolutions.com/api/upload-tenants/";

const fetchAllTenantsAPI = async (property) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}?property=${property}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data; // Return the response data, which should contain a list of premises
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error if there is an issue with the request
  }
};

const createTenantAPI = async (tenantData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(BASE_URL, tenantData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const fetchTenantAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const uploadTenantAPI = async (tenantData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(UPLOAD_URL, tenantData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const updateTenantAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.put(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteTenantAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export {
  fetchTenantAPI,
  fetchAllTenantsAPI,
  createTenantAPI,
  updateTenantAPI,
  deleteTenantAPI,
  uploadTenantAPI
};
