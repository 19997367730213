import React from "react";
import Back from "./Back";
import Heading from "./Heading";
import img from "../Images/about.jpg";
import "../css/about.css";

const About = () => {
  return (
    <>
      <section className="about">
        <Back name="About Us" title="About Us - Who We Are?" cover={img} />
        <div className="cont container flex flex1 mtop">
          <div className="left row">
            <Heading
              title="Our Agency Story"
              subtitle="Check out our company story and work process"
            />

            <p className="about-p">
              We offer a comprehensive solution to these challenges. With
              features including automated invoicing, expense recording, tenant
              data management, and seamless rent collection via M-Pesa
              integration, our tool simplifies rental property management like
              never before. Additionally, our tool generates profit and loss
              statements to provide a clear picture of income and liabilities.
            </p>
            <button className="btn2 defbtn">More About Us</button>
          </div>
          <div className="right row">
            <img className="image" src="./immio.jpg" alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
