import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAllSuppliersAPI,
  fetchSupplierAPI,
  createSupplierAPI,
  updateSupplierAPI,
  deleteSupplierAPI,
  uploadSupplierAPI
} from "./supplierAPI";


export const fetchAllSupplier = createAsyncThunk(
  "supplier/fetchAllSuppliers",
  async (id, { rejectWithValue }) => {
    try {
      const premises = await fetchAllSuppliersAPI(id); 
      return premises;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchSupplier = createAsyncThunk(
  "supplier/fetchSupplier",
  async (id, { rejectWithValue }) => {
    try {
      const supplier = await fetchSupplierAPI(id);
      return supplier;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createSupplier = createAsyncThunk(
  "supplier/createSupplier",
  async (supplierData, { rejectWithValue }) => {
    try {
      const supplier = await createSupplierAPI(supplierData);
      return supplier;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const uploadSupplier = createAsyncThunk(
  "supplier/uploadSupplier",
  async (supplierData, { rejectWithValue }) => {
    try {
      const supplier = await uploadSupplierAPI(supplierData);
      return supplier;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const updateSupplier = createAsyncThunk(
  "supplier/updateSupplier",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedSupplier = await updateSupplierAPI(id, newData);
      return updatedSupplier;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSupplier = createAsyncThunk(
  "supplier/deleteSupplier",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteSupplierAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  Suppliers:[],
  supplier: null,
  loading: false,
  error: null,
};

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchAllSupplier.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchAllSupplier.fulfilled, (state, action) => {
      state.loading = false;
      state.Suppliers = action.payload;
    })
    .addCase(fetchAllSupplier.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
      .addCase(fetchSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.supplier = action.payload;
      })
      .addCase(fetchSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.supplier = action.payload;
      })
      .addCase(createSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.supplier = action.payload;
      })
      .addCase(updateSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSupplier.fulfilled, (state) => {
        state.loading = false;
        state.supplier = null;
      })
      .addCase(deleteSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supplierSlice.reducer;
