import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom"; // Import useParams if you're using React Router
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import Papa from "papaparse";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { createBill } from "../../Redux/Bills/billSlice";

const allowedExtensions = ["csv"];

const CsvReader = ({ uploadedFile }) => {
  const dispatch = useDispatch();
  const { id } = useParams(); // Fetch the id from URL params

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState("");
  const [editingColumn, setEditingColumn] = useState(null); // State for tracking the column being edited
  const [newHeaderValue, setNewHeaderValue] = useState(""); // State for tracking the new header value

  useEffect(() => {
    if (uploadedFile) {
      handleParse(uploadedFile);
    }
  }, [uploadedFile]);

  const handleParse = (file) => {
    const fileExtension = file?.type.split("/")[1];
    if (!allowedExtensions.includes(fileExtension)) {
      setError("Please input a csv file");
      return;
    }

    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;

      if (parsedData.length > 0) {
        const headers = Object.keys(parsedData[0]);
        const cols = headers.map((header) => ({
          field: header,
          header: header.charAt(0).toUpperCase() + header.slice(1),
        }));
        setColumns(cols);
        // Add default values for created_by and property
        const modifiedData = parsedData.map((item) => ({
          ...item,
          created_by: JSON.parse(localStorage.getItem("user"))?.id || null,
          property: id,
        }));
        setData(modifiedData);
      } else {
        setError("No data found in the CSV file");
      }
    };
    reader.readAsText(file);
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case "quantity":
      case "price":
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }

    // Update the state with the modified data
    const updatedData = [...data];
    const rowIndex = data.findIndex((item) => item === rowData);
    if (rowIndex !== -1) {
      updatedData[rowIndex] = { ...rowData };
      setData(updatedData);
    }
  };

  const onHeaderEditComplete = (field) => {
    const updatedColumns = columns.map((col) => {
      if (col.field === field) {
        const formattedHeader = newHeaderValue.toLowerCase().replace(/\s+/g, "_");
        return { ...col, header: formattedHeader };
      }
      return col;
    });
    setColumns(updatedColumns);
    setEditingColumn(null); // Stop editing mode for the column
  };

  const cellEditor = (options) => {
    if (options.field === "price") return priceEditor(options);
    else return textEditor(options);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
      />
    );
  };

  const priceEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="currency"
        currency="USD"
        locale="en-US"
        onKeyDown={(e) => e.stopPropagation()}
      />
    );
  };

  const priceBodyTemplate = (rowData) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(rowData.price);
  };

  const upload = () => {
    data.forEach((bill) => {
      dispatch(createBill(bill));
    });
  };

  return (
    <div className="w-full">
      <Card
        title="Please edit the field if needed"
        subTitle="just click on the field you want to edit
        required fields are"
        className="text-center p-mt-5 p-shadow-5"
      >
        <div style={{ marginTop: "2rem" }}>
          {error ? (
            <Message severity="error" text={error} />
          ) : (
            <div className="flex w-full flex-column card p-fluid">
              <DataTable
                value={data}
                editMode="cell"
                tableStyle={{ minWidth: "20rem" }}
              >
                {columns.map(({ field, header }) => (
                  <Column
                    key={field}
                    field={field}
                    header={
                      editingColumn === field ? (
                        <InputText
                          value={newHeaderValue}
                          onChange={(e) => setNewHeaderValue(e.target.value)}
                          onBlur={() => onHeaderEditComplete(field)}
                          autoFocus
                        />
                      ) : (
                        <span onClick={() => {
                          setEditingColumn(field);
                          setNewHeaderValue(header);
                        }}>
                          {header}
                        </span>
                      )
                    }
                    style={{ width: "25%" }}
                    body={field === "price" ? priceBodyTemplate : undefined}
                    editor={(options) => cellEditor(options)}
                    onCellEditComplete={onCellEditComplete}
                  />
                ))}
              </DataTable>
              <Button
                label="upload"
                icon="pi pi-cloud-upload"
                onClick={upload}
                className="mt-4 flex-initial w-10rem"
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default CsvReader;
