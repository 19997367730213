import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
  FaInstagram,
  FaDribbble,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-bluegray-900 p-5 ">
      <div className="grid">
        <div className="col-12 md:col-3 mb-4">
          <h5 className="text-white font-bold text-xl mb-3">About Us</h5>
          <p className="text-white">
            We create innovative, high-quality software solutions that drive
            business success. Partner with us for cutting-edge technology and
            unparalleled service.
          </p>
        </div>

        <div className="col-12 md:col-3 mb-4">
          <h5 className="text-white font-bold text-xl mb-3">
            Contact Information
          </h5>
          <p className="text-white mb-2">Email: info@primedigitalsolutionz.com</p>
          <p className="text-white mb-2">Phone: +254742474979</p>
          <p className="text-white">Address: 1234 Street Name, City, Country</p>
        </div>

        <div className="col-12 md:col-3 mb-4">
          <h5 className="text-white font-bold text-xl mb-3">Follow Us</h5>
          <div className="flex">
            <FaInstagram size={24} className="mr-2" />
            <FaDribbble size={24} className="mr-2" />
            <FaXTwitter size={24} className="mr-2" />
            <FaYoutube size={24} className="mr-2" />
          </div>
        </div>

        <div className="col-12 md:col-3 mb-4">
          <h5 className="text-white font-bold text-xl mb-3">
            Subscribe to our Newsletter
          </h5>
          <div className="p-inputgroup">
            <InputText placeholder="Your email" />
            <Button label="Subscribe" />
          </div>
        </div>
      </div>

      <div className="text-center mt-4 text-white">
        © {currentYear} Prime Digital Solutions. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
