import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import { Message } from "primereact/message";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://backend.primdigitalsolutions.com/api/password_reset/",
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      const errs = error.response.data.email;
      if (errs) {
        errs.map((err) => setMessage(err));
      }
    }
  };

  return (
    <div className="flex align-items-center justify-content-center h-19rem">
      <div className="card flex justify-content-center align-items-start flex-column">
        <form onSubmit={handleSubmit}>
          <InputText
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="mb-3 p-3"
          />
          <Button type="submit" label="Send Reset Email" />
        </form>
        {message && <Message className="m-4" severity="warn" text={message} />}
      </div>
    </div>
  );
};

export default PasswordReset;
