import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPremiseAPI,
  createPremiseAPI,
  updatePremiseAPI,
  deletePremiseAPI,
  fetchAllPremisesAPI,
  uploadPremiseAPI
} from "./premiseAPI";


export const fetchAllPremises = createAsyncThunk(
  "premise/fetchAllPremises",
  async (id, { rejectWithValue }) => {
    try {
      const premises = await fetchAllPremisesAPI(id); 
      return premises;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPremise = createAsyncThunk(
  "premise/fetchPremise",
  async (id, { rejectWithValue }) => {
    try {
      const premise = await fetchPremiseAPI(id);
      return premise;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createPremise = createAsyncThunk(
  "premise/createPremise",
  async (premiseData, { rejectWithValue }) => {
    try {
      const premise = await createPremiseAPI(premiseData);
      return premise;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadPremise = createAsyncThunk(
  "premise/uploadPremise",
  async (premiseData, { rejectWithValue }) => {
    try {
      const premise = await uploadPremiseAPI(premiseData);
      return premise;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatePremise = createAsyncThunk(
  "premise/updatePremise",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedPremise = await updatePremiseAPI(id, newData);
      return updatedPremise;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deletePremise = createAsyncThunk(
  "premise/deletePremise",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deletePremiseAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  premises: [],
  premise: null,
  loading: false,
  error: null,
};

const premiseSlice = createSlice({
  name: "premise",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPremises.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllPremises.fulfilled, (state, action) => {
        state.loading = false;
        state.premises = action.payload;
      })
      .addCase(fetchAllPremises.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPremise.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPremise.fulfilled, (state, action) => {
        state.loading = false;
        state.premise = action.payload;
      })
      .addCase(fetchPremise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPremise.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPremise.fulfilled, (state, action) => {
        state.loading = false;
        state.premise = action.payload;
      })
      .addCase(createPremise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updatePremise.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePremise.fulfilled, (state, action) => {
        state.loading = false;
        state.premise = action.payload;
      })
      .addCase(updatePremise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePremise.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePremise.fulfilled, (state) => {
        state.loading = false;
        state.premise = null;
      })
      .addCase(deletePremise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default premiseSlice.reducer;
