import React from "react"
import Back from "./Back"
import PriceCard from "./PriceCard"
import img from "../Images/pricing.jpg"
import "../css/price.css"

const Pricing = () => {
  return (
    <>
      <section className='pricing mb'>
        <Back name='Best pricing in the market' title='From as Low as 4999/=.' cover={img} />
        <div className='price container'>
          <PriceCard />
        </div>
      </section>
    </>
  )
}

export default Pricing
