// this bill is related to the landlord

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { FileUpload } from "primereact/fileupload";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

import Error from "../Error";
import Loading from "../Loading";

import {
  fetchAllBills,
  createBill,
  updateBill,
  deleteBill,
} from "../../Redux/Bills/billSlice";
import { useParams } from "react-router-dom";
import CsvReader from "../fileReaders/CsvReader";
import PdfReader from "../fileReaders/PdfReader";
import ExcelReader from "../fileReaders/ExelReader";

export default function BillTable() {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState([]);

  const { id } = useParams();
  let emptyBill = {
    property: id,
    name: "", // Required field for the bill name
    type: "", // Required field, must be one of the choices defined in BILL_TYPES
    measuring_unit: "", // Required field for measuring unit
    payable: "", // Required field, must be one of the choices defined in PAYABLE_FREQUENCY
    earn_commission: false, // Required field, default is False
    unit_rate: 0, // Required field for unit rate, default amount or variable bill rate
    calculate_unit_rate: false, // Required field, default is False
    show_readings_in_invoice: false, // Required field, default is False
    apportion_amount_based_on_from_date: false, // Required field, default is False
    deposit_type: "", // Required field for deposit type either maji ama stima ama....
    deposit: 0, // Required field for deposit  amount of money deposited
    minimum_units: 0, // Required field for minimum units
    minimum_charge: 0, // Required field for minimum charge
    allocate_surplus: false, // Required field, default is False
    custom_invoice_description: "", // Optional field for custom invoice description
    created_by: JSON.parse(localStorage.getItem("user"))?.id || null,
  };

  const dispatch = useDispatch();
  const [bills, setbills] = useState([]);
  const [billDialog, setbillDialog] = useState(false);
  const [billInfoDialog, setBillInfoDialog] = useState(false);
  const [selectedBill, setSelectedBill] = useState({});
  const [billUploadDialog, setbillUploadDialog] = useState(false);
  const [deletebillDialog, setDeletebillDialog] = useState(false);
  const [deletebillsDialog, setDeletebillsDialog] = useState(false);
  const [bill, setbill] = useState(emptyBill);
  const [selectedbills, setSelectedbills] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const payables = [
    { name: "Weekly", code: "W" },
    { name: "Monthly", code: "M" },
    { name: "Quarterly", code: "Q" },
    { name: "Half-Yearly", code: "H" },
    { name: "Yearly", code: "Y" },
  ];
  const types = [
    { name: "Fixed", code: "FX" },
    { name: "Variable", code: "VR" },
  ];
  const Bills = useSelector((state) => state.bills.bills);
  const loading = useSelector((state) => state.bills.loading);
  const error = useSelector((state) => state.bills.error);
  useEffect(() => {
    dispatch(fetchAllBills(id)); // Pass the property id to fetchAllBills
  }, [dispatch, id]);

  useEffect(() => {
    if (Bills && Bills.length > 0) {
      setbills(Bills);
    }
  }, [Bills]);

  const openNew = () => {
    setbill(emptyBill);
    setSubmitted(false);
    setbillDialog(true);
  };

  const openNewUpload = () => {
    setSubmitted(false);
    setbillUploadDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setbillDialog(false);
  };

  const hideBillInfoDialog = () => {
    setBillInfoDialog(false);
  };

  const hideUploadDialog = () => {
    setFile([]);
    setSubmitted(false);
    setbillUploadDialog(false);
  };

  const hideDeletebillDialog = () => {
    setDeletebillDialog(false);
  };

  const hideDeletebillsDialog = () => {
    setDeletebillsDialog(false);
  };

  const savebill = () => {
    const requiredFields = ["name", "type", "measuring_unit", "payable"];

    const missingFields = requiredFields.filter((field) => !bill[field]);

    if (missingFields.length > 0) {
      toast.current.show({
        severity: "error",
        summary: "Validation Failed",
        detail: `Please fill in all required fields: ${missingFields.join(
          ", "
        )}`,
        life: 3000,
      });
      return;
    }

    // Dispatch createBill or updateBill based on whether the bill has an ID
    if (bill.id) {
      dispatch(updateBill({ id: bill.id, newData: bill }))
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Bill Updated",
            life: 3000,
          });
          // Optionally refresh the bills list
          dispatch(fetchAllBills(id));
        })
        .catch((error) => {
          // Handle error (e.g. display a message or log it)
          console.error("Error updating bill:", error);
          toast.current.show({
            severity: "error",
            summary: "Update Failed",
            detail: "There was an error updating the bill.",
            life: 3000,
          });
        });
    } else {
      // For creating a new bill
      dispatch(createBill(bill))
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Bill Created",
            life: 3000,
          });
          // Optionally refresh the bills list
          dispatch(fetchAllBills(id));
        })
        .catch((error) => {
          // Handle error (e.g. display a message or log it)
          console.error("Error creating bill:", error);
          toast.current.show({
            severity: "error",
            summary: "Creation Failed",
            detail: "There was an error creating the bill.",
            life: 3000,
          });
        });
    }

    // Close the bill dialog and reset the bill form if validation passes
    setbillDialog(false);
    setbill(emptyBill);
  };

  const upload = async () => {
    if (!file || file.length === 0) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "No file selected",
        life: 3000,
      });
      return;
    }
  };

  const editbill = (bill) => {
    setbill({ ...bill });
    setbillDialog(true);
  };

  const confirmDeletebill = (bill) => {
    setbill(bill);
    setDeletebillDialog(true);
  };
  const billInfo = (billId) => {
    const bill = bills.find((b) => b.id === billId);
    setSelectedBill(bill);
    setBillInfoDialog(true);
  };

  const deletebill = () => {
    dispatch(deleteBill(bill.id));
    let _bills = bills.filter((val) => val.id !== bill.id);
    setbills(_bills);
    setDeletebillDialog(false);
    setbill(emptyBill);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "bill Deleted",
      life: 3000,
    });
  };
  const confirmDeleteSelected = () => {
    setDeletebillsDialog(true);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const deleteSelectedbills = async () => {
    // Iterate through the selected bills and dispatch deleteBill action for each
    for (const bill of selectedbills) {
      try {
        // Dispatch the deleteBill action for each bill ID
        dispatch(deleteBill(bill.id));
      } catch (error) {
        console.error(`Failed to delete bill with ID ${bill.id}:`, error);
        // Optionally, handle the error (e.g. show a message or log it)
      }
    }

    // After successfully deleting selected bills, filter out the deleted bills from the state
    const remainingBills = bills.filter(
      (bill) =>
        !selectedbills.some((selectedBill) => selectedBill.id === bill.id)
    );

    // Update the state to only include the remaining bills
    setbills(remainingBills);

    // Reset the selected bills and close the confirmation dialog
    setSelectedbills(null);
    setDeletebillsDialog(false);

    // Display a success message
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Bills Deleted",
      life: 3000,
    });
  };

  const onRateChange = (e) => {
    let _bill = { ...bill, unit_rate: +e.value };
    setbill(_bill);
  };

  const onDepositeChange = (e) => {
    let _bill = { ...bill, deposit: +e.value };
    setbill(_bill);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _bill = { ...bill };

    _bill[`${name}`] = val;

    setbill(_bill);
  };

  const onTypeChange = (e) => {
    const selectedValue = e.value.name;
    const updatedBill = {
      ...bill,
      type: selectedValue,
    };

    setbill(updatedBill);
  };

  const onPayableChange = (e) => {
    const selectedValue = e.value.name;
    const updatedBill = {
      ...bill,
      payable: selectedValue,
    };

    setbill(updatedBill);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New Bill"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete Bills"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedbills || !selectedbills.length}
        />
        <Button
          label="Add from a file"
          icon="pi pi-file-excel"
          severity="success"
          onClick={openNewUpload}
        />
      </div>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default();

        // Extract the current data from the DataTable
        const currentData = bills; // This is the current data from the DataTable

        // Define the table headers (columns)
        const tableHeaders = [
          { title: "Bill No", dataKey: "bill_number" },
          { title: "Name", dataKey: "name" },
          { title: "Type", dataKey: "type" },
          { title: "Payable", dataKey: "payable" },
          { title: "Earn Commission", dataKey: "earn_commission" },
          { title: "Measuring Units", dataKey: "measuring_unit" },
          { title: "Calculate Unit Rate", dataKey: "calculate_unit_rate" },
          { title: "Deposit", dataKey: "deposit" },
          // Add more columns as needed
        ];

        // Create the PDF table
        doc.autoTable({
          startY: 20, // Adjust the starting Y position as needed
          head: [tableHeaders], // Pass the table headers
          body: currentData, // Pass the current data
        });

        // Save the PDF file
        doc.save("bills.pdf");
      });
    });
  };

  const DownloadBill = () => {

  }

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Export csv"
          icon="pi pi-upload"
          className="p-button-help mr-2"
          onClick={exportCSV}
        />
        <Button
          label="Export pdf"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportPdf}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editbill(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          className="mr-2"
          onClick={() => confirmDeletebill(rowData)}
        />
        <Button
          icon="pi pi-info"
          rounded
          outlined
          style={{ color: "black" }}
          onClick={() => billInfo(rowData.id)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Bills</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );
  const billDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={savebill} />
    </React.Fragment>
  );

  const billInfoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        outlined
        onClick={hideBillInfoDialog}
      /> <Button label="Download" icon="pi pi-download" onClick={DownloadBill} />
     
    </React.Fragment>
  );

  const billDialogUploadFooter = (
    <React.Fragment>
      <Button
        label="cancel"
        icon="pi pi-times"
        outlined
        onClick={hideUploadDialog}
        severity="danger"
      />
    </React.Fragment>
  );

  const deletebillDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletebillDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deletebill}
      />
    </React.Fragment>
  );
  const deletebillsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletebillsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedbills}
      />
    </React.Fragment>
  );

  if (error) {
    return <Error err={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    const selectedFiles = e.files;

    let newFiles = [];
    Object.keys(selectedFiles).forEach((key) => {
      _totalSize += selectedFiles[key].size || 0;
      newFiles.push(selectedFiles[key]);
    });

    setTotalSize(_totalSize);
    setFile(newFiles);
  };

  const onTemplateClear = () => {
    setFile([]);
    setTotalSize(0);
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Render different components based on the file extension
    let readerComponent;
    switch (fileExtension) {
      case "csv":
        readerComponent = <CsvReader uploadedFile={file} />;
        break;
      case "xls":
      case "xlsx":
        readerComponent = <ExcelReader uploadedFile={file} />;
        break;
      case "pdf":
        readerComponent = <PdfReader uploadedFile={file} />;
        break;
      default:
        readerComponent = <div>Unsupported file format: {fileExtension}</div>;
        break;
    }

    return (
      <div className="flex align-items-center justify-content-between flex-wrap w-full">
        {readerComponent}
        <Button
          icon="pi pi-times"
          severity="danger"
          label="Remove"
          onClick={() => props.onRemove(file)}
          className="mt-5"
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop ether a pdf or csv here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-folder-plus",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={bills}
          selection={selectedbills}
          onSelectionChange={(e) => setSelectedbills(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} bills"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="bill_number"
            header="Bill No"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>
          <Column
            field="name"
            header="Name"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column field="type" sortable header="Type"></Column>
          <Column
            field="payable"
            header="Payable"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>

          <Column
            field="measuring_unit"
            header="Measuring Units"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="calculate_unit_rate"
            header="calculate unit rate"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>

          <Column
            field="deposit"
            header="deposit"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={billInfoDialog}
        style={{ width: "39rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Bill Detail"
        modal
        className="p-fluid"
        footer={billInfoDialogFooter}
        onHide={hideBillInfoDialog}
      >
        <div class="grid">
          <div class="col-6">
            <div>
              <p className="font-bold">
                <span>Bill Name:</span> <small>{selectedBill.name}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>Bill Type:</span> <small>{selectedBill.type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>Measuring Units:</span> <small>{selectedBill.measuring_unit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
              <p>
              <span>  Payable:</span> <small>{selectedBill.payable}</small>
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>Earn Commission:</span> <small>{selectedBill.earn_commission}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>Unit rate:</span> <small>{selectedBill.unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>calculate unit rate:</span> <small>{selectedBill.calculate_unit_rate}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>Deposit type:</span> <small>{selectedBill.deposit_type}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>Deposit:</span> <small>{selectedBill.deposit}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>Minimum units:</span> <small>{selectedBill.minimum_units}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>Minimum charge:</span> <small>{selectedBill.minimum_charge}</small>
            </div>
          </div>
          <div class="col-6">
            <div class=" font-bold">
            <span>Alocate surplus:</span> <small>{selectedBill.allocate_surplus}</small>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={billDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Bill"
        modal
        className="p-fluid"
        footer={billDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText
            placeholder="Enter the Bill Name"
            id="name"
            value={bill.name}
            onChange={(e) => onInputChange(e, "name")}
            required={true}
            autoFocus
            className={classNames({ "p-invalid": submitted && !bill.name })}
          />
          {submitted && !bill.name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="type" className="font-bold">
            Type
          </label>
          <Dropdown
            value={bill.type} // Use the bill.type as the value of the dropdown
            onChange={(e) => onTypeChange(e)} // Pass the entire event to the handler
            options={types}
            optionLabel="name"
            placeholder={bill.type ? `${bill.type}` : "Select bill type"}
            className="w-full md:w-14rem"
          />
          {submitted && !bill.type && (
            <small className="p-error">Type is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="measuring_unit" className="font-bold">
            Measuring Unit
          </label>
          <InputText
            placeholder="eg garbage is measured in bags"
            id="measuring_unit"
            value={bill.measuring_unit}
            onChange={(e) => onInputChange(e, "measuring_unit")}
            required
          />
          {submitted && !bill.measuring_unit && (
            <small className="p-error">Measuring unit is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="payable" className="font-bold">
            Payable
          </label>
          <Dropdown
            value={bill.payable}
            onChange={(e) => onPayableChange(e)}
            options={payables}
            optionLabel="name"
            placeholder={bill.payable ? `${bill.payable}` : "Select pay cycle"}
            className="w-full md:w-14rem"
            checkmark={true}
            highlightOnSelect={false}
          />
          {submitted && !bill.payable && (
            <small className="p-error">Payable frequency is required.</small>
          )}
        </div>
        <div>
          <p className="">The following are optional</p>
        </div>
        <div className="field">
          <label htmlFor="unit_rate" className="font-bold">
            Unit Rate
          </label>
          <InputNumber
            id="unit_rate"
            value={bill.unit_rate}
            onChange={(e) => onRateChange(e)}
            required
            mode="decimal"
            min={0}
            max={100000}
          />
          {submitted && bill.unit_rate <= 0 && (
            <small className="p-error">Unit rate must be positive.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="calculate_unit_rate" className="font-bold">
            Calculate cost from Unit rate?
          </label>
          <Checkbox
            inputId="calculate_unit_rate"
            checked={bill.calculate_unit_rate}
            onChange={(e) =>
              setbill({ ...bill, calculate_unit_rate: e.checked })
            }
          />
        </div>

        <div className="field">
          <label htmlFor="show_readings_in_invoice" className="font-bold">
            Show Readings in Invoice?
          </label>
          <Checkbox
            inputId="show_readings_in_invoice"
            checked={bill.show_readings_in_invoice}
            onChange={(e) =>
              setbill({ ...bill, show_readings_in_invoice: e.checked })
            }
          />
        </div>

        <div className="field">
          <label htmlFor="earn_commission" className="font-bold">
            Earn Commission?
          </label>
          <Checkbox
            inputId="earn_commission"
            checked={bill.earn_commission}
            onChange={(e) => setbill({ ...bill, earn_commission: e.checked })}
          />
        </div>

        <div className="field">
          <label htmlFor="deposit" className="font-bold">
            Deposit
          </label>
          <InputNumber
            id="deposit"
            value={bill.deposit}
            onChange={(e) => onDepositeChange(e)}
            required
            mode="decimal"
            min={0}
            max={100000}
          />
          {submitted && bill.deposit <= 0 && (
            <small className="p-error">deposit must be positive.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="deposit_type" className="font-bold">
            Deposit Type
          </label>
          <InputText
            placeholder="eg water deposit"
            id="measuring_unit"
            value={bill.deposit_type}
            onChange={(e) => onInputChange(e, "deposit_type")}
            required
          />
          {submitted && !bill.deposit_type && (
            <small className="p-error">deposit_type unit is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="minimum_units" className="font-bold">
            Minimum Units
          </label>
          <InputNumber
            id="minimum_units"
            value={bill.minimum_units}
            onChange={(e) => onInputChange(e, "minimum_units")}
            required
            mode="decimal"
            min={0}
            max={100000}
          />
          {submitted && bill.minimum_units <= 0 && (
            <small className="p-error">minimum_units must be positive.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="minimum_charge" className="font-bold">
            Minimum Charge
          </label>
          <InputNumber
            id="minimum_charge"
            value={bill.minimum_charge}
            onChange={(e) => onInputChange(e, "minimum_charge")}
            required
            mode="decimal"
            min={0}
            max={100000}
          />
          {submitted && bill.minimum_charge <= 0 && (
            <small className="p-error">minimum_charge must be positive.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="allocate_surplus" className="font-bold">
            Allocate Surplus
          </label>
          <Checkbox
            inputId="allocate_surplus"
            checked={bill.allocate_surplus}
            onChange={(e) => setbill({ ...bill, allocate_surplus: e.checked })}
          />
        </div>
        <div className="field">
          <label htmlFor="custom_invoice_description" className="font-bold">
            Custom Invoice Description
          </label>
          <InputTextarea
            id="custom_invoice_description"
            value={bill.custom_invoice_description}
            onChange={(e) => onInputChange(e, "custom_invoice_description")}
            rows={3}
            cols={20}
          />
        </div>
      </Dialog>
      <Dialog
        visible={deletebillDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deletebillDialogFooter}
        onHide={hideDeletebillDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {bill && (
            <span>
              Are you sure you want to delete <b>{bill.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={billUploadDialog}
        style={{ width: "60rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Add a Bill"
        modal
        className="p-fluid"
        footer={billDialogUploadFooter}
        onHide={hideUploadDialog}
      >
        <div>
          <Toast ref={toast}></Toast>

          <Tooltip
            target=".custom-choose-btn"
            content="Choose"
            position="bottom"
          />
          <Tooltip
            target=".custom-cancel-btn"
            content="Clear"
            position="bottom"
          />

          <FileUpload
            ref={fileUploadRef}
            name="demo[]"
            multiple
            accept=".pdf,.csv"
            maxFileSize={1000000}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            cancelOptions={cancelOptions}
            onUpload={(e) => upload(e)}
            encType="multipart/form-data"
          />
        </div>
      </Dialog>

      <Dialog
        visible={deletebillsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deletebillsDialogFooter}
        onHide={hideDeletebillsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {bill && (
            <span>Are you sure you want to delete the selected bills?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
