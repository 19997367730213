import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";


const BASE_URL = `${BaseUrl}/api/properties/`;

const fetchAllPropertiesAPI = async (property) => {
  try {
    const token = Cookies.get("token");
    console.log(token);
    
    if (!token) throw new Error("No authentication token found");

    const response = await axios.get(BASE_URL, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    throw new Error(errorMessage);
  }
};

const fetchPropertyAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    if (!token) throw new Error("No authentication token found");

    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    throw new Error(errorMessage);
  }
};

const createPropertyAPI = async (PropertyData) => {
  try {
    const token = Cookies.get("token");
    if (!token) throw new Error("No authentication token found");

    const response = await axios.post(BASE_URL, PropertyData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    throw new Error(errorMessage);
  }
};

const updatePropertyAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    if (!token) throw new Error("No authentication token found");

    const response = await axios.patch(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    throw new Error(errorMessage);
  }
};

const deletePropertyAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    if (!token) throw new Error("No authentication token found");

    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    throw new Error(errorMessage);
  }
};

export {
  fetchPropertyAPI,
  createPropertyAPI,
  updatePropertyAPI,
  deletePropertyAPI,
  fetchAllPropertiesAPI,
};
