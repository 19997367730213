import React from "react";
import Back from "./Back";
import Heading from "./Heading";
import f1 from "../Images/f1.png";

const Features = () => {
  return (
    <section className="about">
      <Back name="Features" title="Our Software Features!" cover={f1} />
      <div className="cont container flex flex1 mtop">
        <div className="left row">
          <Heading title="Our Software Features" />
          <div className="surface-0 text-center">
            <div className="grid">
              <div className="col-12 md:col-4 mb-4 px-5">
                <span
                  className="p-3 shadow-2 mb-3 inline-block"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-desktop text-4xl text-blue-500"></i>
                </span>
                <div className="text-900 text-xl mb-3 font-medium">
                  Automated Rent Collection
                </div>
                <span className="text-700 line-height-3">
                  Streamline rent collection with automated reminders and online
                  payment options.
                </span>
              </div>
              <div className="col-12 md:col-4 mb-4 px-5">
                <span
                  className="p-3 shadow-2 mb-3 inline-block"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-lock text-4xl text-blue-500"></i>
                </span>
                <div className="text-900 text-xl mb-3 font-medium">
                  Robust Security Features
                </div>
                <span className="text-700 line-height-3">
                  Ensure the safety of your data with end-to-end encryption, secure
                  user authentication, and regular security updates.
                </span>
              </div>
              <div className="col-12 md:col-4 mb-4 px-5">
                <span
                  className="p-3 shadow-2 mb-3 inline-block"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-check-circle text-4xl text-blue-500"></i>
                </span>
                <div className="text-900 text-xl mb-3 font-medium">
                  Detailed Financial Reporting
                </div>
                <span className="text-700 line-height-3">
                  Generate income statements, expense reports, and profit & loss
                  reports.
                </span>
              </div>
              <div className="col-12 md:col-4 mb-4 px-5">
                <span
                  className="p-3 shadow-2 mb-3 inline-block"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-users text-4xl text-blue-500"></i>
                </span>
                <div className="text-900 text-xl mb-3 font-medium">
                  Tenant Management
                </div>
                <span className="text-700 line-height-3">
                  Simplified tenant onboarding and offboarding processes.
                </span>
              </div>
              <div className="col-12 md:col-4 mb-4 px-5">
                <span
                  className="p-3 shadow-2 mb-3 inline-block"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-file text-4xl text-blue-500"></i>
                </span>
                <div className="text-900 text-xl mb-3 font-medium">
                  Lease Management
                </div>
                <span className="text-700 line-height-3">
                  Automated lease tracking, renewal reminders, and expiry
                  notifications.
                </span>
              </div>
              <div className="col-12 md:col-4 md:mb-4 mb-0 px-3">
                <span
                  className="p-3 shadow-2 mb-3 inline-block"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-wrench text-4xl text-blue-500"></i>
                </span>
                <div className="text-900 text-xl mb-3 font-medium">
                  Maintenance Management
                </div>
                <span className="text-700 line-height-3">
                  Easy submission and tracking of maintenance requests by tenants.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
