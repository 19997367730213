import React from "react";

const TopBar = () => {
  return (
    <div className="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
      <div className="font-bold mr-8 hidden md:flex">Contact Us!</div>
      <div className="align-items-center hidden lg:flex">
        <span className="line-height-3">
          Have any questions? Reach out to us!
        </span>
      </div>
      <div className="flex align-items-center ml-2 mr-8">
        <i className="pi pi-phone mr-2"></i>
        <a href="tel:+254742474979" className=" text-white underline font-bold">
          (+254) 742 474979
        </a>
      </div>
      <div className="flex align-items-center ml-2 mr-8">
        <i className="pi pi-envelope mr-2"></i>
        <a
          href="mailto:info@primedigitalsolutionz.com"
          className="underline text-white font-bold"
        >
          info@primedigitalsolutions.com
        </a>
      </div>

      <div className="flex align-items-center ml-2">
        <a
          className="no-underline justify-content-center border-circle text-100 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150"
          style={{ width: "2rem", height: "2rem" }}
          onClick={() =>
            (window.location.href =
              "https://chat.whatsapp.com/BCZQSh4C5DsI7cY4uWF5iZ")
          }
        >
          <i className="pi pi-whatsapp"></i>
        </a>
        <a
          className="no-underline justify-content-center border-circle text-100 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150 ml-2"
          style={{ width: "2rem", height: "2rem" }}
          href="https://www.facebook.com/yourfacebookpage"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="pi pi-facebook"></i>
        </a>
        <a
          className="no-underline justify-content-center border-circle text-100 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150 ml-2"
          style={{ width: "2rem", height: "2rem" }}
          href="https://www.linkedin.com/in/nicholas-maina-kamau/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="pi pi-linkedin"></i>
        </a>
      </div>
    </div>
  );
};

export default TopBar;
