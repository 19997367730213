import axios from "axios";
import Cookies from "js-cookie";
import { BaseUrl } from "../Constants";

const BASE_URL = `${BaseUrl}/api/suppliers/`;
const UPLOAD_URL = "https://backend.primdigitalsolutions.com/api/upload-suppliers/";

const fetchAllSuppliersAPI = async (property) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}?property=${property}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data; // Return the response data, which should contain a list of premises
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error if there is an issue with the request
  }
};



const fetchSupplierAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const createSupplierAPI = async (supplierData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(BASE_URL, supplierData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const uploadSupplierAPI = async (supplierData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(UPLOAD_URL, supplierData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const updateSupplierAPI = async (id, newData) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.put(`${BASE_URL}${id}/`, newData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const deleteSupplierAPI = async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.delete(`${BASE_URL}${id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export {
  fetchSupplierAPI,
  createSupplierAPI,
  updateSupplierAPI,
  deleteSupplierAPI,
  fetchAllSuppliersAPI,
  uploadSupplierAPI
};
