import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchLandlordAPI,
  createLandlordAPI,
  updateLandlordAPI,
  deleteLandlordAPI,
  fetchAllLandlordsAPI,
  uploadLandlordAPI,
} from "./landlordAPI";

export const fetchAllLandlords = createAsyncThunk(
  "landlord/fetchAllLandlords",
  async (id, { rejectWithValue }) => {
    try {
      const landlords = await fetchAllLandlordsAPI(id);
      return landlords;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchLandlord = createAsyncThunk(
  "landlord/fetchLandlord",
  async (id, { rejectWithValue }) => {
    try {
      const landlord = await fetchLandlordAPI(id);
      return landlord;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createLandlord = createAsyncThunk(
  "landlord/createLandlord",
  async (landlordData, { rejectWithValue }) => {
    try {
      const landlord = await createLandlordAPI(landlordData);
      return landlord;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadLandlord = createAsyncThunk(
  "landlord/uploadLandlord",
  async (landlordData, { rejectWithValue }) => {
    try {
      const landlord = await uploadLandlordAPI(landlordData);
      return landlord;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateLandlord = createAsyncThunk(
  "landlord/updateLandlord",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const updatedLandlord = await updateLandlordAPI(id, newData);
      return updatedLandlord;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteLandlord = createAsyncThunk(
  "landlord/deleteLandlord",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteLandlordAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  landlords: [], // Initialize landlords as an empty array
  landlord: null,
  loading: false,
  error: null,
};

const landlordSlice = createSlice({
  name: "landlord",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchAllLandlords.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchAllLandlords.fulfilled, (state, action) => {
      state.loading = false;
      state.landlords = action.payload; // Save the list of landlords
    })
    .addCase(fetchAllLandlords.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
      .addCase(fetchLandlord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLandlord.fulfilled, (state, action) => {
        state.loading = false;
        state.landlord = action.payload;
      })
      .addCase(fetchLandlord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createLandlord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLandlord.fulfilled, (state, action) => {
        state.loading = false;
        state.landlord = action.payload;
      })
      .addCase(createLandlord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateLandlord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLandlord.fulfilled, (state, action) => {
        state.loading = false;
        state.landlord = action.payload;
      })
      .addCase(updateLandlord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteLandlord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteLandlord.fulfilled, (state) => {
        state.loading = false;
        state.landlord = null;
      })
      .addCase(deleteLandlord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default landlordSlice.reducer;
