// conditional render on either sell or  rent

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import PropertysChart from "./Charts/ExpensesChart";
import ProfitAndLossChart from "./Charts/ProfitAndLoss";

import {
  createProperty,
  fetchAllProperties,
  updateProperty,
  deleteProperty,
} from "../Redux/Properties/PropertySlice";
import OcupancyChart from "./Charts/OcupancyChart";

export default function MyProperties({ sendDataToParent }) {
  const dispatch = useDispatch();
  const emptyProperty = {
    created_by: JSON.parse(localStorage.getItem("user"))?.id || null,
    property_name: "",
    property_type: "",
    country: "Kenya",
    town_or_city: "",
    estate_or_suburb: "",
    price: 0.0,
    payable: "Monthly",
    availability_status: "",
    bedroom: 0,
    is_price_negotiable: false,
    bathroom: 0,
    garage_or_parking: 0,
    is_furnished: "Yes",
    year_built: 0,
    land_tenure: "",
    is_deed_ready: "Yes",
    plot_uom: "",
    plot_surface_area: 0.0,
    floor_area_ft2: 0.0,
    description: "",
    rent_or_sale: "",
    landlord_owner_agreement: null,
    amenities: [],
    indoor_amenities: [],
    outdoor_amenities: [],
    rooms_amenities: [],
    floor_amenities: [],
    roofing_amenities: [],
    security_amenities: [],
    wall_amenities: [],
    power_amenities: [],
    water_amenities: [],
    sanitation_amenities: [],
    communication_amenities: [],
    view_amenities: [],
  };

  const AMENITIES_CHOICES = [
    { label: "Cafeteria", value: "Cafeteria" },
    { label: "Disabled access", value: "Disabled access" },
    { label: "Fitness centre", value: "Fitness centre" },
    { label: "Lift", value: "Lift" },
    { label: "Near transport", value: "Near transport" },
    { label: "Sports court", value: "Sports court" },
  ];

  const INDOORCHOICES = [
    { label: "Air conditioner", value: "Air conditioner" },
    { label: "Fire place", value: "Fire place" },
    { label: "Gym", value: "Gym" },
    { label: "Jacuzzi", value: "Jacuzzi" },
    { label: "Pet is allowed", value: "Pet is allowed" },
    { label: "Sauna", value: "Sauna" },
    { label: "Stairs", value: "Stairs" },
  ];

  const OUTDOOR_CHOICES = [
    { label: "Backyard", value: "Backyard" },
    { label: "Balcony", value: "Balcony" },
    { label: "Barbeque", value: "Barbeque" },
    { label: "Deck", value: "Deck" },
    { label: "Garden", value: "Garden" },
    { label: "Gazebo", value: "Gazebo" },
    { label: "Meadow", value: "Meadow" },
    { label: "Parking area", value: "Parking area" },
    { label: "Pond", value: "Pond" },
    { label: "Swimming pool", value: "Swimming pool" },
    { label: "Verandah", value: "Verandah" },
  ];

  const ROOMS_CHOICES = [
    { label: "Basement", value: "Basement" },
    { label: "Board room", value: "Board room" },
    { label: "Bonus room", value: "Bonus room" },
    { label: "Car park", value: "Car park" },
    { label: "Changing room", value: "Changing room" },
    { label: "Cloak room", value: "Cloak room" },
    { label: "Closet", value: "Closet" },
    { label: "Conference hall", value: "Conference hall" },
    { label: "Dining room", value: "Dining room" },
    { label: "Kitchen", value: "Kitchen" },
    { label: "Laundry room", value: "Laundry room" },
    { label: "Library", value: "Library" },
    { label: "Living room", value: "Living room" },
    { label: "Lounge", value: "Lounge" },
    { label: "Master ensuite", value: "Master ensuite" },
    { label: "Office", value: "Office" },
    { label: "Servant quarter", value: "Servant quarter" },
    { label: "Store", value: "Store" },
    { label: "Studio", value: "Studio" },
    { label: "Theatre", value: "Theatre" },
    { label: "Waiting room", value: "Waiting room" },
    { label: "Workshop", value: "Workshop" },
  ];

  const FLOOR_CHOICES = [
    { label: "Carpet", value: "Carpet" },
    { label: "Concrete", value: "Concrete" },
    { label: "Hardwood", value: "Hardwood" },
    { label: "Slates", value: "Slates" },
    { label: "Softwood", value: "Softwood" },
    { label: "Tiles", value: "Tiles" },
  ];

  const ROOFING_CHOICES = [
    { label: "Asphalt", value: "Asphalt" },
    { label: "Chimney", value: "Chimney" },
    { label: "Dome", value: "Dome" },
    { label: "Flat roof", value: "Flat roof" },
    { label: "Iron sheet", value: "Iron sheet" },
    { label: "Painted ceiling", value: "Painted ceiling" },
    { label: "Tile", value: "Tile" },
    { label: "Vinyl", value: "Vinyl" },
    { label: "Wooden ceiling", value: "Wooden ceiling" },
  ];

  const SECURITY_CHOICES = [
    { label: "Alarm", value: "Alarm" },
    { label: "Card access control", value: "Card access control" },
    { label: "Cctv", value: "Cctv" },
    { label: "Dog kennel", value: "Dog kennel" },
    { label: "Electric fence", value: "Electric fence" },
    { label: "Gate guard", value: "Gate guard" },
    { label: "Hedge", value: "Hedge" },
    { label: "Panic button", value: "Panic button" },
    { label: "Private gate", value: "Private gate" },
    { label: "Security lighting", value: "Security lighting" },
    { label: "Stairs gate", value: "Stairs gate" },
    { label: "Un security approved", value: "Un security approved" },
    { label: "Perimeter wall", value: "Perimeter wall" },
    { label: "Watchman", value: "Watchman" },
    { label: "Window bars", value: "Window bars" },
  ];

  const WALL_CHOICES = [
    { label: "Barricade", value: "Barricade" },
    { label: "Gate", value: "Gate" },
    { label: "Glass", value: "Glass" },
    { label: "Graffiti", value: "Graffiti" },
    { label: "Stone", value: "Stone" },
    { label: "Wood", value: "Wood" },
  ];

  const POWER_CHOICES = [
    { label: "Biogas", value: "Biogas" },
    { label: "Electrical grid", value: "Electrical grid" },
    { label: "Generator", value: "Generator" },
    { label: "Inverter", value: "Inverter" },
    { label: "Solar panel", value: "Solar panel" },
    { label: "Solar water heater", value: "Solar water heater" },
  ];

  const WATER_CHOICES = [
    { label: "Borehole", value: "Borehole" },
    { label: "External water tank", value: "External water tank" },
    { label: "Internal water tank", value: "Internal water tank" },
    { label: "Piped water", value: "Piped water" },
    { label: "Rain water harvesting", value: "Rain water harvesting" },
  ];

  const SANITATION_CHOICES = [
    { label: "Garbage collection", value: "Garbage collection" },
    { label: "Sewage", value: "Sewage" },
  ];

  const COMMUNICATION_CHOICES = [
    { label: "Fibre optic", value: "Fibre optic" },
    { label: "Landline", value: "Landline" },
    { label: "Satellite internet", value: "Satellite internet" },
    { label: "Satellite tv", value: "Satellite tv" },
  ];

  const VIEW_CHOICES = [
    { label: "Beach", value: "Beach" },
    { label: "City", value: "City" },
    { label: "Country", value: "Country" },
    { label: "Hill", value: "Hill" },
  ];

  const PAY_FREQUENCY_CHOICES = [
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Bi-weekly", value: "Bi-weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Semi-annually", value: "Semi-annually" },
    { label: "Annually", value: "Annually" },
  ];

  const AVAILABILITY_CHOICES = [
    { label: "Available", value: "Available" },
    { label: "Sold", value: "Sold" },
    { label: "Under Offer", value: "Under Offer" },
    { label: "Withdrawn", value: "Withdrawn" },
    { label: "Reserved", value: "Reserved" },
    { label: "Off Market", value: "Off Market" },
    { label: "Leased", value: "Leased" },
    { label: "Vacant", value: "Vacant" },
    { label: "Occupied", value: "Occupied" },
    { label: "Coming Soon", value: "Coming Soon" },
    { label: "Pre-construction", value: "Pre-construction" },
    { label: "Under Construction", value: "Under Construction" },
    { label: "Ready for Occupancy", value: "Ready for Occupancy" },
    { label: "Expired", value: "Expired" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Other", value: "Other" },
  ];

  const PROPERTY_TYPES = [
    { label: "House", value: "House" },
    { label: "Apartment", value: "Apartment" },
    { label: "Condo", value: "Condo" },
    { label: "Townhouse", value: "Townhouse" },
    { label: "Land", value: "Land" },
    { label: "Commercial", value: "Commercial" },
    { label: "Other", value: "Other" },
  ];

  const [property, setProperty] = useState(emptyProperty);
  const [propertyDialog, setPropertyDialog] = useState(false);
  const [deletePropertyDialog, setDeletePropertyDialog] = useState(false);
  const [date, setDate] = useState(null);
  const toast = useRef(null);
  const Properties = useSelector((state) => state.properties.properties);
  const Error = useSelector((state) => state.properties.error);
  console.log(Error);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedindoor_amenities, setSelectedindoor_amenities] = useState([]);
  const [selectedOutdoorAmenities, setSelectedOutdoorAmenities] = useState([]);
  const [selectedRoomsAmenities, setSelectedRoomsAmenities] = useState([]);
  const [selectedFloorAmenities, setSelectedFloorAmenities] = useState([]);
  const [selectedRoofingAmenities, setSelectedRoofingAmenities] = useState([]);
  const [selectedSecurityAmenities, setSelectedSecurityAmenities] = useState(
    []
  );
  const [selectedWallAmenities, setSelectedWallAmenities] = useState([]);
  const [selectedPowerAmenities, setSelectedPowerAmenities] = useState([]);
  const [selectedWaterAmenities, setSelectedWaterAmenities] = useState([]);
  const [selectedSanitationAmenities, setSelectedSanitationAmenities] =
    useState([]);
  const [selectedCommunicationAmenities, setSelectedCommunicationAmenities] =
    useState([]);
  const [selectedViewAmenities, setSelectedViewAmenities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllProperties());
  }, [dispatch]);

  const openNew = () => {
    setProperty(emptyProperty);
    setPropertyDialog(true);
  };

  const hideDialog = () => {
    setPropertyDialog(false);
  };

  const saveProperty = () => {
    // Basic validation to check if required fields are filled
    if (!property.property_name || !property.property_type || !property.town_or_city) {
      toast.current.show({
        severity: "error",
        summary: "Validation Failed",
        detail: "Please fill in all required fields",
        life: 3000,
      });
      return;
    }
  
    if (property.id) {
      // If property has an id, it means it already exists and needs to be updated
      dispatch(updateProperty(property)) // Dispatching action to update property
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Property Updated",
            life: 3000,
          });
          // Optionally refresh the properties list
          dispatch(fetchAllProperties());
        })
        .catch(() => {
          // Retrieve the error from the state
          const errorMessage =Error || "Error updating property";
          toast.current.show({
            severity: "error",
            summary: "Update Failed",
            detail: errorMessage,
            life: 3000,
          });
        })
        .finally(() => {
          // Close the property dialog and reset the property form
          setPropertyDialog(false);
          setProperty(emptyProperty);
        });
    } else {
      // If property doesn't have an id, it means it's a new property and needs to be created
      dispatch(createProperty(property)) // Dispatching action to create property
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Property Created",
            life: 3000,
          });
          // Optionally refresh the properties list
          dispatch(fetchAllProperties());
        })
        .catch(() => {
          // Retrieve the error from the state
          const errorMessage = Error || "Error creating property";
          toast.current.show({
            severity: "error",
            summary: "Creation Failed",
            detail: errorMessage,
            life: 3000,
          });
        })
        .finally(() => {
          // Close the property dialog and reset the property form
          setPropertyDialog(false);
          setProperty(emptyProperty);
        });
    }
  };
  
   

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _property = { ...property };
    _property[`${name}`] = val;
    setProperty(_property);
  };

  const onNumberChange = (e, name) => {
    const val = e.value || 0;
    let _property = { ...property };
    _property[`${name}`] = val;
    setProperty(_property);
  };

  const statusBodyTemplate = (product) => {
    return (
      <Tag
        value={product.availability_status}
        severity={getSeverity(product)}
      ></Tag>
    );
  };

  const getSeverity = (product) => {
    switch (product.availability_status) {
      case "Available":
        return "success";
      case "LOWSTOCK":
        return "warning";
      case "OUTOFSTOCK":
        return "danger";
      default:
        return null;
    }
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Properties</span>
      <div className="gap-6 flex align-items-center">
        <Button
          icon="pi pi-plus"
          label="Add Property"
          outlined
          severity="primary"
          onClick={openNew}
        />
      </div>
    </div>
  );

  const footer = `In total there ${
    Properties && Properties.length === 1 ? "is" : "are"
  } ${Properties ? Properties.length : 0} ${
    Properties && Properties.length === 1 ? "Property" : "Properties"
  }.`;

  const editProperty = (property) => {
    setProperty({ ...property });
    setPropertyDialog(true);
  };

  const confirmDeleteProperty = (property) => {
    setProperty(property);
    setDeletePropertyDialog(true);
  };

  const manageLink = (property) => {
    sendDataToParent(property.id);
    navigate(`/property/${property.id}/bills`);
  };
  const onYearChange = (e) => {
    const selectedYear = e.value?.getFullYear() || null;
    setProperty((prevProperty) => ({
      ...prevProperty,
      year_built: selectedYear,
    }));
  };

  const onAvailabilityStatusChange = (e) => {
    setProperty((prevProperty) => ({
      ...prevProperty,
      availability_status: e.value,
    }));
  };
  const onPropertyTypeChange = (e) => {
    setProperty((prevProperty) => ({
      ...prevProperty,
      property_type: e.value,
    }));
  };

  const onPayFrequencyChange = (e) => {
    setProperty((prevProperty) => ({ ...prevProperty, payable: e.value }));
  };

  const onAmenitiesChange = (e, name) => {
    setSelectedAmenities(e.value);
    setProperty((prevProperty) => ({ ...prevProperty, [name]: e.value }));
  };

  const onIndoorAmenitiesChange = (e, name) => {
    setSelectedindoor_amenities(e.value);
    setProperty((prevProperty) => ({ ...prevProperty, [name]: e.value }));
  };

  const onOutdoorAmenitiesChange = (e) => {
    setSelectedOutdoorAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      outdoor_amenities: e.value,
    }));
  };

  const onRoomsAmenitiesChange = (e) => {
    setSelectedRoomsAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      rooms_amenities: e.value,
    }));
  };

  const onFloorAmenitiesChange = (e) => {
    setSelectedFloorAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      floor_amenities: e.value,
    }));
  };

  const onRoofingAmenitiesChange = (e) => {
    setSelectedRoofingAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      roofing_amenities: e.value,
    }));
  };

  const onSecurityAmenitiesChange = (e) => {
    setSelectedSecurityAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      security_amenities: e.value,
    }));
  };

  const onWallAmenitiesChange = (e) => {
    setSelectedWallAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      wall_amenities: e.value,
    }));
  };

  const onPowerAmenitiesChange = (e) => {
    setSelectedPowerAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      power_amenities: e.value,
    }));
  };

  const onWaterAmenitiesChange = (e) => {
    setSelectedWaterAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      water_amenities: e.value,
    }));
  };

  const onSanitationAmenitiesChange = (e) => {
    setSelectedSanitationAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      sanitation_amenities: e.value,
    }));
  };

  const onCommunicationAmenitiesChange = (e) => {
    setSelectedCommunicationAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      communication_amenities: e.value,
    }));
  };

  const onViewAmenitiesChange = (e) => {
    setSelectedViewAmenities(e.value);
    setProperty((prevProperty) => ({
      ...prevProperty,
      view_amenities: e.value,
    }));
  };

  const hideDeletepropertyDialog = () => {
    setDeletePropertyDialog(false);
  };

  const deletepropertie = () => {
    dispatch(deleteProperty(property.id));
    let _properties = property.filter((val) => val.id !== property.id);
    setProperty(_properties);
    setDeletePropertyDialog(false);
    setProperty(emptyProperty);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "propertie Deleted",
      life: 3000,
    });
  };

  const deletepropertyDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeletepropertyDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deletepropertie}
      />
    </React.Fragment>
  );
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editProperty(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          className="mr-2"
          onClick={() => confirmDeleteProperty(rowData)}
        />
        <Button
          icon="pi pi-cog"
          label="manage"
          outlined
          severity="success"
          onClick={() => manageLink(rowData)}
        />
      </React.Fragment>
    );
  };

  const propertyDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProperty}
      />
    </React.Fragment>
  );

  return (
    <div className="container ">
      <Toast ref={toast} />
      <div className="grid  sm:grid-cols-3 gap-4 m-3">
        <div className=" col text-center p-3 border-round-sm shadow-4 gray-50 font-bold h-15rem flex align-items-center justify-content-center">
          <PropertysChart />
        </div>
        <div className=" col text-center p-3 border-round-sm shadow-4 gray-50 font-bold h-15rem flex align-items-center justify-content-center">
          <ProfitAndLossChart />
        </div>
        <div className=" col text-center p-3 border-round-sm shadow-4 gray-50 font-bold h-15rem flex align-items-center justify-content-center">
          <OcupancyChart />
        </div>
      </div>

      <div className="card">
        <DataTable
          value={Properties}
          header={header}
          footer={footer}
          tableStyle={{ minWidth: "60rem" }}
        >
          <Column field="property_name" header="Name"></Column>
          <Column field="property_type" header="Property Type"></Column>

          <Column field="rent_or_sale" header="Category"></Column>
          <Column field="estate_or_suburb" header="property Location"></Column>
          <Column header="Status" body={statusBodyTemplate}></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={deletePropertyDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deletepropertyDialogFooter}
        onHide={hideDeletepropertyDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {property && (
            <span>
              Are you sure you want to delete <b>{property.property_name}</b>?
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={propertyDialog}
        style={{ width: "900px" }}
        header="Property Details"
        modal
        className="p-fluid"
        footer={propertyDialogFooter}
        onHide={hideDialog}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="property_name">
              Property Name{" "}
              <i className=" pi pi-star " style={{ color: "red" }}></i>
            </label>
            <InputText
              id="property_name"
              value={property.property_name}
              onChange={(e) => onInputChange(e, "property_name")}
              required
              autoFocus
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="rent_or_sale">
              Property Listing{" "}
              <i className=" pi pi-star " style={{ color: "red" }}></i>
            </label>
            <Dropdown
              id="rent_or_sale"
              value={property.rent_or_sale}
              options={[
                { label: "Sell", value: "Sale" },
                { label: "Rent", value: "Rent" },
              ]}
              onChange={(e) => onInputChange(e, "rent_or_sale")}
              placeholder={property.rent_or_sale}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="property_type">
              Property Type{" "}
              <i className=" pi pi-star " style={{ color: "red" }}></i>
            </label>
            <Dropdown
              value={property.property_type}
              options={PROPERTY_TYPES}
              onChange={onPropertyTypeChange}
              optionLabel="label"
              placeholder="Select Property Type"
              className="w-full md:w-20rem"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="country">Country</label>
            <InputText
              id="country"
              value={property.country}
              onChange={(e) => onInputChange(e, "country")}
              required
              disabled
              placeholder={property.country}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="town_or_city">
              Town or City{" "}
              <i className=" pi pi-star " style={{ color: "red" }}></i>
            </label>
            <InputText
              id="town_or_city"
              value={property.town_or_city}
              onChange={(e) => onInputChange(e, "town_or_city")}
              required
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="estate_or_suburb">
              Estate or Suburb{" "}
              <i className=" pi pi-star " style={{ color: "red" }}></i>
            </label>
            <InputText
              id="estate_or_suburb"
              value={property.estate_or_suburb}
              onChange={(e) => onInputChange(e, "estate_or_suburb")}
              required
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="payable">
              Payable <i className=" pi pi-star " style={{ color: "red" }}></i>
            </label>
            <Dropdown
              value={property.payable}
              options={PAY_FREQUENCY_CHOICES}
              onChange={onPayFrequencyChange}
              optionLabel="label"
              placeholder="Select Pay Frequency"
              className="w-full md:w-20rem"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="bedroom">Bedroom</label>
            <InputNumber
              id="bedroom"
              value={property.bedroom}
              onValueChange={(e) => onNumberChange(e, "bedroom")}
            />
          </div>

          {property.rent_or_sale === "Sale" && (
            <>
              <div className="field col-12 md:col-6">
                <label htmlFor="price">Price</label>
                <InputNumber
                  id="price"
                  value={property.price}
                  onValueChange={(e) => onNumberChange(e, "price")}
                  mode="currency"
                  currency="ksh"
                  locale="en-US"
                />
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="availability_status">
                  Availability Status{" "}
                </label>
                <Dropdown
                  value={property.availability_status}
                  options={AVAILABILITY_CHOICES}
                  onChange={onAvailabilityStatusChange}
                  optionLabel="label"
                  placeholder="Select Availability Status"
                  className="w-full md:w-20rem"
                />
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="is_price_negotiable">Is Price Negotiable</label>
                <Dropdown
                  id="is_price_negotiable"
                  value={property.is_price_negotiable}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  onChange={(e) => onInputChange(e, "is_price_negotiable")}
                  placeholder="Select an option"
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="is_deed_ready">Is Deed Ready</label>
                <Dropdown
                  id="is_deed_ready"
                  value={property.is_deed_ready}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  onChange={(e) => onInputChange(e, "is_deed_ready")}
                  placeholder="Select an option"
                />
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="plot_uom">Plot UOM</label>
                <InputText
                  id="plot_uom"
                  value={property.plot_uom}
                  onChange={(e) => onInputChange(e, "plot_uom")}
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="plot_surface_area">Plot Surface Area</label>
                <InputNumber
                  id="plot_surface_area"
                  value={property.plot_surface_area}
                  onValueChange={(e) => onNumberChange(e, "plot_surface_area")}
                />
              </div>
            </>
          )}

          <div className="field col-12 md:col-6">
            <label htmlFor="bathroom">Bathroom</label>
            <InputNumber
              id="bathroom"
              value={property.bathroom}
              onValueChange={(e) => onNumberChange(e, "bathroom")}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="garage_or_parking">Garage or Parking</label>
            <InputNumber
              id="garage_or_parking"
              value={property.garage_or_parking}
              onValueChange={(e) => onNumberChange(e, "garage_or_parking")}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="is_furnished">Is Furnished</label>
            <Dropdown
              id="is_furnished"
              value={property.is_furnished}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              onChange={(e) => onInputChange(e, "is_furnished")}
              placeholder="Select an option"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="year_built">Year Built</label>
            <Calendar
              id="year_built"
              value={date}
              onChange={(e) => {
                setDate(e.value);
                onYearChange(e);
              }}
              view="year"
              dateFormat="yy"
              maxDate={new Date()}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="floor_area_ft2">Floor Area (ft²)</label>
            <InputNumber
              id="floor_area_ft2"
              value={property.floor_area_ft2}
              onValueChange={(e) => onNumberChange(e, "floor_area_ft2")}
            />
          </div>

          <div className="field col-12">
            <label htmlFor="description">Description</label>
            <InputText
              id="description"
              value={property.description}
              onChange={(e) => onInputChange(e, "description")}
              rows={3}
              cols={20}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="amenities">Amenities</label>
            <MultiSelect
              value={selectedAmenities}
              options={AMENITIES_CHOICES}
              onChange={(e) => onAmenitiesChange(e, "amenities")}
              optionLabel="label"
              placeholder="Select Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="indoor_amenities">indoor_amenities</label>
            <MultiSelect
              value={selectedindoor_amenities}
              options={INDOORCHOICES}
              onChange={(e) => onIndoorAmenitiesChange(e, "indoor_amenities")}
              optionLabel="label"
              placeholder="Select indoor amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="outdoor_amenities">Outdoor Amenities</label>
            <MultiSelect
              value={selectedOutdoorAmenities}
              options={OUTDOOR_CHOICES}
              onChange={(e) => onOutdoorAmenitiesChange(e, "outdoor_amenities")}
              optionLabel="label"
              placeholder="Select Outdoor Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="rooms_amenities">Rooms Amenities</label>
            <MultiSelect
              value={selectedRoomsAmenities}
              options={ROOMS_CHOICES}
              onChange={(e) => onRoomsAmenitiesChange(e, "rooms_amenities")}
              optionLabel="label"
              placeholder="Select Rooms Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="floor_amenities">Floor Amenities</label>
            <MultiSelect
              value={selectedFloorAmenities}
              options={FLOOR_CHOICES}
              onChange={(e) => onFloorAmenitiesChange(e, "floor_amenities")}
              optionLabel="label"
              placeholder="Select Floor Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="roofing_amenities">Roofing Amenities</label>
            <MultiSelect
              value={selectedRoofingAmenities}
              options={ROOFING_CHOICES}
              onChange={(e) => onRoofingAmenitiesChange(e, "roofing_amenities")}
              optionLabel="label"
              placeholder="Select Roofing Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="security_amenities">Security Amenities</label>
            <MultiSelect
              value={selectedSecurityAmenities}
              options={SECURITY_CHOICES}
              onChange={(e) =>
                onSecurityAmenitiesChange(e, "security_amenities")
              }
              optionLabel="label"
              placeholder="Select Security Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="wall_amenities">Wall Amenities</label>
            <MultiSelect
              value={selectedWallAmenities}
              options={WALL_CHOICES}
              onChange={(e) => onWallAmenitiesChange(e, "wall_amenities")}
              optionLabel="label"
              placeholder="Select Wall Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="power_amenities">Power Amenities</label>
            <MultiSelect
              value={selectedPowerAmenities}
              options={POWER_CHOICES}
              onChange={(e) => onPowerAmenitiesChange(e, "power_amenities")}
              optionLabel="label"
              placeholder="Select Power Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="water_amenities">Water Amenities</label>
            <MultiSelect
              value={selectedWaterAmenities}
              options={WATER_CHOICES}
              onChange={(e) => onWaterAmenitiesChange(e, "water_amenities")}
              optionLabel="label"
              placeholder="Select Water Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="sanitation_amenities">Sanitation Amenities</label>
            <MultiSelect
              value={selectedSanitationAmenities}
              options={SANITATION_CHOICES}
              onChange={(e) =>
                onSanitationAmenitiesChange(e, "sanitation_amenities")
              }
              optionLabel="label"
              placeholder="Select Sanitation Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="communication_amenities">
              Communication Amenities
            </label>
            <MultiSelect
              value={selectedCommunicationAmenities}
              options={COMMUNICATION_CHOICES}
              onChange={(e) =>
                onCommunicationAmenitiesChange(e, "communication_amenities")
              }
              optionLabel="label"
              placeholder="Select Communication Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="view_amenities">View Amenities</label>
            <MultiSelect
              value={selectedViewAmenities}
              options={VIEW_CHOICES}
              onChange={(e) => onViewAmenitiesChange(e, "view_amenities")}
              optionLabel="label"
              placeholder="Select View Amenities"
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
